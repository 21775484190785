<template>
  <a
    class="message-item__value message-item__value_file"
    :href="messageFile.uploadFile ? '' : messageFile.url"
    :download="messageFile.uploadFile ? '' : messageFile.originalName"
    target="_blank"
  >
    <div
      v-if="!isMe"
      class="message-item__label"
    >
      {{ lableText }}
    </div>
    <div class="message-item__value-wrapper">
      <div
        v-if="messageFile._id"
        class="message-item__icon"
      >
        <i class="ub-icon-pdf-file"></i>
      </div>
      <div
        class="message-item__content"
        :class="{'message-item__content_upload': messageFile._id === undefined}"
      >
        <span
          v-if="messageFile.originalName"
          class="message-item__docname"
        >
          {{ messageFile.originalName }}
        </span>
        <transition name="fade-content">
          <div
            v-if="messageFile.uploadFile"
            class="message-item-progress"
          >
            <span class="message-item-progress__line"></span>
          </div>
        </transition>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'ChatFile',
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
    clientName: {
      type: String,
    },
    isMe: {
      type: Boolean,
      default: false,
    },
    chatPartnerId: {
      type: String,
      default: '',
    },
    chatClientId: {
      type: String,
      default: '',
    },
    chatAdminsId: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    messageFile() {
      const { file = {} } = this.item;
      return file;
    },
    lableText() {
      const { senderId } = this.item;
      if (senderId === this.chatPartnerId) {
        return this.$t('chat.partner');
      }
      if (this.chatAdminsId.includes(senderId)) {
        return this.$t('chat.admin');
      }
      return this.clientName;
    },
  },
};
</script>
