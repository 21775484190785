import { TYPE_ELEMENT } from '@/preset/listElements';
import { SUB_TYPE_DESCRIPTION } from '@/preset/elements/listSubTypes';
import getUniqueId from '@/mixins/uniqueId';

const NEW_DESCRIPTION = () => ({
  fieldId: getUniqueId(),
  type: TYPE_ELEMENT,
  sub: SUB_TYPE_DESCRIPTION,
  value: '',
  parentId: [],
});

export default NEW_DESCRIPTION;
