<template>
  <div
    v-if="row.payments.length"
    class="ub-modal-payment"
  >
    <div class="ub-modal-payment__header">
      <template v-if="paymentsInfo">
        <img
          :src="getImagePaySystem"
          :alt="getImageAlt"
          class="ub-modal-payment__img"
        >
      </template>
      <template v-else>
        <img
          :src="getImagePaySystem"
          :alt="getImageAlt"
          class="img"
        >
        <span>
          {{ $t('chequeModal.paymentCheck') }}
        </span>
      </template>
    </div>
    <div
      v-if="!isFullPaymentFulfilled"
      class="ub-modal-payment__content"
    >
      <div
        v-for="payment in payments"
        :key="payment._id"
        class="ub-modal-payment__content-item"
      >
        <div class="ub-modal-payment__content-info">
          {{ $t('chequeModal.prepayment') }} {{ prePayment }}%
        </div>
        <div class="ub-modal-payment__content-price">
          {{ payment.sum }} {{ payment.currency }}
        </div>
      </div>
    </div>
    <div
      v-else
      class="ub-modal-payment__content"
    >
      <div
        v-for="(payment, index) in payments"
        :key="payment._id"
        class="ub-modal-payment__content-item"
      >
        <div class="ub-modal-payment__content-info">
          <template v-if="index === 0 && payment.isPrepayment">
            {{ $t('chequeModal.prepayment') }}
          </template>
          <template v-else>
            {{ $t('chequeModal.postpayment') }}
          </template>
          <template v-if="index === 0 && payment.isPrepayment">
            {{ prePayment }}%
          </template>
          <template v-else>
            {{ 100 - prePayment }}%
          </template>
        </div>
        <div class="ub-modal-payment__content-price">
          {{ payment.sum }} {{ payment.currency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'UBPaymentModal',
  props: {
    row: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      topPosition: 0,
    };
  },
  computed: {
    prePayment() {
      return get(this.row, 'prePayment', '');
    },
    paymentsInfo() {
      const paymentsInfo = this.payments.filter((element) => element.paymentForm === 'Paypal');
      return paymentsInfo;
    },
    isFullPaymentFulfilled() {
      return get(this.row, 'isFullPaymentFulfilled', '');
    },
    payments() {
      return get(this.row, 'payments', '');
    },
    paymentForm() {
      return this.row.payments[0]?.paymentForm;
    },
    getImagePaySystem() {
      return this.paymentForm === 'Paypal'
      // eslint-disable-next-line global-require
        ? require('@/assets/images/paypal.svg')
      // eslint-disable-next-line global-require
        : require('@/assets/images/stripe.svg');
    },
    getImageAlt() {
      return this.paymentForm === 'Paypal' ? 'paypal' : 'stripe';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/variables";

.ub-modal-payment {
  box-shadow: 0 0 10px rgba($color-black, .1);
  border-radius: $borderRadius;
  background: $color-white;
  width: 230px;
  position: absolute;
  top: 35px;
  text-align: left;
  z-index: 100;

  &__header {
    padding: 12px;
    display: flex;
    align-items: center;

    span {
      color: $color-black;
      margin-left: 6px;
      font: $font-size-md $font-global-medium;
    }
  }

  &__img {
    height: 100%;
    max-height: 25px;
    max-width: 100px;
  }

  &__content {

    &-item {
      padding: 12px;
      border-top: 1px solid $color-alabaster;
      display: flex;
      justify-content: space-between;
      font: $font-size-base $font-global-medium;
    }

    &-price {
      color: $color-black;
      padding-left: 5px;
    }

    &-info {
      color: $color-dodger-blue;
    }
  }
}
</style>
