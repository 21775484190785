import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  createChat(data) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.CHAT_API,
    });
    return instWithCred.post('', data);
  },
  updateChat({ id, chat }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.CHAT_API,
    });
    return instWithCred.patch(id, chat);
  },
  getChat({ id }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.CHAT_API,
    });
    return instWithCred.get(id);
  },
  getChats() {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.CHAT_API,
    });
    return instWithCred.get('getchats');
  },
  addMessage(data) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.MESSAGES_API,
    });
    return instWithCred.post('', data);
  },
  updateMessage({ id, message }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.MESSAGES_API,
    });
    return instWithCred.patch(id, message);
  },
};
