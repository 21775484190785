export const TYPE_TEXT = 'TYPE_TEXT';
export const TYPE_NUMBER = 'TYPE_NUMBER';
export const TYPE_FLAG = 'TYPE_FLAG';
export const TYPE_LIST = 'TYPE_LIST';
export const TYPE_MULTILIST = 'TYPE_MULTILIST';
export const TYPE_DATE = 'TYPE_DATE';
export const TYPE_LINK = 'TYPE_LINK';
export const TYPE_TEXTAREA = 'TYPE_TEXTAREA';
export const TYPE_SWITCH = 'TYPE_SWITCH';
export const TYPE_BIRTHDAY = 'TYPE_BIRTHDAY';
export const TYPE_BREAK = 'TYPE_BREAK';
