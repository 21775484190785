<template>
  <div
    class="ub-string-cell"
    @click="clickCell"
  >
    <span
      class="ub-string-cell-text"
      :class="[
        {
          'ub-string-cell-text_with-hover': column.route || column.hover,
          'ub-string-cell-text_tag': column.styles === 'tag',
          'ub-string-cell-text_capitalize': countryFlag,
          'ub-string-cell-text_link': link
        }
      ]"
    >
      <template v-if="link">
        <a :href="linkHref">
          {{ value }}
        </a>
      </template>
      <template v-else-if="icon">
        <template v-if="hasFullyPayedPayoffs !== ''">
          <i
            class="ub-string-cell-text__icon"
            :class="[icon, hasFullyPayedPayoffs ? 'ub-string-cell-text__icon_full' : 'ub-string-cell-text__icon_half']"
          ></i>
        </template>
        <template v-else>
          <i
            v-if="refunded"
            class="ub-string-cell-text__icon"
            :class="icon"
          ></i>
        </template>
      </template>
      <template v-else>
        {{ value }}
      </template>
      <template v-if="procent">
        %
      </template>
    </span>
    <div
      v-if="rewardRate"
      class="ub-string-cell__reward"
      :class="checkValue"
    >
      {{ rewardRate }}%
    </div>
    <i
      v-if="info.length > 0 && column.info"
      class="ub-icon-info ub-string-cell-info"
      @mouseenter="setShowInfo($event, true)"
      @mouseleave="setShowInfo($event, false)"
    >
      <transition name="ub-bounce">
        <div
          v-if="showInfo"
          class="ub-string-cell-info-popup"
          :style="positionInfo"
        >
          <div
            v-for="item in info"
            :key="item"
            class="ub-string-cell-info-popup-item"
          >
            <span class="ub-string-cell-info-popup-item__circle" /> {{ item }}
          </div>
        </div>
      </transition>
    </i>
    <div
      v-if="markText && markDisplay"
      class="ub-string-cell-mark"
    >
      {{ markText }}
    </div>
    <img
      v-if="displayNotification && hasNotification"
      :src="notificationImage"
      class="ub-string-cell-notification"
      alt="table"
    />
  </div>
</template>

<script>
import {
  get, includes, compact, filter,
} from 'lodash';

export default {
  name: 'UBStringCell',
  props: {
    countryFlag: {
      type: Boolean,
      default: () => false,
    },
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
    index: {
      type: Number,
      default: () => -1,
    },
  },
  data() {
    return {
      showInfo: false,
      positionInfo: {},
      notificationImage: 'notificationImage',
    };
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    value() {
      const { locale = false } = this.column;
      const { language } = this;
      const fromProp = get(this.row, locale ? `${this.column.prop}.${language}` : this.column.prop, '');
      const prefix = get(this.row, this.column.prefix, '');
      const lang = get(this.column, 'lang', false);
      const postfix = get(this.column, 'postfix', '');
      const valueList = get(this.row, this.column.display, fromProp);
      const filterParams = get(this.column, 'filterParams', '');
      const value = filterParams ? filter(valueList, (item) => item[filterParams]) : valueList;
      const { sum = '' } = this.column;
      const valueSum = sum ? value.map((item) => item[sum]).reduce((a, b) => (
        Number.isNaN(+a) ? 0 : +a) + (Number.isNaN(+b) ? 0 : +b),
      0) : value;
      const postfixText = typeof postfix === 'string' ? postfix : this.calcNum(valueSum, postfix);
      const postfixLangText = lang ? this.$t(postfixText) : postfixText;
      return `${valueSum} ${prefix} ${postfixLangText}`;
    },
    rewardRate() {
      const prop = get(this.column, 'reward.prop', '');
      const value = get(this.row, prop, '');
      return value;
    },
    hasFullyPayedPayoffs() {
      const value = get(this.row, 'hasFullyPayedPayoffs', '');
      return value;
    },
    refunded() {
      const value = get(this.row, 'isRefunded', '');
      return value;
    },
    checkValue() {
      if (+this.rewardRate === 70) {
        return 'ub-string-cell__reward_main';
      }
      return 'ub-string-cell__reward_green';
    },
    link() {
      return get(this.column, 'link', false);
    },
    icon() {
      return get(this.column, 'icon', '');
    },
    isRefunded() {
      return get(this.column, 'isRefunded', '');
    },
    refundedStatus() {
      return get(this.row, 'isRefunded', false);
    },
    linkOption() {
      return get(this.column, 'linkOption', '');
    },
    procent() {
      return get(this.column, 'procent', false);
    },
    linkHref() {
      const linkHref = get(this.row, `${this.column.linkHref}`, '');
      return `${this.linkOption ? `${this.linkOption}:` : ''}${linkHref}`;
    },
    info() {
      return compact(get(this.row, this.column.info, []));
    },
    markText() {
      return get(this.column, 'mark.text', '');
    },
    markDisplay() {
      const prop = get(this.column, 'mark.display', '');
      return get(this.row, prop, '');
    },
    displayNotification() {
      return get(this.column, 'notification', '');
    },
    hasNotification() {
      const userID = get(this.userInfo, 'id', '');
      const notificationId = get(this.row, '$notification.id', '');
      const readUsers = get(this.row, '$notification.readUsers', []);
      return !includes(readUsers, userID) && notificationId;
    },
  },
  methods: {
    calcNum(number, time) {
      const cases = [2, 0, 1, 1, 1, 2];
      return time[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
    setShowInfo(e, val) {
      this.showInfo = val;
      const position = e.target.getBoundingClientRect();
      if (val && position) {
        this.positionInfo = {
          position: 'fixed',
          top: `${position.top + position.height + 8}px`,
          left: `${position.left}px`,
        };
      } else {
        this.positionInfo = {};
      }
    },
    clickCell(e) {
      const isRoute = get(this.column, 'route', '');
      const action = get(this.column, 'action', '');
      const field = get(this.column, 'field', '');
      const id = get(this.row, `${field}`, '');
      if (isRoute || action) {
        e.stopPropagation();
        if (isRoute && id) {
          this.goToRoute(isRoute, id);
        } else {
          this.goToRoute(isRoute);
        }
        if (action && typeof action === 'function') action(this.row, this.column);
      }
    },
    goToRoute(routeName, id) {
      const router = {
        name: routeName,
      };
      const paramName = get(this.column, ['route', 'paramName'], '');
      const paramProp = get(this.column, ['route', 'paramValue'], '');
      const paramValue = get(this.row, paramProp, '');
      if (paramName && paramValue) {
        router.params = {
          [paramName]: paramValue,
        };
      }
      if (id) {
        router.query = {
          id,
        };
      }
      this.$router.push(router);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/variables";

.ub-string-cell {
  font-family: $font-global;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;

  &-text {

    &__icon {
      font-size: $font-size-landing-lg;
      color: $color-dodger-blue;

      &_half {
        color: $color-corn;
      }

      &_full {
        color: $color-green;
      }
    }
  }

  &__reward {
    display: inline-block;
    padding: 4px 8px;
    border-radius: $borderRadius;
    font-weight: normal;
    font: $font-size-h3 $font-global;
    line-height: 1.5;
    margin-left: 10px;

    &_main {
      background: rgba($color-dodger-blue, .08);
      color: $color-dodger-blue,
    }

    &_green {
      background: rgba($color-green, .12);
      color: $color-green,
    }
  }

  &-text {
    display: flex;
    align-items: center;
    font-size: $font-size-h3;
    line-height: 21px;
    color: $color-black;

    &__info {
      border-radius: $borderRadius;
      padding: 4px 8px;
      height: 29px;
      background: rgba($color-dodger-blue, .08);
      color: $color-dodger-blue;
      transition: background-color .15s ease-in;
      margin-left: 20px;

      &_margin {
        margin-left: 0;
      }
    }

    &_capitalize {
      text-transform: capitalize;
    }

    &_with-hover {
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: $color-black;
      }
    }

    &_link {

      a {
        text-decoration: none;
        transition: color .2s ease-in;
        color: inherit;

        &:hover {
          color: $color-dodger-blue;
        }

      }

    }

    &_tag {
      display: inline-block;
      padding: 4px 6px;
      background: $color-alabaster;
      color: $color-alabaster;
      border-radius: $borderRadius;
      font-weight: normal;
      font-size: $font-size-h3;
      line-height: 16px;
    }
  }

  &-notification {
    width: 20px;
    height: 18px;
    object-fit: cover;
    margin-left: 15px;
    cursor: pointer;
  }

  &-mark {
    margin: auto 0 auto auto;
    padding: 3px 4px;
    background: rgba($color-alabaster, .2);
    border-radius: $borderRadiusSmall;
    font-weight: 500;
    font-size: $font-size-sm;
    line-height: 13px;
    color: $color-alabaster;
    max-height: 19px;
  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 10px;
    font-size: $font-size-h3;
    color: $color-alabaster;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: $color-alabaster;
    }

    &-popup {
      position: absolute;
      padding: 7px;
      background: rgba($color-black, .55);
      border-radius: $borderRadiusLarge;
      color: $color-white;
      user-select: none;
      pointer-events: none;

      &-item {
        display: grid;
        grid-template-columns: 10px auto;
        grid-gap: 1px;
        align-items: start;
        margin-bottom: 5px;

        &__circle {
          width: 3px;
          height: 3px;
          margin: 5px 0;
          background-color: $color-white;
          border-radius: 50%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: auto;
        top: -3px;
        display: block;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 3px solid rgba($color-black, .55);
      }
    }
  }
}

</style>
