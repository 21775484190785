import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  addPaymentsCheckout(data) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.PAYMENTS_API,
    });
    return instWithCred.post('/checkout', data);
  },

  approvePayment(payload) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.PAYMENTS_API,
    });
    return instWithCred.post('/capture', payload);
  },
  getAllPayments({
    page = 1, perPage = 5, filter,
  }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    const filterQuery = filter ? `&filter=${filter}` : '';
    const sortQuery = '&sort={"createdAt": -1}';
    return instWithCred.get(`partner-payoffs?page=${page}&per_page=${perPage}${filterQuery}${sortQuery}`);
  },
  getPaymentsHistory({
    page = 1, perPage = 5, search, filter, sort,
  }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.PAYMENTS_API,
    });
    const searchQuery = search ? `&search=${search}` : '';
    const filterQuery = filter ? `&filter=${filter}` : '';
    const sortQuery = sort ? `&sort=${JSON.stringify(sort)}` : '&sort={"createdAt": -1}';
    return instWithCred.get(`?page=${page}&per_page=${perPage}${searchQuery}${filterQuery}${sortQuery}`);
  },
  refundPayment(refund) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.PAYMENTS_API,
    });
    return instWithCred.post('/refund', refund);
  },
};
