<template>
  <div
    class="ub-image-cell"
    :class="{'ub-image-cell_text-overflow': textOverflow}"
    @mouseenter="setDisplayPopup($event, true)"
    @mouseleave="setDisplayPopup($event, false)"
  >
    <countryFlag
      v-if="countryFlag"
      ref="list"
      :countries="countriesList"
      :index="index"
      :activeItemModal.sync="activeItemModal"
      @showCountriesModal="showCountriesModal"
      @getCountriesList="getCountriesList"
      @toggleActiveModal="toggleActiveModal"
    />
    <div
      class="ub-image-cell__image"
      :style="{
        padding,
        background,
        width,
        minWidth: width,
        height
      }"
      @click.stop="selectModal ? openPersonModal($event) : false"
    >
      <img
        v-if="image"
        :src="image"
        alt="table"
        :title="textOverflow && imageTitle ? imageTitle : ''"
      />
      <i
        v-else-if="defaultIcon"
        :class="'ub-icon-' + defaultIcon"
      />
    </div>
    <span
      v-if="imageTitle && !countryFlag"
      class="ub-image-cell__image-title"
      :class="{'ub-image-cell__image-title_bold': column.bold}"
      :title="textOverflow && imageTitle ? imageTitle : ''"
    >
      {{ imageTitle }}
    </span>
    <div
      v-if="notification"
      class="ub-image-cell__notification"
    >
      {{ notification }}
    </div>
    <transition name="ub-bounce">
      <div
        v-if="popup && hoverImage"
        class="ub-image-cell-popup"
        :style="popupPosition"
      >
        {{ popup }}
      </div>
    </transition>
  </div>
</template>

<script>
import { get } from 'lodash';
import countryFlag from '@/components/UBTable/UBCountryFlag/CountryFlag';

export default {
  name: 'UBImageCell',
  components: {
    countryFlag,
  },
  props: {
    activeItemModal: {
      type: Boolean,
      default: false,
    },
    countryFlag: {
      type: Boolean,
      default: false,
    },
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
    index: {
      type: Number,
      default: () => -1,
    },
  },
  data() {
    return {
      hoverImage: false,
      popupPosition: {},
    };
  },
  computed: {
    textOverflow() {
      return get(this.column, 'textOverflow', false);
    },
    padding() {
      return `${get(this.column, 'imageStyle.padding', 0)}px`;
    },
    background() {
      return get(this.column, 'imageStyle.background', 'transparent');
    },
    width() {
      return `${get(this.column, 'imageStyle.width', 20)}px`;
    },
    height() {
      return `${get(this.column, 'imageStyle.height', 20)}px`;
    },
    countriesList() {
      return this.row.country;
    },
    notification() {
      const notification = get(this.row, this.column.notification, 0);
      return notification > 9 ? 9 : notification;
    },
    image() {
      const image = get(this.row, this.column.display, '');
      return image;
    },
    imageTitle() {
      const imageTitle = get(this.row, this.column.displayTitle, '');
      return imageTitle;
    },
    value() {
      const value = get(this.row, this.column.prop, '');
      return value;
    },
    defaultIcon() {
      return get(this.column, 'defaultIcon', '');
    },
    popup() {
      return get(this.row, this.column.popup, '');
    },
    selectModal() {
      return get(this.column, 'selectModal', '');
    },
  },
  methods: {
    openPersonModal(e) {
      this.$emit('toggleSetActiveModal', true, this.row, this.selectModal);
      this.$emit('showPersonModal', e);
    },
    toggleActiveModal(data) {
      this.$emit('toggleActiveModal', data);
    },
    showCountriesModal(e) {
      this.$emit('showCountriesModal', e, this.countriesList);
    },
    getCountriesList(data) {
      this.$emit('getCountriesList', data);
    },
    setDisplayPopup(e, val) {
      this.hoverImage = val;
      const position = e.target.getBoundingClientRect();
      if (val && position) {
        this.popupPosition = {
          position: 'fixed',
          top: `${position.top + position.height + 4}px`,
          left: 'auto',
        };
      } else {
        this.popupPosition = {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-image-cell {
    position: relative;
    display: flex;
    align-items: center;

    &_text-overflow {
      max-width: 100%;

      .ub-image-cell__image-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

    }

    &__image {
     position: relative;
     width: 20px;
     min-width: 20px;
     height: 20px;
     color: $color-black;
     font-size: $font-size-h1;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;

     img {
       display: block;
       width: 100%;
       height: 100%;
       object-fit: cover;
       border-radius: 50%;
       min-width: 20px;
     }

      i {
        color: $color-white;
      }

     & + &-title {
       margin-left: 6px;
       text-transform: capitalize;
     }

     &-title {
       font: $font-size-base $font-global;
       line-height: 1.5;

       &_bold {
         font-family: $font-global-medium;
       }

     }

     &:before {
       margin: 0;
     }
   }

    &__notification {
      width: 16px;
      height: 16px;
      border: 2px solid $color-white;
      margin-left: 8px;
      background: $color-green;
      border-radius: 50%;
      font: $font-size-small $font-global;
      line-height: 1;
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-popup {
      position: absolute;
      left: auto;
      top: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 4px 7px;
      background: rgba($color-black, .55);
      font-weight: 500;
      font-size: $font-size-sm;
      line-height: 13px;
      text-align: center;
      color: $color-white;
      border-radius: $borderRadiusMedium;
      z-index: $z-index-small;
      pointer-events: none;
      text-transform: capitalize;

      &:before {
        content: '';
        position: absolute;
        left: auto;
        top: -3px;
        display: block;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 3px solid rgba($color-black, .55);
      }
    }
  }

</style>
