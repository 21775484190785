<template>
  <div class="admin-modal">
    <loader v-if="loader" />
    <div class="admin-modal-wrap admin-modal-wrap_padding">
      <div class="admin-modal-wrap-header">
        <div class="admin-modal-wrap-header__title">
          {{ $t('clientProfileModal.title') }}
        </div>
        <div
          class="admin-modal-wrap-header__close"
          @click="closeModal"
        >
          <i class="ub-icon-close-circle admin-modal-wrap-header__icon"></i>
        </div>
      </div>
      <VuePerfectScrollbar>
        <div class="admin-modal-wrap-content">
          <div class="admin-modal-wrap-content-contact">
            <div class="admin-modal-wrap-content-contact__section">
              <div class="admin-modal-wrap-content-contact__title">
                {{ $t('profilePage.personalInfo') }}
              </div>
              <div class="admin-modal-wrap-content-contact-row">
                <div class="admin-modal-wrap-content-contact-row__item">
                  <div class="admin-modal-wrap-content-contact-row__label">
                    {{ $t('profilePage.name') }}
                  </div>
                  <div class="admin-modal-wrap-content-contact-row__text">
                    <textInput
                      :typeInput="'text'"
                      :autofocus="true"
                      :value.sync="user.firstName"
                      :class="{'error': errors.has('firstName')}"
                      :errorStatus="errors.has('firstName')"
                      :textCapitalize="true"
                    >
                    </textInput>
                    <transition name="fade-el">
                      <span
                        v-if="$validator.errors.has('firstName')"
                        class="validation"
                      >
                        {{ $validator.errors.first('firstName') }}
                      </span>
                    </transition>
                  </div>
                </div>
                <div class="admin-modal-wrap-content-contact-row__item">
                  <div class="admin-modal-wrap-content-contact-row__label">
                    {{ $t('profilePage.lastname') }}
                  </div>
                  <div class="admin-modal-wrap-content-contact-row__text">
                    <textInput
                      :typeInput="'text'"
                      :value.sync="user.lastName"
                      :class="{'error': errors.has('lastName')}"
                      :errorStatus="errors.has('lastName')"
                      :textCapitalize="true"
                    >
                    </textInput>
                  </div>
                </div>
                <div class="admin-modal-wrap-content-contact-row__item">
                  <div class="admin-modal-wrap-content-contact-row__label">
                    E-mail
                  </div>
                  <div class="admin-modal-wrap-content-contact-row__text">
                    <textInput
                      :typeInput="'email'"
                      :value.sync="user.email"
                      :class="{'error': errors.has('email')}"
                      :errorStatus="errors.has('email')"
                    >
                    </textInput>
                    <transition name="fade-el">
                      <span
                        v-if="$validator.errors.has('email')"
                        class="validation"
                      >
                        {{ $validator.errors.first('email') }}
                      </span>
                    </transition>
                  </div>
                </div>
                <div class="admin-modal-wrap-content-contact-row__item">
                  <div class="admin-modal-wrap-content-contact-row__label">
                    {{ $t('profilePage.phone') }}
                  </div>
                  <div class="admin-modal-wrap-content-contact-row__text">
                    <VueTelInput
                      v-model="user.phone"
                      name="phone"
                      :required="required"
                      :validCharactersOnly="validCharactersOnly"
                      :inputOptions="inputOptions"
                      :placeholder="placeholder"
                      :maxLen="maxLen"
                      :class="{'error': $validator.errors.has('phone')}"
                      :errorStatus="$validator.errors.has('phone')"
                    />
                    <transition name="fade-el">
                      <span
                        v-if="$validator.errors.has('phone')"
                        class="validation"
                      >
                        {{ $validator.errors.first('phone') }}
                      </span>
                    </transition>
                    <transition name="fade-el">
                      <i
                        v-if="$validator.errors.has('phone')"
                        class="ub-icon-info-valid admin-modal-wrap-content-contact-row__icon
                      admin-modal-wrap-content-contact-row__icon_error"
                      ></i>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="admin-modal-wrap-content-contact__section"
            >
              <div
                class="admin-modal-wrap-content-contact__title"
              >
                {{ $t('profilePage.maillingAdress') }}
              </div>
              <div class="admin-modal-wrap-content-contact-row">
                <div class="admin-modal-wrap-content-contact-row__item">
                  <div class="admin-modal-wrap-content-contact-row__label">
                    {{ $t('profilePage.country') }}
                  </div>
                  <div class="admin-modal-wrap-content-contact-row__text">
                    <selectWithKeyTemplate
                      :settings="selectSettings"
                      :class="{'error': errors.has('country')}"
                      @changeSelect="changeSelect($event)"
                    />
                    <transition name="fade-el">
                      <span
                        v-if="$validator.errors.has('country')"
                        class="validation"
                      >
                        {{ $validator.errors.first('country') }}
                      </span>
                    </transition>
                  </div>
                </div>
                <div class="admin-modal-wrap-content-contact-row__item">
                  <div class="admin-modal-wrap-content-contact-row__label">
                    {{ $t('profilePage.region') }}
                  </div>
                  <div class="admin-modal-wrap-content-contact-row__text">
                    <textInput
                      :typeInput="'text'"
                      :value.sync="user.district"
                    >
                    </textInput>
                  </div>
                </div>
                <div
                  class="admin-modal-wrap-content-contact-row__item
                admin-modal-wrap-content-contact-row__item_flex"
                >
                  <div class="user-city">
                    <div class="admin-modal-wrap-content-contact-row__label">
                      {{ $t('profilePage.city') }}
                    </div>
                    <div class="admin-modal-wrap-content-contact-row__text">
                      <textInput
                        :typeInput="'text'"
                        :value.sync="user.city"
                      >
                      </textInput>
                    </div>
                  </div>
                  <div class="user-postcode">
                    <div class="admin-modal-wrap-content-contact-row__label">
                      {{ $t('profilePage.index') }}
                    </div>
                    <div class="admin-modal-wrap-content-contact-row__text">
                      <textInput
                        :typeInput="'text'"
                        :value.sync="user.postcode"
                      >
                      </textInput>
                    </div>
                  </div>
                </div>
                <div
                  class="
                admin-modal-wrap-content-contact-row__item
                admin-modal-wrap-content-contact-row__item_flex
            "
                >
                  <div class="user-street">
                    <div class="admin-modal-wrap-content-contact-row__label">
                      {{ $t('profilePage.street') }}
                    </div>
                    <div class="admin-modal-wrap-content-contact-row__text">
                      <textInput
                        :typeInput="'text'"
                        :value.sync="user.street"
                        :textCapitalize="true"
                      >
                      </textInput>
                    </div>
                  </div>
                  <div class="user-house">
                    <div class="admin-modal-wrap-content-contact-row__label">
                      {{ $t('profilePage.house') }}
                    </div>
                    <div class="admin-modal-wrap-content-contact-row__text">
                      <textInput
                        :typeInput="'text'"
                        :value.sync="user.house"
                      >
                      </textInput>
                    </div>
                  </div>
                  <div class="user-flat">
                    <div class="admin-modal-wrap-content-contact-row__label">
                      {{ $t('profilePage.flat') }}
                    </div>
                    <div class="admin-modal-wrap-content-contact-row__text">
                      <textInput
                        :typeInput="'text'"
                        :value.sync="user.room"
                      >
                      </textInput>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VuePerfectScrollbar>
      <div class="admin-modal-wrap-footer">
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_cancel"
            @click="closeModal"
          >
            {{ $t('global.cancel') }}
          </button>
        </div>
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_primary"
            @click="updateUser"
          >
            {{ $t('buttons.save') }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-shortkey="['esc']"
      class="admin-modal__overlay"
      @click="closeModal"
      @shortkey="closeModal"
    ></div>
  </div>
</template>

<script>
import validationErrorMessage from '@/locales/validationErrorMessage';
import loader from '@/components/loader';
import textInput from '@/components/fields/textInput';
import { cloneDeep, get, sortBy } from 'lodash';
import { VueTelInput } from 'vue-tel-input';
import userApi from '@/api/user/userApi';
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'ClientProfileModal',
  components: {
    loader,
    textInput,
    VueTelInput,
    selectWithKeyTemplate,
    VuePerfectScrollbar,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userDef: {},
      loader: false,
      validCharactersOnly: true,
      required: true,
      maxLen: 17,
      placeholder: '',
    };
  },
  computed: {
    selectSettings() {
      const { country = {} } = this.user;
      return {
        optionList: this.selectCountriesList,
        placeholderText: this.$t('countryPage.placeholderSelect'),
        selectedKey: country.code,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: '',
        imagePosition: 'left',
        showImageInput: true,
      };
    },
    inputOptions() {
      return {
        required: true,
        mode: 'national',
        enabledFlags: false,
      };
    },
    user: {
      get() {
        return this.userDef;
      },
      set(data) {
        this.userDef = data;
      },
    },
    language() {
      return this.$i18n.locale;
    },
    defaultCountries() {
      return this.$store.getters.defaultCountries;
    },
    selectCountriesList() {
      const newArr = [];
      const arrSortByName = sortBy(this.defaultCountries, `name.${this.language}`);
      arrSortByName.forEach((item) => {
        newArr.push({
          text: item.name[this.language],
          key: item.code,
          image: `country/${item.code}.svg`,
        });
      });
      return newArr;
    },
  },
  beforeMount() {
    const {
      clientCountry,
    } = this.currentUser;
    const clientCountryObj = cloneDeep(this.defaultCountries.find((country) => country.code === clientCountry));
    this.user = {
      ...cloneDeep(this.currentUser),
      country: clientCountryObj,
    };
    const dict = {
      en: {
        custom: {
          firstName: {
            required: validationErrorMessage.en.inputRequired,
          },
          email: {
            required: validationErrorMessage.en.inputRequired,
            email: validationErrorMessage.en.inputEmail,
          },
          phone: {
            required: validationErrorMessage.en.inputPhone,
          },
          country: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        custom: {
          firstName: {
            required: validationErrorMessage.ru.inputRequired,
          },
          email: {
            required: validationErrorMessage.ru.inputRequired,
            default: validationErrorMessage.ru.inputEmail,
          },
          phone: {
            required: validationErrorMessage.ru.inputPhone,
          },
          country: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'firstName', rules: { required: true } });
    this.$validator.attach({
      name: 'email',
      rules: {
        required: true,
        // eslint-disable-next-line max-len
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
    });
    this.$validator.attach({ name: 'phone', rules: { required: true } });
  },
  methods: {
    updateUser() {
      const {
        firstName,
        country,
        street,
        city,
        district,
        room,
        postcode,
        phone,
        email,
        salesManager: { _id: id },
      } = this.user;
      this.$validator.validateAll({
        firstName,
        country,
        street,
        city,
        district,
        room,
        postcode,
        phone: phone.length === this.maxLen
        || phone.length >= 11 ? phone : '',
        email,
      }).then((result) => {
        if (result) {
          const user = {
            ...this.user,
            salesManager: id,
            clientCountry: get(this.user, 'country.code', ''),
            country: undefined,
          };
          userApi.updateUser(user).then((resp) => {
            this.loader = false;
            const currentCountry = this.defaultCountries.find((country) => country.code === user.clientCountry);
            const userData = {
              ...resp.data,
              country: currentCountry,
            };
            this.$emit('updateClientProfile', userData);
            this.$emit('closeModal');
          }).catch((err) => {
            this.loader = false;
            const str = err.response.data.message;
            if (err.response && (str.includes('duplicate key'))) {
              this.$validator.errors.add({
                field: 'email',
                msg: this.$t('loginPage.loginForm.signupValidation'),
              });
            }
          });
        }
      });
    },
    cancelEdit() {
      this.$emit('editMode', false);
      this.$validator.reset();
    },
    changeSelect(key) {
      const { country } = this.user;
      if (!country) {
        this.$set(this.user, 'country', {
          code: key,
        });
      } else {
        country.code = key;
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";

.admin-modal-wrap {
  width: 540px;

  &-content {
    color: $color-black;
    display: flex;

    &-contact {
      width: 100%;

      &__edit {
        color: $color-dodger-blue;
        cursor: pointer;
        transition: color .15s ease-in;
        margin-bottom: 16px;

        &:hover {
          color: $color-cornflower-blue;
        }
      }

      &__section {
        margin-bottom: 34px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-row {
        display: flex;
        flex-wrap: wrap;
        margin: -9px -10px;

        &__icon {
          position: absolute;
          right: 12px;
          top: 50%;
          color: $color-silver-chalice;
          transition: color .15s ease-in;
          cursor: pointer;
          transform: translateY(-50%);

          &_error {
            pointer-events: none;
            color: $color-cardinal !important;
          }
        }

        &__item {
          width: 100%;
          padding: 9px 10px;
          position: relative;

          &_flex {
            display: flex;
          }

          &_width {
            width: 32%;
          }
        }

        &__label {
          font: $font-size-md $font-global-medium;
          color: $color-silver-chalice;
          margin-bottom: 6px;
        }

        &__text {
          font: $font-size-base $font-global;
          color: $color-black;
          position: relative;

          &-info {
            display: flex;
            align-items: center;

            img {
              border-radius: 50%;
              width: 20px;
              height: 20px;
            }

            div {
              margin-left: 7px;
            }
          }
        }
      }

      &__title {
        font: $font-size-xlg $font-global-bold;
        margin-bottom: 24px;
      }
    }
  }
}

.user-postcode {
  width: 120px;
  min-width: 120px;
  margin-left: 8px;
}

.user-flat {
  width: 120px;
  min-width: 120px;
  margin-left: 8px;
}

.user-house {
  width: 58px;
  min-width: 58px;
  margin-left: 8px;
}

.user-city, .user-street  {
  flex-grow: 1;
}

</style>
