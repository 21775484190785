import * as subTypes from '@/preset/elements/listSubTypes';
import {
  TYPE_TEXT,
  TYPE_NUMBER,
  TYPE_DATE,
  TYPE_BIRTHDAY,
  TYPE_FLAG,
  TYPE_LIST,
  TYPE_BREAK,
  TYPE_MULTILIST,
  TYPE_LINK,
  TYPE_TEXTAREA,
  TYPE_SWITCH,
} from '@/constants/typesAll';
import NEW_HEADLINE from '@/preset/elements/newHeadline';
import NEW_TEXT from '@/preset/elements/newText';
import NEW_NUMBER from '@/preset/elements/newNumber';
import NEW_LINK from '@/preset/elements/newLink';
import NEW_TEXTAREA from '@/preset/elements/newTextarea';
import NEW_BREAK from '@/preset/elements/newBreak';
import NEW_LIST from '@/preset/elements/newList';
import NEW_MULTILIST from '@/preset/elements/newMultiList';
import NEW_SWITCH from '@/preset/elements/newSwitch';
import NEW_FLAG from '@/preset/elements/newFlag';
import NEW_DATE from '@/preset/elements/newDate';
import NEW_BIRTHDAY from '@/preset/elements/newBirthday';
import NEW_RADIO_BUTTON from '@/preset/elements/radioButton';
import NEW_ACCORDEON from '@/preset/elements/newAccordeon';
import NEW_UPLOAD_FILE from '@/preset/elements/newUploadFile';
import NEW_DESCRIPTION from '@/preset/elements/newDescription';
import NEW_CHECKBOX from '@/preset/elements/newCheckbox';

const LIST_FUNCTION = {
  [subTypes.SUB_TYPE_HEADLINE]: NEW_HEADLINE,
  [subTypes.SUB_TYPE_RADIO]: NEW_RADIO_BUTTON,
  [subTypes.SUB_TYPE_ACCORDEON]: NEW_ACCORDEON,
  [subTypes.SUB_TYPE_UPLOAD_FILE]: NEW_UPLOAD_FILE,
  [subTypes.SUB_TYPE_CHECKBOX]: NEW_CHECKBOX,
  [subTypes.SUB_TYPE_DESCRIPTION]: NEW_DESCRIPTION,
};

export const FIELD_FUNCTION = {
  [TYPE_TEXT]: NEW_TEXT,
  [TYPE_NUMBER]: NEW_NUMBER,
  [TYPE_LINK]: NEW_LINK,
  [TYPE_TEXTAREA]: NEW_TEXTAREA,
  [TYPE_BREAK]: NEW_BREAK,
  [TYPE_LIST]: NEW_LIST,
  [TYPE_MULTILIST]: NEW_MULTILIST,
  [TYPE_SWITCH]: NEW_SWITCH,
  [TYPE_FLAG]: NEW_FLAG,
  [TYPE_DATE]: NEW_DATE,
  [TYPE_BIRTHDAY]: NEW_BIRTHDAY,
};

export default LIST_FUNCTION;
