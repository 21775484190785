import * as subTypes from '@/preset/elements/listSubTypes';
import {
  TYPE_TEXT,
  TYPE_NUMBER,
  TYPE_DATE,
  TYPE_BIRTHDAY,
  TYPE_FLAG,
  TYPE_LIST,
  TYPE_BREAK,
  TYPE_MULTILIST,
  TYPE_LINK,
  TYPE_TEXTAREA,
  TYPE_SWITCH,
} from '@/constants/typesAll';

export const headline = () => import('@/components/elements/headline');
export const textField = () => import('@/components/elements/textField');
export const numberField = () => import('@/components/elements/numberField');
export const linkField = () => import('@/components/elements/linkField');
export const textareaField = () => import('@/components/elements/textareaField');
export const breakField = () => import('@/components/elements/breakField');
export const listField = () => import('@/components/elements/listField');
export const switchField = () => import('@/components/elements/switchField');
export const multiListField = () => import('@/components/elements/multiListField');
export const flagField = () => import('@/components/elements/flagField');
export const dateField = () => import('@/components/elements/dateField');
export const birthdayField = () => import('@/components/elements/birthdayField');
export const radioButton = () => import('@/components/elements/radioButton');
export const accordeon = () => import('@/components/elements/accordeon');
export const uploadFile = () => import('@/components/elements/uploadFile');
export const description = () => import('@/components/elements/description/description');
export const checkboxField = () => import('@/components/elements/checkboxField');

export const LIST_COMPONENT = {
  [subTypes.SUB_TYPE_HEADLINE]: headline,
  [subTypes.SUB_TYPE_RADIO]: radioButton,
  [subTypes.SUB_TYPE_ACCORDEON]: accordeon,
  [subTypes.SUB_TYPE_UPLOAD_FILE]: uploadFile,
  [subTypes.SUB_TYPE_DESCRIPTION]: description,
  [subTypes.SUB_TYPE_CHECKBOX]: checkboxField,
};

export const LIST_FIELD = {
  [TYPE_TEXT]: textField,
  [TYPE_NUMBER]: numberField,
  [TYPE_LINK]: linkField,
  [TYPE_TEXTAREA]: textareaField,
  [TYPE_BREAK]: breakField,
  [TYPE_LIST]: listField,
  [TYPE_SWITCH]: switchField,
  [TYPE_MULTILIST]: multiListField,
  [TYPE_FLAG]: flagField,
  [TYPE_DATE]: dateField,
  [TYPE_BIRTHDAY]: birthdayField,
};
