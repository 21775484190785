export const paymentStatus = {
  PREPAYMENT_MADE: 'PM',
  FULL_PAYMENT: 'FP',
  NOT_PAYMENT: 'NP',
};

export const paymentStatuses = {
  [paymentStatus.PREPAYMENT_MADE]: {
    name: {
      ru: 'Внесена предоплата',
      en: 'Prepayment made',
    },
    color: 'orange',
    code: paymentStatus.PREPAYMENT_MADE,
  },
  [paymentStatus.FULL_PAYMENT]: {
    name: {
      ru: 'Оплачен ',
      en: 'Paid',
    },
    color: 'green',
    code: paymentStatus.FULL_PAYMENT,
  },
  [paymentStatus.NOT_PAYMENT]: {
    name: {
      ru: 'Не оплачен',
      en: 'Not Payment',
    },
    color: 'red',
    code: paymentStatus.NOT_PAYMENT,
  },
};
