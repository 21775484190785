import { TYPE_ELEMENT } from '@/preset/listElements';
import { SUB_TYPE_ACCORDEON } from '@/preset/elements/listSubTypes';
import NEW_DESCRIPTION from '@/preset/elements/newDescription';
import getUniqueId from '@/mixins/uniqueId';

const NEW_ACCORDEON = () => ({
  fieldId: getUniqueId(),
  type: TYPE_ELEMENT,
  sub: SUB_TYPE_ACCORDEON,
  title: '',
  description: NEW_DESCRIPTION(),
  placeholder: {
    title: {
      ru: 'Аккордеон',
      en: 'Accordion',
    },
    description: {
      ru: 'Практика понимания письменного английского языка улучшит ваш словарный запас, а также понимание '
          + 'грамматики и порядка слов.',
      en: 'Practicing your comprehension of written English will both improve your vocabulary'
          + ' and understanding of grammar and word order',
    },
  },
  parentId: [],
  filesList: [],
});

export default NEW_ACCORDEON;
