<template>
  <div class="message-item__value">
    <div
      v-if="!isMe"
      class="message-item__label"
    >
      <template v-if="!item.isSystem">
        {{ lableText }}
      </template>
      <template v-else>
        {{ $t('chat.systemMessage') }}
      </template>
    </div>
    <span>
      {{ item.message }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'ChatMessage',
  props: {
    clientName: {
      type: String,
    },
    item: {
      type: Object,
      default: () => {},
    },
    isMe: {
      type: Boolean,
      default: false,
    },
    chatPartnerId: {
      type: String,
      default: '',
    },
    chatClientId: {
      type: String,
      default: '',
    },
    chatAdminsId: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    lableText() {
      const { senderId } = this.item;
      if (senderId === this.chatPartnerId) {
        return this.$t('chat.partner');
      }
      if (this.chatAdminsId.includes(senderId)) {
        return this.$t('chat.admin');
      }
      return this.clientName;
    },
  },
};
</script>

<style scoped>

</style>
