export const OWNER = 'Owner';
export const CEO = 'CEO';
export const CLIENT = 'Client';
export const PARTNER = 'Partner';
export const MANAGER = 'Manager';
export const DEPARTMENT_HEAD = 'DepartmentHead';
export const UB_CLIENT_MANAGER = 'UB_Client_Manager';
export const UB_CLIENT_DEPARTMENT_HEAD = 'UB_Client_Department_Head';
export const UB_CONTACT_MANAGER = 'UB_Contact_Manager';
export const UB_CONTACT_DEPARTMENT_HEAD = 'UB_Contact_Department_Head';
export const UB_BUSINESS_DEVELOPMENT_MANAGER = 'UB_Business_Development_Manager';
export const UB_BUSINESS_DEVELOPMENT_DEPARTMENT_HEAD = 'UB_Business_Development_Department_Head';
export const UB_CONTENT_MANAGER = 'UB_Content_Manager';
export const UB_SERVICES_MANAGER = 'UB_Services_Manager';
