import { TYPE_ELEMENT } from '@/preset/listElements';
import { SUB_TYPE_HEADLINE } from '@/preset/elements/listSubTypes';
import getUniqueId from '@/mixins/uniqueId';
import i18n from '@/i18n';

const NEW_HEADLINE = () => ({
  fieldId: getUniqueId(),
  type: TYPE_ELEMENT,
  sub: SUB_TYPE_HEADLINE,
  value: '',
  placeholder: {
    value: {
      ru: i18n.t('elements.heading'),
      en: i18n.t('elements.heading'),
    },
  },
  parentId: [],
});

export default NEW_HEADLINE;
