<template>
  <div
    v-if="description"
    class="ub-accordion-cell"
  >
    <div class="ub-accordion-cell-control">
      <i
        :key="'plus'"
        class="ub-icon-plus ub-accordion-cell-control__icon"
        :class="{'ub-accordion-cell-control__icon_active': !openContent}"
        @click="setOpen(true)"
      />
      <i
        :key="'minus'"
        class="ub-icon-minus ub-accordion-cell-control__icon"
        :class="{'ub-accordion-cell-control__icon_active': openContent}"
        @click="setOpen(false)"
      />
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'UBAccordionCell',
  props: {
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      openContent: false,
    };
  },
  computed: {
    description() {
      return get(this.row, this.column.prop, '');
    },
  },
  watch: {
    row() {
      const val = get(this.row, '$accordionContent', false);
      this.setOpen(val);
    },
  },
  methods: {
    setOpen(val) {
      this.openContent = val;
      this.$set(this.row, '$accordionContent', val);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-accordion-cell {
    font-family: $font-global;

    &-control {
      position: relative;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $borderRadiusSmall;
      box-sizing: border-box;
      border: 1px solid rgba($color-black, .3);
      color: $color-black;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        color: $color-black;
        border-color: rgba($color-black, .6);
      }

      &__icon {
        position: absolute;
        top: auto;
        left: auto;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;
        pointer-events: none;
        transition: 0.2s;

        &_active {
          font-size: $font-size-h6;
          pointer-events: auto;
        }

        &:before {
          margin: 0;
        }
      }
    }

    &-content {
      background: $color-white;
      border-radius: $borderRadius;
      padding: 12px;
      font-weight: normal;
      font-size: $font-size-md;
      line-height: 16px;
      z-index: 3;
    }
  }
</style>
