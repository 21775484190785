<template>
  <div
    class="ub-content"
  >
    <loader v-if="loader" />
    <OrderInfoProgressBar
      v-if="Object.keys(currentOrder).length"
      :orderId="orderId"
      :status="orderStatus"
      :userRole="userRole"
      :fullPrice="fullPrice"
      :currency="currency"
      :language="language"
      :value="progressBarStatus"
      :barClass="'progress-bar__blue'"
      :days="progressBarDays"
      :userId="userId"
      :openChat.sync="openChat"
      :currentOrder="currentOrder"
      :procentPayment="procentPayment"
      :paymentsData="paymentsData"
      @progressBarClickEvent="changeAcceptOrder"
      @finishOrder="finishOrder"
      @addProjectManager="addProjectManager"
      @cancelOrder="cancelOrder"
      @toggleStatusToPaid="toggleStatusToPaid"
    />
    <div
      class="ub-content-description"
      :class="{'ub-content-description_margin': showInfoText}"
      @click="toggleShowContent()"
    >
      <div class="ub-content-description__text">
        {{ $t('orderDescription.text') }}
      </div>
      <i
        class="ub-icon-arrow ub-content-description__icon"
        :class="{'ub-content-description__icon_up': showInfoText}"
      ></i>
    </div>
    <transition name="fade-el">
      <div
        v-if="showInfoText"
        class="ub-content-description-info"
        :class="{'ub-content-description-info_margin': showInfoText}"
      >
        <div
          v-if="ubOutDepartment.length"
          class="ub-content-description-info__list"
        >
          <div class="ub-content-description-info__title">
            {{ $t('orderDescription.ownerNotes') }}
          </div>
          <div
            v-for="el in ubOutDepartment"
            :key="el._id"
            class="ub-content-description-info__text"
          >
            <div
              class="ub-content-description-info__text-content"
              v-html="el.text"
            ></div>
            <div
              v-for="file in el.files"
              :key="file.id"
              class="ub-content-description-info__files"
            >
              <i class="ub-icon-file-1 ub-content-description-info__files-icon"></i>
              <a
                :href="file.url"
                class="ub-content-description-info__files-link"
              >
                {{ file.originalName }}
              </a>
            </div>
            <div
              v-if="isVisibleChangeDescription"
              class="action-elements"
              :class="activeDescription === el._id ? 'ub-icon-cross-x' : 'ub-icon-more-vertical'"
              @click.stop="openWindowSettings(el._id)"
            >
              <transition
                name="fade-el"
                mode="out-in"
              >
                <div
                  v-if="activeDescription === el._id && areYouShureWindow === -1"
                  v-click-outside="closeChooseWindow"
                  class="action-elements-choose"
                  @click.stop
                >
                  <div class="action-elements-choose-controls">
                    <button
                      class="ub-button action-elements-choose-controls__button"
                      @click="changeDescription(el)"
                    >
                      {{ $t('buttons.change') }}
                    </button>
                    <button
                      class="ub-button action-elements-choose-controls__button"
                      @click="areYouShureWindow = el._id"
                    >
                      {{ $t('buttons.delete') }}
                    </button>
                  </div>
                </div>
              </transition>
              <transition name="fade-el">
                <div
                  v-if="areYouShureWindow === el._id"
                  class="ub-toolbar-confirm"
                >
                  <div class="ub-toolbar-confirm__text">
                    {{ $t('orderDescription.areYouShureWindow') }}
                  </div>
                  <div class="ub-toolbar-confirm-controls">
                    <button
                      class="ub-button ub-toolbar-confirm-controls__button"
                      @click.stop="closeModal"
                    >
                      {{ $t('buttons.no') }}
                    </button>
                    <button
                      class="ub-button ub-toolbar-confirm-controls__button"
                      @click.stop="deleteDescription(el._id)"
                    >
                      {{ $t('buttons.yes') }}
                    </button>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div
          v-if="ubContact.length"
          class="ub-content-description-info__list"
        >
          <div
            class="ub-content-description-info__title"
          >
            {{ $t('orderDescription.saleNotes') }}
          </div>
          <div
            v-for="el in ubContact"
            :key="el._id"
            class="ub-content-description-info__text"
          >
            <div
              class="ub-content-description-info__text-content"
              v-html="el.text"
            ></div>
            <div
              v-for="file in el.files"
              :key="file.id"
              class="ub-content-description-info__files"
            >
              <i class="ub-icon-file-1 ub-content-description-info__files-icon"></i>
              <a
                :href="file.url"
                class="ub-content-description-info__files-link"
              >
                {{ file.originalName }}
              </a>
            </div>
            <div
              v-if="isVisibleChangeDescription"
              class="action-elements"
              :class="activeDescription === el._id ? 'ub-icon-cross-x' : 'ub-icon-more-vertical'"
              @click.stop="openWindowSettings(el._id)"
            >
              <transition
                name="fade-el"
                mode="out-in"
              >
                <div
                  v-if="activeDescription === el._id && areYouShureWindow === -1"
                  v-click-outside="closeChooseWindow"
                  class="action-elements-choose"
                  @click.stop
                >
                  <div class="action-elements-choose-controls">
                    <button
                      class="ub-button action-elements-choose-controls__button"
                      @click="changeDescription(el)"
                    >
                      {{ $t('buttons.change') }}
                    </button>
                    <button
                      class="ub-button action-elements-choose-controls__button"
                      @click="areYouShureWindow = el._id"
                    >
                      {{ $t('buttons.delete') }}
                    </button>
                  </div>
                </div>
              </transition>
              <transition name="fade-el">
                <div
                  v-if="areYouShureWindow === el._id"
                  class="ub-toolbar-confirm"
                >
                  <div class="ub-toolbar-confirm__text">
                    {{ $t('orderDescription.areYouShureWindow') }}
                  </div>
                  <div class="ub-toolbar-confirm-controls">
                    <button
                      class="ub-button ub-toolbar-confirm-controls__button"
                      @click.stop="closeModal"
                    >
                      {{ $t('buttons.no') }}
                    </button>
                    <button
                      class="ub-button ub-toolbar-confirm-controls__button"
                      @click.stop="deleteDescription(el._id)"
                    >
                      {{ $t('buttons.yes') }}
                    </button>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div
          v-if="ubClient.length"
          class="ub-content-description-info__list"
        >
          <div class="ub-content-description-info__title">
            {{ $t('orderDescription.projectManagerNotes') }}
          </div>
          <div
            v-for="el in ubClient"
            :key="el._id"
            class="ub-content-description-info__text"
          >
            <div
              class="ub-content-description-info__text-content"
              v-html="el.text"
            ></div>
            <div
              v-for="file in el.files"
              :key="file.id"
              class="ub-content-description-info__files"
            >
              <i class="ub-icon-file-1 ub-content-description-info__files-icon"></i>
              <a
                :href="file.url"
                class="ub-content-description-info__files-link"
              >
                {{ file.originalName }}
              </a>
            </div>
            <div
              v-if="isVisibleUbClient"
              class="action-elements"
              :class="activeDescription === el._id ? 'ub-icon-cross-x' : 'ub-icon-more-vertical'"
              @click.stop="openWindowSettings(el._id)"
            >
              <transition
                name="fade-el"
                mode="out-in"
              >
                <div
                  v-if="activeDescription === el._id && areYouShureWindow === -1"
                  v-click-outside="closeChooseWindow"
                  class="action-elements-choose"
                  @click.stop
                >
                  <div class="action-elements-choose-controls">
                    <button
                      class="ub-button action-elements-choose-controls__button"
                      @click="changeDescription(el)"
                    >
                      {{ $t('buttons.change') }}
                    </button>
                    <button
                      class="ub-button action-elements-choose-controls__button"
                      @click="areYouShureWindow = el._id"
                    >
                      {{ $t('buttons.delete') }}
                    </button>
                  </div>
                </div>
              </transition>
              <transition name="fade-el">
                <div
                  v-if="areYouShureWindow === el._id"
                  class="ub-toolbar-confirm"
                >
                  <div class="ub-toolbar-confirm__text">
                    {{ $t('orderDescription.areYouShureWindow') }}
                  </div>
                  <div class="ub-toolbar-confirm-controls">
                    <button
                      class="ub-button ub-toolbar-confirm-controls__button"
                      @click.stop="closeModal"
                    >
                      {{ $t('buttons.no') }}
                    </button>
                    <button
                      class="ub-button ub-toolbar-confirm-controls__button"
                      @click.stop="deleteDescription(el._id)"
                    >
                      {{ $t('buttons.yes') }}
                    </button>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <template v-if="!isClientDepartment || checkProjectManager">
          <transition
            name="fade-el"
            mode="out-in"
          >
            <button
              v-if="!isVisibleDescription"
              :key="'first'"
              class="button-action button-action_offset"
              @click="addNewDescription"
            >
              <i class="ub-icon-cross"></i>
              <span>
                <template v-if="!checkProjectManager">
                  {{ $t('orderDescription.btn') }}
                </template>
                <template v-else>
                  {{ $t('orderDescription.btnProject') }}
                </template>
              </span>
            </button>
            <div
              v-else
              :key="'second'"
              class="ub-content-description__add"
            >
              <UBContentDescription
                :descriptionArray.sync="descriptionArray"
                :currentDescription="isVisibleDescription ? currentDescription : {}"
                :editStatus="editStatus"
                :row="currentOrder"
                @closeDescription="closeDescription"
              />
            </div>
          </transition>
        </template>
      </div>
    </transition>
    <div class="ub-content-header">
      <div
        class="ub-content-header__order"
      >
        <div class="ub-content-header__order-text">
          {{ $t('orderPage.table.order') }}
        </div>
      </div>
      <div class="ub-content-header__timing">
        {{ $t('orderPage.table.timing') }}
      </div>
      <div class="ub-content-header__price">
        {{ $t('orderPage.table.cost') }}
      </div>
      <div class="ub-content-header__status">
        {{ $t('orderPage.table.status') }}
      </div>
    </div>
    <div class="ub-content-content">
      <div
        v-for="(item, index) in stepList"
        :key="item.stepId"
      >
        <div>
          <div
            class="ub-content-content-row"
          >
            <div
              class="ub-content-content__order"
            >
              <div class="ub-content-content__order-wrap">
                <div
                  class="ub-content-content__order-index"
                >
                  {{ index + 1 }}
                </div>
                <div class="ub-content-content__order-info">
                  <div>
                    {{ item.titleStep }}
                  </div>
                  <div
                    class="ub-content-content__order_more"
                    :class="{'ub-content-content__order_more_disable': !item.fields.length}"
                    @click="setActiveItem(item.stepId)"
                  >
                    <template
                      v-if="activeItem === item.stepId"
                    >
                      {{ $t('steps.turn') }}
                    </template>
                    <template
                      v-else
                    >
                      {{ $t('steps.details') }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="ub-content-content__timing">
              <template v-if="!item.isActive">
                &mdash;
              </template>
              <template v-else>
                {{ item.timing }}
                {{ calcDay(item.timing, [$t('global.dayOne'), $t('global.dayAlternative'), $t('global.days')]) }}
              </template>
            </div>
            <div class="ub-content-content__price">
              <template v-if="!item.isActive">
                &mdash;
              </template>
              <template v-else>
                {{ item.cost }} {{ currency }}
              </template>
            </div>
            <div class="ub-content-content__status">
              <div
                v-if="!item.isActive"
                class="ub-content-content__status-reorder"
              >
                <span class="ub-content-content__status-info ub-content-content__status-info_individually">
                  {{ $t('orderPage.byYourself') }}
                </span>
              </div>
              <div class="ub-content-content__status-file">
                <span
                  class="ub-content-content__status-info"
                  :class="{
                    'ub-content-content__status-info_process': item.status === 'IP',
                    'ub-content-content__status-info_done': item.status === 'CM'}"
                  @click.stop="changeStatusOrder(item.stepId)"
                >
                  <template v-if="item.status === 'IP'">
                    {{ $t('statuses.inProgress') }}
                  </template>
                  <template v-else-if="item.status === 'CM'">
                    {{ $t('statuses.completed') }}
                  </template>
                </span>
              </div>
              <transition name="fade-el">
                <div
                  v-if="isVisibleChangeOrderModal === item.stepId"
                  ref="modal"
                  v-click-outside="closeWindow"
                  class="order-modal-status"
                >
                  <div
                    class="order-modal-status__item order-modal-status__item_process"
                    @click="changeStatus(item, 'IP')"
                  >
                    {{ $t('statuses.inProgress') }}
                  </div>
                  <div
                    class="order-modal-status__item order-modal-status__item_done"
                    @click="changeStatus(item, 'CM')"
                  >
                    {{ $t('statuses.completed') }}
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <transition
            name="fade-el"
            mode="out-in"
          >
            <div
              v-if="activeItem === item.stepId"
              class="ub-content-content-more"
            >
              <div
                v-if="item.documents.length"
                class="ub-content-content-more-section"
              >
                <div
                  v-for="file in item.documents"
                  :key="file.fieldId"
                  class="ub-content-content-file"
                >
                  <template v-if="file.fileInfo">
                    <template v-if="!file.fileInfo.url">
                      <div class="button-action__content">
                        <div class="button-action__left">
                          <div class="button-action__icon">
                            <i class="ub-icon-file-1"></i>
                          </div>
                        </div>
                        <div class="button-action__right">
                          <div
                            class="button-action__top"
                            :class="{'button-action__top_center': file.fileInfo._id}"
                          >
                            <div class="button-action__name">
                              {{ file.fileInfo.originalName }}
                            </div>
                            <div class="button-action__percent">
                            </div>
                          </div>
                          <div
                            class="button-action__bottom"
                          >
                            <div class="button-action-progress">
                              <span class="button-action-progress__line"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <i class="ub-icon-file-1"></i>
                      <a
                        class="ub-content-content-file__link"
                        :href="file.fileInfo.url"
                      >
                        {{ file.fileInfo.originalName }}
                      </a>
                    </template>
                  </template>
                </div>
              </div>
              <div class="ub-content-content-more-section">
                <div
                  v-for="file in item.fields"
                  :key="file.fieldId"
                  class="ub-content-content-file"
                >
                  <div
                    v-if="showElement(file)"
                    class="ub-content-content-elem"
                  >
                    <ElementDefault
                      :zIndexEl="true"
                      :step="item"
                      :element.sync="file"
                    />
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="ub-content-amount">
      <div
        class="ub-content-amount__order"
      >
        <div class="ub-content-amount__order-text">
          {{ $t('global.total') }}
        </div>
      </div>
      <div class="ub-content-amount__timing">
        {{ allDays }} {{ calcNum }}
      </div>
      <div class="ub-content-amount__price">
        {{ fullPrice }} {{ currency }}
      </div>
      <div class="ub-content-amount__status"></div>
    </div>
    <chat
      v-if="false"
      :order="currentOrder"
      :openChat.sync="openChat"
      :chatId.sync="chatId"
    />
  </div>
</template>

<script>
import {
  filter, find, get,
} from 'lodash';
import ElementDefault from '@/components/elements/DefaultTemplate';
import OrderInfoProgressBar from '@/components/order/orderInfoProgressBar';
import {
  acceptedProjectRequireLastPay,
  acceptWorkWithFullyPayedOrder, completeAllOrderSteps, managerConfirmedLastPayment,
  managerConfirmedNotPayedOrder,
  managerConfirmedPayedOrder, managerFinishedProject, notAcceptPartnerWork,
  cancelOrder,
  orderStatus, receiveDocuments,
  requestDocuments, sendPayedProjectDocuments, orderPayedWithCashAfterManagerConfirmed,
} from '@/constants/orderStatus';
import ordersApi from '@/api/orders/ordersApi';
import loader from '@/components/loader';
import chat from '@/components/chat/chat';
import UBContentDescription from '@/components/UBTable/UBContent/UBContentDescription';
import staffDescriptionApi from '@/api/staff-description/staffDescriptionApi';
import ClickOutside from 'vue-click-outside';
import { UB_CLIENT, UB_CONTACT } from '@/constants/listStaffDepartments';
import {
  CEO, OWNER,
} from '@/constants/roleType';

export default {
  name: 'UBContent',
  components: {
    ElementDefault,
    loader,
    OrderInfoProgressBar,
    chat,
    UBContentDescription,
  },
  directives: {
    ClickOutside,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isVisibleChangeOrderModal: -1,
      activeItem: -1,
      currentOrderDef: {},
      openChat: false,
      showInfoText: false,
      orderInfoDef: {},
      loader: false,
      chatId: '',
      toggleIconAction: false,
      isVisibleDescription: false,
      activeDescription: -1,
      currentDescription: {},
      editStatus: false,
      areYouShureWindow: -1,
      descriptionArray: [],
      paymentsData: [],
    };
  },
  computed: {
    ubContact() {
      return this.descriptionArray.filter((item) => item.user.department === 'ubContact');
    },
    ubOutDepartment() {
      return this.descriptionArray.filter((item) => item.user.role === OWNER || item.user.role === CEO);
    },
    ubClient() {
      return this.descriptionArray.filter((item) => item.user.department === 'ubClient');
    },
    checkProjectManager() {
      const { projectManager: { _id: projectManagerId } } = this.currentOrder;
      const { _id: userId } = this.userInfo;
      return userId === projectManagerId;
    },
    isVisibleChangeDescription() {
      return this.userDepartment === UB_CONTACT || this.userRole === OWNER || this.userRole === CEO;
    },
    isVisibleUbClient() {
      return this.userDepartment === UB_CLIENT || this.userRole === OWNER || this.userRole === CEO;
    },
    isClientDepartment() {
      return this.userDepartment === UB_CLIENT;
    },
    userDepartment() {
      const { department = '' } = this.userInfo;
      return department;
    },
    currentOrder: {
      get() {
        return this.currentOrderDef;
      },
      set(data) {
        this.currentOrderDef = data;
      },
    },
    progressBarDays() {
      const arr = this.stepList.filter((item) => item.isActive && item.status !== 'CM')
        .map((el) => ({
          timing: el.timing,
          status: el.status,
        }));
      return arr.reduce((acc, obj) => acc + +obj.timing, 0);
    },
    showChat() {
      const { _id } = this.currentOrder;
      return !!_id;
    },
    completedSteps() {
      return this.stepList.filter((item) => item.isActive);
    },
    orderStatus() {
      const { status = {} } = this.currentOrder;
      return status;
    },
    orderInfo: {
      get() {
        return this.orderInfoDef;
      },
      set(data) {
        this.orderInfoDef = data;
      },
    },
    orderId() {
      return this.orderInfo.orderId;
    },
    procentPayment() {
      return get(this.currentOrder, 'prePayment', null);
    },
    userId() {
      return get(this.currentOrder, '_id', '');
    },
    progressBarStatus() {
      const arr = this.stepList.filter((item) => item.isActive && item.status === 'CM')
        .map((el) => ({
          isActive: el.isActive,
          status: el.status,
        }));
      const fullProcent = this.completedSteps.length;
      if (arr.length) {
        const result = (arr.length * 100) / fullProcent;
        if (result > 100) {
          return 100;
        }
        return result;
      }
      return 0;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userRole() {
      return this.userInfo.role;
    },
    allDays() {
      const arr = this.stepList.filter((item) => item.isActive)
        .map((el) => (el.timing));
      return arr.reduce((acc, val) => acc + val, 0);
    },
    fullPrice() {
      const arr = this.stepList.filter((item) => item.isActive)
        .map((el) => (el.cost));
      return arr.reduce((acc, val) => acc + val, 0);
    },
    calcNum() {
      const texts = [this.$t('global.dayOne'), this.$t('global.dayAlternative'), this.$t('global.days')];
      const number = this.allDays;
      const cases = [2, 0, 1, 1, 1, 2];
      return texts[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
    stepList() {
      return get(this.currentOrder, `steps.${this.language}`, []);
    },
    language() {
      return this.$i18n.locale;
    },
    currency() {
      return get(this.currentOrder, 'currency', '');
    },
    showBtnDontPay() {
      return !!this.paymentsData.length;
    },
  },
  watch: {
    row(newVal) {
      if (newVal) {
        this.currentOrder = newVal;
      }
    },
  },
  beforeMount() {
    const { _id: id } = this.row;
    this.loader = true;
    ordersApi.getOrderById(id)
      .then((resp) => {
        this.loader = false;
        const {
          partner = {}, projectManager = {}, status: { code: statusCode }, partnerAssignmentConfirmation, payments,
        } = resp.data;

        this.paymentsData = payments;

        const { _id: partnerId = '' } = partner;
        const { _id: projectManagerId = '' } = projectManager;
        const partnerAvatar = partnerId
        // eslint-disable-next-line global-require
          ? partner.userAvatarUrl || require('@/assets/images/placeholder/avatar.svg')
          : undefined;
        const projectManagerAvatar = projectManagerId
        // eslint-disable-next-line global-require
          ? projectManager.userAvatarUrl || require('@/assets/images/placeholder/avatar.svg')
          : undefined;
        const partnerFullName = partnerId ? `${partner.partnerCompanyName || ''}` : '';
        const projectManagerFullName = projectManagerId ? `${projectManager.firstName || ''}
             ${projectManager.lastName || ''}` : '';
        const isRejected = partnerAssignmentConfirmation === 'rejected';
        this.currentOrder = {
          ...resp.data,
          partner: !isRejected && partnerId ? {
            ...partner,
            fullName: partnerFullName,
            userAvatarUrl: partnerAvatar,
          } : undefined,
          projectManager: projectManager ? {
            ...projectManager,
            fullName: projectManagerFullName,
            userAvatarUrl: projectManagerAvatar,
          } : undefined,
        };
        if (statusCode === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
          this.currentOrder.status.client[this.language] = this.$t('statuses.assignPerformer');
        }
        if (statusCode === orderStatus.ORDER_CONFIRMED_AND_PAYED && Object.keys(partner).length
            && Object.keys(projectManager).length) {
          this.currentOrder.status.client[this.language] = this.$t('statuses.partnerAppointed');
        }
        this.$emit('updateCurrentRow', this.currentOrder);
        const { country: { code, name }, managementForm, orderId } = resp.data;
        const { staffDescription } = resp.data;
        this.orderInfo = {
          code,
          name,
          managementForm,
          image: `country/${code}.svg`,
          orderId,
        };
        if (staffDescription) {
          this.descriptionArray = staffDescription;
        }
      })
      .catch(() => {
        this.loader = false;
        this.$router.push({
          name: 'page404',
          query: {},
        });
      });
  },
  methods: {
    changeStatusOrder(id) {
      const { status: { code } } = this.currentOrder;
      if (code === orderStatus.DOCUMENTS_RECEIVED || code === orderStatus.ALL_STEPS_COMPLETED) {
        this.isVisibleChangeOrderModal = id;
      }
    },
    changeStatus(item, statusCondition) {
      const { steps: stepsInOrder, _id: id, status: { code } } = this.currentOrder;
      const steps = {
        ...stepsInOrder,
      };
      Object.keys(steps).forEach((key) => {
        const stepsList = steps[key];
        const currentStep = stepsList.find((el) => el.stepId === item.stepId);
        if (currentStep) {
          currentStep.status = statusCondition;
        }
      });
      let transition = '';
      const { projectExecutor, isFullPaymentFulfilled } = this.currentOrder;
      if (projectExecutor === 'partner') {
        transition = completeAllOrderSteps;
      } else if (projectExecutor === 'projectManager'
          && this.currentOrder.isFullPaymentFulfilled) {
        transition = acceptWorkWithFullyPayedOrder;
      } else if (projectExecutor === 'projectManager'
          && !isFullPaymentFulfilled) {
        transition = acceptedProjectRequireLastPay;
      }
      if (projectExecutor === 'projectManager') {
        if (code === orderStatus.DOCUMENTS_RECEIVED
            && isFullPaymentFulfilled) {
          transition = acceptWorkWithFullyPayedOrder;
        } else {
          transition = acceptedProjectRequireLastPay;
        }
      }
      if (this.progressBarStatus !== 100 && code === orderStatus.ALL_STEPS_COMPLETED) {
        transition = notAcceptPartnerWork;
      }
      const order = {
        steps,
        transition: this.progressBarStatus === 100 ? transition : undefined,
      };
      this.isVisibleChangeOrderModal = -1;
      this.loader = true;
      this.updateRequest(id, order);
    },
    closeWindow() {
      this.isVisibleChangeOrderModal = -1;
    },
    closeModal() {
      this.areYouShureWindow = -1;
      this.activeDescription = -1;
    },
    deleteDescription(id) {
      const index = this.descriptionArray.findIndex((el) => el._id === id);
      if (index !== -1) {
        this.descriptionArray.splice(index, 1);
        staffDescriptionApi.deleteStaffDescription(id)
          .then((resp) => {
            this.loader = false;
            this.$emit('update:descriptionArray', resp.data.staffDescription);
            this.areYouShureWindow = -1;
            this.activeDescription = -1;
          })
          .catch((err) => {
            this.loader = false;
            console.error(err);
          });
      }
    },
    changeDescription(item) {
      this.currentDescription = item;
      this.editStatus = true;
      this.isVisibleDescription = true;
      this.activeDescription = -1;
    },
    closeDescription() {
      this.editStatus = false;
      this.currentDescription = {};
      this.activeDescription = -1;
      this.isVisibleDescription = false;
    },
    addNewDescription() {
      this.activeDescription = -1;
      this.isVisibleDescription = true;
      this.editStatus = false;
    },
    closeChooseWindow() {
      this.activeDescription = -1;
    },
    openWindowSettings(id) {
      this.activeDescription = id;
      this.areYouShureWindow = -1;
    },
    toggleShowContent() {
      this.showInfoText = !this.showInfoText;
    },
    updateRequest(id, order) {
      this.loader = true;
      ordersApi.updateOrderById({ id, order })
        .then((resp) => {
          this.loader = false;
          this.currentOrder = resp.data;
          this.$emit('updateCurrentRow', resp.data);
        })
        .catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    updateRequestPaymentStatus(id, operation) {
      this.loader = true;
      ordersApi.updatePaymentStatus({ id, operation })
        .then((resp) => {
          this.loader = false;
          this.currentOrder = resp.data;
          this.$emit('updateCurrentRow', resp.data);
        })
        .catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    changeAcceptOrder() {
      const order = {};
      const isOrderNotPayed = !this.currentOrder.isPrepaymentFulfilled && this.currentOrder.prePayment !== 0;
      order.transition = isOrderNotPayed ? managerConfirmedNotPayedOrder : managerConfirmedPayedOrder;
      const { status: { code }, _id, isFullPaymentFulfilled } = this.currentOrder;
      if (code === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
        order.transition = requestDocuments;
      } else if (code === orderStatus.DOCUMENTS_REQUESTED) {
        order.transition = receiveDocuments;
      } else if (code === orderStatus.ALL_STEPS_COMPLETED) {
        order.transition = isFullPaymentFulfilled
          ? acceptWorkWithFullyPayedOrder
          : acceptedProjectRequireLastPay;
      } else if (code === orderStatus.LAST_PAYMENT_PAYED) {
        order.transition = managerConfirmedLastPayment;
      } else if (code === orderStatus.LAST_PAYMENT_CONFIRMED) {
        order.transition = sendPayedProjectDocuments;
      }
      this.loader = true;
      this.updateRequest(_id, order);
    },
    addProjectManager() {
      const { _id } = this.currentOrder;
      const order = {};
      order.projectExecutor = 'projectManager';
      this.loader = true;
      this.updateRequest(_id, order);
    },
    finishOrder() {
      const { _id } = this.currentOrder;
      const order = {};
      order.transition = managerFinishedProject;
      this.loader = true;
      this.updateRequest(_id, order);
    },
    cancelOrder() {
      const { _id } = this.currentOrder;
      const order = {};
      order.transition = cancelOrder;
      this.loader = true;
      this.updateRequest(_id, order);
    },
    toggleStatusToPaid() {
      const { status: { code }, _id } = this.currentOrder;
      const operation = {};

      switch (code) {
      case orderStatus.LAST_PAYMENT_REQUIRED: {
        operation.status = orderStatus.LAST_PAYMENT_PAYED;
        break;
      }
      case orderStatus.ORDER_CONFIRMED_NOT_PAYED: {
        operation.transition = orderPayedWithCashAfterManagerConfirmed;
        break;
      }
      case orderStatus.LAST_PAYMENT_PAYED: {
        operation.status = orderStatus.LAST_PAYMENT_REQUIRED;
        break;
      }
      default: operation.status = orderStatus.ORDER_CONFIRMED_NOT_PAYED;
      }

      this.loader = true;
      this.updateRequestPaymentStatus(_id, operation);
    },
    calcDay(number, time) {
      const cases = [2, 0, 1, 1, 1, 2];
      return time[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
    setActiveItem(id) {
      const result = this.activeItem === id ? this.activeItem = -1 : this.activeItem = id;
      return result;
    },
    showElement(el) {
      if (!el.parentId.length) {
        return true;
      }
      this.stepList.forEach((step) => {
        this.$set(step, 'isVisible', true);
      });
      const stepListFields = this.stepList.reduce((acc, step) => {
        step.fields.forEach((field) => {
          field.stepId = step.stepId;
        });
        return [...acc, ...step.fields];
      }, []);
      const parentElements = filter(stepListFields, (field) => el.parentId.includes(field.fieldId));
      if (!find(parentElements, (element) => element.checked)) {
        if (!el.type) {
          this.setIsVisibleStep(el, false);
        }
        return false;
      }
      const showStatus = parentElements.some((parentElement) => {
        if (parentElement.parentId.length) {
          return this.showChildElement(parentElement);
        }
        const { stepId } = parentElement;
        const parensStep = this.stepList.find((step) => step.stepId === stepId);
        return parensStep.isVisible ? parentElement.checked : false;
      });
      if (!el.type) {
        this.setIsVisibleStep(el, showStatus);
      }
      return showStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/variables";

.order {
  &-modal-status {
    position: absolute;
    top: 34px;
    min-width: 120px;
    min-height: 78px;
    box-shadow: 0 0 10px rgba($color-black, .1);
    border-radius: $borderRadius;
    background: $color-white;
    z-index: 1;
    padding: 3px 0;

    &__item {
      padding: 12px;
      cursor: pointer;
      transition: background-color .15s ease-in;
      text-align: left;

      &_done {
        color: $color-green;
      }

      &_process {
        color: $color-dodger-blue;
      }

      &:hover {
        background: $color-alabaster;
      }
    }
  }
}

.ub-content {
  user-select: none;
  width: 100%;
  background: $color-white;
  box-shadow: 0 0 8px rgba($color-black, .04);
  border-radius: 0 0 $borderRadius;
  padding: 16px;
  margin-bottom: 8px;

  .order-panel {
    margin-bottom: 12px;
  }

  &-description {
    display: flex;
    justify-content: space-between;
    padding: 13px 20px;
    background: $color-white;
    cursor: pointer;
    box-shadow: 0 0 8px rgba($color-black, .08);
    border-radius: $borderRadius;
    margin-bottom: 12px;

    &_margin {
      margin-bottom: 0;
    }

    &__add {
      background: rgba($color-dodger-blue, .08);
      border-radius: $borderRadius;
      padding: 20px 20px 16px 20px;
    }

    &__text {
      font-family: $font-global-medium;
    }

    &__icon {
      color: $color-silver-chalice;

      &_up {
        transform: rotate(180deg);
      }
    }

    &-info {
      background: $color-white;
      box-shadow: 0 0 8px rgba($color-black, .08);
      padding: 20px 24px;
      border-bottom-left-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;

      .button-action_offset {
        margin-bottom: 0;
        margin-top: 0;

        i {
          font-size: $font-size-dd;
        }

        &_margin {
          margin-top: 0;
        }
      }

      &_margin {
        margin-bottom: 12px;
      }

      &__title {
        font-family: $font-global-medium;
        margin-bottom: 16px;
      }

      &__files {
        margin-top: 5px;
        align-items: center;
        display: flex;

        &:first-child {
          margin-top: 0;
        }

        &-link {
          font-size: $font-size-landing-small;
          text-decoration: none;
          color: $color-black;
        }

        &-icon {
          color: $color-dodger-blue;
          font-size: $font-size-icon-xd;
          position: relative;
          left: -5px;

          &_close {
            color: $color-silver-chalice;
            font-size: $font-size-dd;
            cursor: pointer;
            padding-top: 2px;
          }
        }
      }

      &__text {
        font-family: $font-global;
        margin-bottom: 15px;
        position: relative;
        padding-right: 40px;

        &-content {
          margin-bottom: 14px;
        }
      }
    }
  }

  &-header {
    width: 100%;
    background: $color-white;
    box-shadow: 0 0 8px rgba($color-black, .04);
    border-radius: $borderRadius;
    color: $color-dodger-blue;
    font: $font-size-base $font-global-medium;
    display: flex;
    justify-content: flex-start;
    padding: 14px 15px;

    &__order {
      width: 40%;
      flex-grow: 1;

      &-text {
        padding-left: 29px;
      }
    }

    &__timing {
      width: 15%;
      text-align: center;
    }

    &__price {
      width: 15%;
      text-align: right;
    }

    &__status {
      width: 30%;
      padding-left: 11%;
    }
  }

  &-content {
    width: 100%;

    &-elem {
      width: 100%;
    }

    &-file {
      display: flex;
      align-items: center;
      margin-bottom: 19px;

      &:last-child {
        margin-bottom: 0;
      }

      &__link {
        text-decoration: none;
        color: $color-black;
      }

      &__remove {
        color: $color-dodger-blue;
        margin-left: 5px;
        transform: rotate(45deg);
        cursor: pointer;
      }

      .ub-icon-file-1 {
        color: $color-dodger-blue;
        font-size: $font-size-icon-xd;
      }
    }

    &-more {
      background: $color-alabaster;
      box-shadow: 0 0 8px rgba($color-black, .04);
      border-radius: 0 0 $borderRadius $borderRadius;

      &-section {
        padding: 20px 36px;
        border-bottom: 1px solid $color-gallery;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    &__status {
      display: flex;
      text-align: center;
      padding-left: 11%;
      position: relative;

      &-file {
        position: relative;
        font: $font-size-base $font-global-medium;
        color: $color-dodger-blue;
        height: 29px;
        display: flex;
        align-items: center;

        i {
          margin-right: 6px;
          font-size: $font-size-xlg;
        }
      }

      &-input {
        width: 100%;
        position: absolute;
        max-width: 140px;
        height: 20px;
        left: 0;
        top: 5px;
        cursor: pointer;
        opacity: 0;
      }

      &-reorder {
        font: $font-size-base $font-global-medium;
        color: $color-dodger-blue;
        height: 29px;
        display: flex;
        align-items: center;

        i {
          margin-right: 6px;
          font-size: $font-size-xlg;
        }
      }

      &-text {
        cursor: pointer;
        transition: color .15s ease-in;
        min-width: 160px;
        display: flex;
        align-items: center;

        &:hover {
          color: $color-denim;
        }
      }

      &-info {
        border-radius: $borderRadius;
        padding: 4px 8px;
        color: $color-silver-chalice;
        display: flex;
        align-items: center;
        margin-right: 32px;
        font: $font-size-base $font-global;
        height: 28px;
        cursor: default;

        &_individually {
          background: $color-alabaster;
          transition: background-color .15s ease-in;
        }

        &_process {
          background: rgba($color-dodger-blue, .08);
          color: $color-dodger-blue;
          transition: background-color .15s ease-in;
        }

        &_done {
          background: rgba($color-green, .08);
          color: $color-green;
          transition: background-color .15s ease-in;
        }
      }
    }

    &-row {
      display: flex;
      padding: 18px 15px;
      background: $color-white;
      box-shadow: 0 0 8px rgba($color-black, .04);
      border-radius: $borderRadius;
      margin-top: 8px;
      min-height: 73px;
    }

    &__order {
      width: 40%;
      flex-grow: 1;

      &-wrap {
        display: flex;
      }

      &-index {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: rgba($color-dodger-blue, .1);
        display: flex;
        justify-content: center;
        align-items: center;
        font: $font-size-sm $font-global-medium;
        color: $color-dodger-blue;
        margin-right: 10px;
      }

      &_more {
        font: $font-size-md $font-global-medium;
        color: $color-dodger-blue;
        cursor: pointer;
        margin-top: 6px;

        &_disable {
          pointer-events: none;
          color: $color-gallery;
        }
      }
    }

    &__timing {
      width: 15%;
      text-align: center;
    }

    &__price {
      width: 15%;
      text-align: right;
    }

    &__status {
      width: 30%;
    }
  }

  &-amount {
    margin-top: 8px;
    width: 100%;
    background: $color-white;
    box-shadow: 0 0 8px rgba($color-black, .04);
    border-radius: $borderRadius;
    height: 48px;
    color: $color-dodger-blue;
    font: $font-size-base $font-global-bold;
    padding: 14px 15px;
    display: flex;
    align-items: center;

    &__order {
      width: 40%;
      flex-grow: 1;

      &-text {
        padding-left: 29px;
      }
    }

    &__timing {
      width: 15%;
      text-align: center;
    }

    &__price {
      width: 15%;
      text-align: right;
    }

    &__status {
      width: 30%;
    }
  }
}

.action-elements {
  position: absolute;
  right: 15px;
  top: -5px;
  font-size: 12px;
  width: 24px;
  height: 24px;
  border: 1px solid rgba($color-dodger-blue, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: $color-dodger-blue;
  cursor: pointer;
  transition: all .15s ease-in;
  z-index: auto;

  &-choose {
    user-select: none;
    position: absolute;
    top: 12px;
    right: 10px;
    left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba($color-black, .1);
    border-radius: $borderRadius;
    background-color: $color-white;
    color: $color-black;
    cursor: default;
    min-width: 143px;
    max-width: 143px;
    padding: 3px 0;
    z-index: 1;

    &-controls {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__button {
        line-height: 1.5;
        min-height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color .15s ease-in;
        padding: 0 12px;
        font: $font-size-md $font-global;

        &:hover {
          background: $color-alabaster;
        }
      }
    }
  }

  &:before {
    position: relative;
    border-color: transparent;
  }

  &:hover {
    background: $color-dodger-blue;
    color: $color-white;
    border-color: transparent;
  }
}

.ub-icon-cross-x {
  background: $color-dodger-blue;
  color: $color-white;

  &:before {
    font-size: $font-size-h6;
    left: -.5px;
  }
}

.ub-new-message-body__message {
  width: 100%;
  height: 50px;
  background: darkseagreen;
}

.ub-toolbar-confirm {
  min-width: 232px;
  max-width: 232px;
  padding: 9px 23px 11px 23px;
  color: $color-black;
  background: $color-white;
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 10px;

  &__text {
    font-size: $font-size-md;
    font-style: normal;
    text-align: center;
    margin-bottom: 12px;

    span {
      display: block;
      margin-top: 4px;
    }
  }

  &-controls {
    justify-content: flex-end;
    display: flex;

    &__button {
      font-size: $font-size-h3;
      color: $color-dodger-blue;
      padding: 0 5px;
      cursor: pointer;
      font-family: $font-global-medium;

      & + .ub-toolbar-confirm-controls__button {
        margin-left: 18px;
      }
    }
  }
}

</style>
