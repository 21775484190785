<template>
  <div class="order-panel">
    <div class="order-panel-payment">
      <div class="order-panel__info">
        <div class="order-panel__info-title">
          {{ $t('orderPage.order') }}  № {{ orderId }}
        </div>
        <div
          class="order__text order-panel__text"
        >
          <span
            class="order-panel__circle"
            :class="{
              'order-panel__circle_feedback': checkFeedBack
            }"
            :style="{background: statusBg}"
          >
          </span>
          <span>
            {{ orderStatusText }}
          </span>
          <UBReviews
            v-if="checkFeedBack"
            :review="feedback"
          />
        </div>
      </div>
      <div class="order-panel__wrapper">
        <button
          v-if="showBtnPayForClientManualFrom"
          class="ub-btn ub-btn_primary"
          @click="toggleShowWindowWithPaypal"
        >
          {{ $t('global.pay') }}
        </button>
        <template v-if="partnerAppointed">
          <button
            v-if="showPutIntoOperation"
            class="ub-btn ub-btn_secondary order-panel__wrapper-btn"
            @click="addProjectManager"
          >
            {{ $t('global.launchUB') }}
          </button>
        </template>
        <template v-if="!isPartner">
          <template
            v-if="showTemplateOfButtons"
          >
            <div
              v-if="(showButton && !showPutIntoOperation)"
              class="order-panel__btn"
            >
              <button
                class="ub-btn ub-btn_primary"
                @click.stop="handleEvent()"
              >
                <template v-if="currentOrderStatus === orderStatus.NEW_ORDER">
                  {{ $t('statuses.takeOrder') }}
                </template>
                <template v-else-if="currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED">
                  {{ $t('statuses.requestDocs') }}
                </template>
                <template v-else-if="showBtnPayForm">
                  {{ $t('global.pay') }}
                </template>
                <template v-else-if="currentOrderStatus === orderStatus.ALL_STEPS_COMPLETED && !isClient">
                  {{ $t('statuses.takeWork') }}
                </template>
                <template v-else-if="currentOrderStatus === orderStatus.DOCUMENTS_REQUESTED && !isClient">
                  {{ $t('statuses.acceptDocs') }}
                </template>
                <template v-else-if="currentOrderStatus === orderStatus.LAST_PAYMENT_PAYED && !isClient">
                  {{ $t('statuses.paymentReceived') }}
                </template>
                <template v-else-if="showBtnDocumentsWasSend">
                  {{ $t('statuses.sendDocuments') }}
                </template>
              </button>
            </div>
          </template>
        </template>
        <template v-else>
          <div
            v-if="currentOrder.partnerAssignmentConfirmation !== 'rejected'
              && currentOrder.partnerAssignmentConfirmation !== 'accepted'"
            class="order-panel__btn"
          >
            <button
              class="ub-btn ub-btn_primary"
              @click="acceptOrderPartner"
            >
              <template
                v-if="currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED"
              >
                {{ $t('statuses.takeOrder') }}
              </template>
            </button>
          </div>
          <div
            v-if="currentOrder.partnerAssignmentConfirmation !== 'rejected' && showPartnerSecond"
            class="order-panel__btn"
          >
            <button
              class="ub-btn ub-btn_primary"
              @click="cancelOrderPartner"
            >
              <template>
                {{ $t('statuses.cancelOrder') }}
              </template>
            </button>
          </div>
        </template>
        <transition
          v-if="showFinishStatus"
          name="fade-el"
          mode="out-in"
          appear
        >
          <button
            class="ub-btn ub-btn_primary ub-btn_offset"
            @click.stop="finishOrder()"
          >
            {{ $t('statuses.completeProject') }}
          </button>
        </transition>
      </div>
      <div
        v-if="showDropdownWithOptions"
        class="action-elements"
        :class="toggleIconAction ? 'ub-icon-cross-x' : 'ub-icon-more-vertical'"
        @click.stop="openWindowSettings()"
      >
        <transition
          name="fade-el"
          mode="out-in"
        >
          <div
            v-if="toggleIconAction"
            v-click-outside="closeChooseWindow"
            class="action-elements-choose"
            @click.stop
          >
            <div class="action-elements-choose-controls">
              <button
                v-if="checkRefundOperation"
                class="ub-button action-elements-choose-controls__button"
                @click.stop="showRefundModal()"
              >
                {{ $t('global.refundToClient') }}
              </button>
              <button
                v-if="!isClient && checkNewPaymentModal && !isProjectExecutor && !isSalesDepartment"
                class="ub-button action-elements-choose-controls__button"
                :class="{'action-elements-choose-controls__button_disable': !checkNewPayment || !orderPayment.sum}"
                @click="showNewPaymentModal"
              >
                {{ $t('global.refund') }}
              </button>
              <button
                v-if="showCancelOrder && currentOrderStatus !== orderStatus.ORDER_CANCELLED"
                class="ub-button action-elements-choose-controls__button"
                @click="cancelOrder"
              >
                {{ $t('statuses.cancelOrder') }}
              </button>
              <button
                v-if="showBtnChangeStatusToPaid"
                class="ub-button action-elements-choose-controls__button"
                @click="changeStatusToPaid"
              >
                {{ $t('statuses.changeStatusToPaid') }}
              </button>
              <button
                v-if="showBtnChangeStatusNotToPaid"
                class="ub-button action-elements-choose-controls__button"
                @click="changeStatusToPaid"
              >
                {{ $t('statuses.returnStatusNotToPaid') }}
              </button>
            </div>
          </div>
        </transition>
      </div>
      <transition
        name="fade-el"
        mode="out-in"
        appear
      >
        <paypal
          v-if="currentOrder && isClient"
          v-show="openPayPal"
          class="paypal_full"
          :currentOrder.sync="currentOrder"
          :loader.sync="loaderDef"
          :showChangeStatusPayment="false"
          @closePayPal="closePayPal"
          @payFull="payFull"
          @toggleShowWindowWithPaypal="toggleShowWindowWithPaypal"
        />
      </transition>
    </div>
    <div class="progress">
      <div class="progress-row">
        <div class="progress-row__status">
          <div
            class="progress-bar"
          >
            <div
              :class="barClass"
              :style="barStyle"
              class="progress-row__status-bar"
            >
            </div>
          </div>
          <div class="progress-row__status-procent">
            {{ Math.floor(value) }}%
          </div>
          <div class="progress-row__status-days">
            <span class="progress-row__status-circle"></span>
            {{ days }}
            {{ calcNum }}
          </div>
        </div>
        <div
          v-if="(isClient || isAdmin || isManager) && payments.length"
          class="progress-row__dialog progress-row__dialog_check"
          @click="openCheckModal"
        >
          <i class="ub-icon-сheck-bank"></i>
          <span class="progress-row__checks">
            {{ $t('clientOrder.checks') }}
          </span>
          <transition name="fade-el">
            <div
              v-if="isVisibleCheckModal"
              class="progress-row-modal"
            >
              <div
                v-for="cheque in payments"
                :key="cheque._id"
                class="progress-row-modal__item"
                @click="showChequeModal(cheque)"
              >
                <i class="ub-icon-file-1"></i>
                <span>
                  <template v-if="cheque.isPrepayment">
                    {{ $t('clientOrder.chequePrepayment') }}
                  </template>
                  <template v-else>
                    {{ $t('clientOrder.chequeAdditionalPayment') }}
                  </template>
                </span>
              </div>
            </div>
          </transition>
        </div>
        <div
          v-if="false"
          class="progress-row__dialog"
          :class="{'progress-row__dialog_active': !isNewOrder}"
          @click="setOpenChat"
        >
          <i class="ub-icon-chat_bubble"></i>
          <template v-if="isClient">
            {{ $t('orderPage.sendMessageClient') }}
          </template>
          <template v-else-if="isPartner">
            {{ $t('orderPage.sendMessagePartner') }}
          </template>
          <template v-else-if="isAdmin || isManager">
            {{ $t('orderPage.sendMessageAdmin') }}
          </template>
        </div>
      </div>
    </div>
    <transition name="fade-el">
      <chequeModal
        v-if="isVisibleChequeModal"
        :paymentInfo="paymentInfo"
        :fullName="fullName"
        :procentPayment="procentPayment"
        @closeModal="closeModal"
      />
    </transition>
    <portal to="modalRefund">
      <transition name="fade-window">
        <refundModal
          v-if="isVisibleRefundModal"
          :sum="fullPrice"
          :value="currency"
          :userId="userId"
          :select="true"
          :paymentsList="paymentsList"
          @closeModal="closeModal"
        />
      </transition>
    </portal>
    <transition name="fade-window">
      <newPaymentsModal
        v-if="isVisibleNewPaymentModal"
        :orderId="orderId"
        :orderPayment="orderPayment"
        :isVisiblePayoffButton.sync="isVisiblePayoffButton"
        :payoff="true"
        :orderModal="true"
        :orderRefund="true"
        @closeModal="closeModal"
      />
    </transition>
  </div>
</template>

<script>
import {
  get,
} from 'lodash';
import {
  orderStatus,
  ADMIN_BUTTON_COLOR,
  CLIENT_BUTTON_COLOR,
  PARTNER_BUTTON_COLOR,
} from '@/constants/orderStatus';
import {
  UB_CLIENT,
  UB_CONTACT,
} from '@/constants/listStaffDepartments';
import STATUS_COLORS from '@/constants/statusColors';
import paypal from '@/components/payment/paypal';
import ClickOutside from 'vue-click-outside';
import {
  MANAGER, PARTNER, CLIENT, OWNER, CEO, DEPARTMENT_HEAD,
} from '@/constants/roleType';
import chequeModal from '@/components/modals/chequeModal';
import refundModal from '@/components/modals/refundModal';
import UBReviews from '@/components/UBTable/UBReviews/UBReviews';
import newPaymentsModal from '@/components/modals/newPaymentsModal';
import ordersApi from '@/api/orders/ordersApi';

export default {
  name: 'OrderInfoProgressBar',
  components: {
    paypal,
    refundModal,
    chequeModal,
    UBReviews,
    newPaymentsModal,
  },
  directives: {
    ClickOutside,
  },
  props: {
    order: {
      type: Object,
    },
    procentPayment: {
      type: Number,
    },
    fullPrice: {
      type: Number,
    },
    currency: {
      type: String,
    },
    language: {
      type: String,
      default: '',
    },
    loader: {
      type: Boolean,
    },
    status: {
      type: Object,
      default: () => {},
    },
    orderId: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
    days: {
      type: Number,
      default: 0,
    },
    barClass: {
      type: String,
      default: '',
    },
    userRole: {
      type: String,
      default: '',
    },
    openChat: {
      type: Boolean,
    },
    userId: {
      type: String,
    },
    currentOrder: {
      type: Object,
      default: () => {},
    },
    paymentsData: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      conditionShowProgress: [
        orderStatus.NEW_ORDER,
        orderStatus.ORDER_CONFIRMED_NOT_PAYED,
      ],
      conditionShowText: [
        orderStatus.LAST_PAYMENT_REQUIRED,
        orderStatus.LAST_PAYMENT_PAYED,
        orderStatus.LAST_PAYMENT_CONFIRMED,
        orderStatus.DOCUMENTS_SENT_AFTER_LAST_PAYMENT,
      ],
      conditionShowCancel: [
        orderStatus.PROJECT_WITH_FULLY_PAYED_ORDER_ACCEPTED,
        orderStatus.LAST_PAYMENT_PAYED,
        orderStatus.LAST_PAYMENT_CONFIRMED,
        orderStatus.PROJECT_FINISHED,
        orderStatus.DOCUMENTS_SENT_AFTER_LAST_PAYMENT,
        orderStatus.PARTNER_REWARD_PERCENT_CHANGED,
      ],
      conditionShowTransitions: [
        orderStatus.DOCUMENTS_SENT_AFTER_LAST_PAYMENT,
        orderStatus.NEW_ORDER,
        orderStatus.ORDER_CONFIRMED_AND_PAYED,
        orderStatus.ORDER_CONFIRMED_NOT_PAYED,
        orderStatus.DOCUMENTS_REQUESTED,
        orderStatus.DOCUMENTS_RECEIVED,
        orderStatus.ALL_STEPS_COMPLETED,
        orderStatus.LAST_PAYMENT_PAYED,
        orderStatus.LAST_PAYMENT_CONFIRMED,
        orderStatus.PROJECT_FINISHED,
      ],
      conditionShowTransitionsSecond: [
        orderStatus.NEW_ORDER,
        orderStatus.ORDER_CONFIRMED_AND_PAYED,
        orderStatus.DOCUMENTS_REQUESTED,
        orderStatus.ALL_STEPS_COMPLETED,
        orderStatus.LAST_PAYMENT_PAYED,
        orderStatus.LAST_PAYMENT_CONFIRMED,
      ],
      conditionShowPartnerBtnSecond: [
        orderStatus.ORDER_CONFIRMED_AND_PAYED,
        orderStatus.DOCUMENTS_REQUESTED,
        orderStatus.DOCUMENTS_RECEIVED,
      ],
      showConditionFinishStatuses: [
        orderStatus.LAST_PAYMENT_CONFIRMED,
        orderStatus.DOCUMENTS_SENT_AFTER_LAST_PAYMENT,
      ],
      toggleIconAction: false,
      paymentInfoDef: {},
      payFullStatus: false,
      isVisibleRefundModal: false,
      isVisibleNewPaymentModal: false,
      openPayPal: false,
      ready: false,
      isVisibleCheckModal: false,
      isVisibleChequeModal: false,
      isVisiblePayoffButton: true,
      orderStatus,
      orderPayment: {},
    };
  },
  computed: {

    currentRouteName() {
      return this.$route.name;
    },

    isFullPrepayment() {
      return this.currentOrder.prePayment === 100;
    },

    getPayments() {
      return this.currentOrder.payments;
    },

    showTemplateOfButtons() {
      return this.currentOrderStatus !== orderStatus.ORDER_CANCELLED
      && this.currentRouteName !== 'order';
    },

    showBtnPayForClientManualFrom() {
      /* return this.isClient
          && !this.currentOrder.payments.length
          && this.currentOrderStatus === orderStatus.ORDER_CANCELLED; */

      if (this.isClient
        && this.currentRouteName === 'order'
       && this.currentOrderStatus === orderStatus.LAST_PAYMENT_REQUIRED
      ) return true;

      return this.isClient
          && this.currentRouteName === 'order'
          && !this.currentOrder.payments.length
          && (this.currentOrderStatus === orderStatus.NEW_ORDER
              || this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_NOT_PAYED
              || this.currentOrderStatus === orderStatus.LAST_PAYMENT_REQUIRED
          );
    },

    showBtnPayForm() {
      return this.isClient
          && this.currentOrderStatus === orderStatus.LAST_PAYMENT_REQUIRED;
    },

    showDropdownWithOptions() {
      if (!this.isClient
          && !this.isPartner
          && this.isFullPrepayment
          && this.getPayments.length
          && this.currentOrderStatus === orderStatus.PROJECT_WITH_FULLY_PAYED_ORDER_ACCEPTED
      ) return true; // полная предоплата картой

      if (!this.isClient
          && !this.isPartner
          && !this.getPayments.length
          && (this.currentOrderStatus === orderStatus.PROJECT_WITH_FULLY_PAYED_ORDER_ACCEPTED
          || this.currentOrderStatus === orderStatus.PROJECT_FINISHED)
      ) return true; // полная предоплата наликом

      return !this.isClient
      && !this.isPartner && this.checkOpenWindowSettings && !this.checkProjectManager
      && this.showHiddenSettings && this.currentOrderStatus !== orderStatus.PROJECT_WITH_FULLY_PAYED_ORDER_ACCEPTED;
    },

    showBtnChangeStatusToPaid() {
      if (this.currentOrderStatus === orderStatus.LAST_PAYMENT_REQUIRED) return true;

      return this.showCancelOrder
          && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_NOT_PAYED;
    },

    showBtnChangeStatusNotToPaid() {
      if (this.currentOrder.projectExecutor === 'projectManager'
          && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED) return false;

      if (this.currentOrder.projectExecutor === 'projectManager'
          && this.currentOrderStatus === orderStatus.LAST_PAYMENT_PAYED
          && !this.currentOrder.payments.length) return true;

      if (this.currentOrderStatus === orderStatus.LAST_PAYMENT_REQUIRED) return false;

      return this.showCancelOrder
          && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED
          && !this.paymentsData.length;
    },

    checkRefundOperation() {
      const {
        salesManager: { _id: salesManagerId } = {},
        projectManager: { _id: projectManagerId } = {},
      } = this.currentOrder;
      const { _id: userId } = this.userInfo;

      if (this.isAdmin || (userId === projectManagerId || userId === salesManagerId)) {
        const { payments = [] } = this.currentOrder;
        const isRefundedArr = payments.filter((payment) => !payment.isRefunded);
        const result = !!isRefundedArr.length;
        return result;
      }
      return false;
    },
    showButton() {
      if (!this.isClient
          && !this.isPartner
          && this.isFullPrepayment
          && this.getPayments.length
          && (this.currentOrderStatus === orderStatus.DOCUMENTS_RECEIVED
              || this.currentOrderStatus === orderStatus.PROJECT_FINISHED
          )
      ) return false; // оплата полная при первом платеже

      if (!this.isClient
          && !this.isPartner
          && this.isFullPrepayment
          && !this.getPayments.length
          && (this.currentOrderStatus === orderStatus.PROJECT_FINISHED
              || this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_NOT_PAYED
              || this.currentOrderStatus === orderStatus.DOCUMENTS_RECEIVED
          )
      ) return false; // оплата полная наликом при первом платеже

      if (!this.isClient && this.toAppoint) {
        if (this.isFullPrepayment) return true;

        if (this.conditionShowTransitionsSecond.includes(this.currentOrderStatus)) {
          return true;
        }
      } else if (this.currentOrderStatus === orderStatus.LAST_PAYMENT_REQUIRED
          || !this.conditionShowTransitions.includes(this.currentOrderStatus)) {
        return true;
      }
      return false;
    },

    showFinishStatus() {
      if ((!this.isClient && !this.isPartner && this.showConditionFinishStatuses.includes(this.currentOrderStatus))
          || (!this.isClient && !this.isPartner
              && this.currentOrderStatus === orderStatus.PROJECT_WITH_FULLY_PAYED_ORDER_ACCEPTED
              && this.isFullPrepayment)) { // for full prepayment
        return true;
      }
      return false;
    },

    showBtnDocumentsWasSend() {
      if (this.currentOrderStatus === orderStatus.PROJECT_WITH_FULLY_PAYED_ORDER_ACCEPTED
          && this.isFullPrepayment) return true;

      return this.currentOrderStatus === orderStatus.LAST_PAYMENT_CONFIRMED && !this.isClient;
    },

    showHiddenSettings() {
      if ((this.currentOrderStatus === orderStatus.PROJECT_FINISHED && !this.checkRefundOperation)
          || this.currentOrderStatus === orderStatus.ORDER_CANCELLED) {
        return false;
      }
      return true;
    },
    toAppoint() {
      const { _id: userId } = this.userInfo;
      if (this.userRole === OWNER || this.userRole === CEO) return true;
      const {
        projectManager: { _id: projectManagerId = '' } = {},
        salesManager: { _id: salesManagerId = '' } = {},
      } = this.currentOrder;
      return userId === projectManagerId || userId === salesManagerId;
    },
    checkProjectManager() {
      return this.userRole === MANAGER && this.userDepartment === UB_CLIENT
          && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_NOT_PAYED;
    },
    checkOpenWindowSettings() {
      if (Object.keys(this.currentOrder).length) {
        const { status: { code } } = this.currentOrder;
        return orderStatus.NEW_ORDER !== code;
      }
      return false;
    },
    partnerAppointed() {
      if (Object.keys(this.currentOrder).length) {
        const {
          projectExecutor, partner = {}, partnerAssignmentConfirmation,
        } = this.currentOrder;
        const { _id } = partner;
        if ((projectExecutor === undefined && !_id)
            || (_id && partnerAssignmentConfirmation === 'rejected')) {
          return true;
        }
        return false;
      }
      return false;
    },
    showPartnerSecond() {
      if (this.conditionShowPartnerBtnSecond.includes(this.currentOrderStatus)) {
        return true;
      }
      return false;
    },
    userDepartment() {
      const { department = '' } = this.userInfo;
      return department;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    checkNewPaymentModal() {
      const { partner = {}, partnerAssignmentConfirmation = '' } = this.currentOrder;
      return ((this.isAdmin || (this.userDepartment === UB_CLIENT
          && (this.userRole === DEPARTMENT_HEAD || this.userRole === MANAGER) && Object.keys(partner).length
      )) && partnerAssignmentConfirmation === 'accepted');
    },
    statusBg() {
      if (this.isAdmin || this.isManager) {
        if (this.currentOrder.projectExecutor === 'projectManager'
            && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
          return STATUS_COLORS.processColor;
        }
        if (this.currentOrder.projectExecutor === 'partner'
            && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
          return STATUS_COLORS.processColor;
        }
        if (this.currentOrderStatus === orderStatus.ORDER_CANCELLED
            || this.currentOrder.partnerAssignmentConfirmation === 'rejected') {
          return STATUS_COLORS.errorColor;
        }
        return ADMIN_BUTTON_COLOR[this.currentOrderStatus];
      }
      if (this.isClient) {
        if (this.currentOrder.projectExecutor === 'projectManager'
            && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
          return STATUS_COLORS.processColor;
        } if (this.currentOrder.projectExecutor === 'partner'
            && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
          return STATUS_COLORS.processColor;
        }
        if (this.currentOrderStatus === orderStatus.ORDER_CANCELLED) {
          return STATUS_COLORS.errorColor;
        }
        return CLIENT_BUTTON_COLOR[this.currentOrderStatus];
      }
      if (this.isPartner) {
        if (this.currentOrder.projectExecutor === 'projectManager'
            && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
          return STATUS_COLORS.processColor;
        } if (this.currentOrder.projectExecutor === 'partner'
            && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
          return STATUS_COLORS.processColor;
        }
        if (this.currentOrder.partnerAssignmentConfirmation === 'rejected') {
          return STATUS_COLORS.errorColor;
        }
        return PARTNER_BUTTON_COLOR[this.currentOrderStatus];
      }
      return false;
    },
    showCancelOrder() {
      if (this.isAdmin || this.isManager) {
        const { isPrepaymentFulfilled, prePayment } = this.currentOrder;
        if (((this.currentOrderStatus === orderStatus.NEW_ORDER)
            && (isPrepaymentFulfilled || prePayment === 0))
            || !this.conditionShowCancel.includes(this.currentOrderStatus)
        ) {
          return true;
        }
      }
      return false;
    },
    showPutIntoOperation() {
      if ((this.userRole === OWNER || this.userRole === CEO
          || ((this.isManager || this.userRole === DEPARTMENT_HEAD) && this.userDepartment === UB_CLIENT)
          || ((this.isManager || this.userRole === DEPARTMENT_HEAD) && this.userDepartment === UB_CONTACT))
          && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED
          && this.currentOrder.projectExecutor === undefined
      ) {
        return true;
      }
      return false;
    },
    isProjectExecutor() {
      const { projectExecutor } = this.currentOrder;
      return projectExecutor === 'projectManager';
    },
    isSalesDepartment() {
      return this.userDepartment === UB_CONTACT;
    },
    checkNewPayment() {
      const { partner = {} } = this.currentOrder;
      return (this.isAdmin || this.isManager) && (this.payments.length || this.prePayment)
          && Object.keys(partner).length && this.isVisiblePayoffButton;
    },
    partnerId() {
      return get(this.currentOrder, 'partner._id', '');
    },
    fullName() {
      const firstName = get(this.currentOrder, 'client.firstName', '');
      const lastName = get(this.currentOrder, 'client.lastName', '');
      return `${firstName} ${lastName}`;
    },
    prePayment() {
      return get(this.currentOrder, 'prePayment', null);
    },
    feedback() {
      return get(this.currentOrder, 'feedback', {});
    },
    checkFeedBack() {
      return (Object.keys(this.feedback).length && this.currentOrderStatus === orderStatus.PROJECT_FINISHED)
          && (this.isAdmin || this.isManager || this.isClient);
    },
    paymentInfo: {
      get() {
        return this.paymentInfoDef;
      },
      set(data) {
        this.paymentInfoDef = data;
      },
    },
    payments() {
      return get(this.currentOrder, 'payments', []);
    },
    paymentsList() {
      return get(this.currentOrder, 'payments', []);
    },
    isPartner() {
      return this.userRole === PARTNER;
    },
    isManager() {
      return this.userRole === MANAGER;
    },
    isClient() {
      return this.userRole === CLIENT;
    },
    isAdmin() {
      return this.userRole === OWNER
          || this.userRole === CEO
          || this.userRole === DEPARTMENT_HEAD;
    },
    loaderDef: {
      get() {
        return this.loader;
      },
      set(data) {
        this.$emit('update:loader', data);
      },
    },
    isNewOrder() {
      const { code } = this.status;
      return code === orderStatus.ON;
    },
    roleUser() {
      return this.userRole;
    },
    calcNum() {
      const texts = [this.$t('global.dayOne'), this.$t('global.dayAlternative'), this.$t('global.days')];
      const number = this.days;
      const cases = [2, 0, 1, 1, 1, 2];
      return texts[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
    orderStatusText() {
      if (Object.keys(this.currentOrder).length) {
        if (
          (!this.conditionShowProgress.includes(this.currentOrderStatus))
            && (!this.currentOrder.projectExecutor
            && this.currentOrder.partnerAssignmentConfirmation === 'waitForResponse')) {
          if (this.isClient) {
            return this.$t('statuses.inProgress');
          }
          if (this.isAdmin) {
            return this.$t('statuses.partnerAppointed');
          }
          if (this.isAdmin || this.isManager) {
            const { partner = {}, projectManager, partnerAssignmentConfirmation } = this.currentOrder;
            const { _id } = partner;
            if (_id && projectManager && partnerAssignmentConfirmation === 'waitForResponse'
                && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
              return this.$t('statuses.partnerAppointed');
            }
            return this.$t('statuses.newOrder');
          }
          if (this.isPartner) {
            return this.$t('statuses.newOrder');
          }
        } else if (
          (!this.conditionShowProgress.includes(this.currentOrderStatus))
            && (!this.currentOrder.projectExecutor && this.currentOrder.partnerAssignmentConfirmation === 'rejected')) {
          if (this.isClient) {
            return this.$t('statuses.inProgress');
          }
          if (this.isAdmin) {
            return this.$t('statuses.cancelled');
          }
          if (this.isManager) {
            return this.$t('global.cancel');
          }
          if (this.isPartner) {
            return this.$t('statuses.cancelled');
          }
        } else if (
          (!this.conditionShowProgress.includes(this.currentOrderStatus))
            && (!this.currentOrder.projectExecutor && !this.currentOrder.partner)) {
          if (this.isClient) {
            if (this.currentOrderStatus === orderStatus.ORDER_CANCELLED) {
              return this.$t('statuses.cancelled');
            }
            return this.$t('statuses.preparatoryStage');
          }
          if (this.isAdmin || this.isManager) {
            if (this.currentOrderStatus !== orderStatus.ORDER_CANCELLED) {
              return this.$t('statuses.assignPerformer');
            }
            return this.$t('statuses.cancelled');
          }
        } else {
          if (this.isAdmin || this.isManager) {
            if (this.currentOrder.projectExecutor === 'projectManager'
                && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
              return this.$t('statuses.inProgressUB');
            } if (this.currentOrder.projectExecutor === 'partner'
                && this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
              return this.$t('statuses.inProgress');
            }
            if (this.currentOrderStatus === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
              return this.$t('statuses.assignPerformer');
            }
            return `${get(this.status, `manager.${this.language}`, '')}`;
          }
          if (this.isPartner) {
            if (this.currentOrder.projectExecutor
                && this.currentOrderStatus === orderStatus.ALL_STEPS_COMPLETED) {
              return this.$t('statuses.onCheck');
            }
            if (this.currentOrder.projectExecutor
                && (this.conditionShowText.includes(this.currentOrderStatus))) {
              return this.$t('statuses.accepted');
            }
            if (this.currentOrder.projectExecutor && this.currentOrderStatus === orderStatus.PROJECT_FINISHED) {
              return this.$t('statuses.projectCompleted');
            }
          }
          return `${get(this.status, `client.${this.language}`, '')}`;
        }
      }
      return '';
    },
    currentOrderStatus() {
      const { code } = this.status;
      return code;
    },
    barStyle() {
      return {
        width: `${this.value}%`,
      };
    },
  },
  mounted() {
    setTimeout(() => { this.ready = true; }, 0);
  },
  beforeMount() {
    if (this.isAdmin || this.isManager) {
      if (Object.keys(this.currentOrder).length) {
        const { partner: partnerObj, currency, _id: order } = this.currentOrder;
        if (partnerObj) {
          const { _id: partner } = partnerObj;
          this.orderPayment = {
            partner,
            currency,
            order,
          };
          ordersApi.getOrdersPartnerForPayoffs(partner, order)
            .then((resp) => {
              const { data } = resp;
              if (Object.keys(data).length) {
                this.orderPayment = {
                  ...this.orderPayment,
                  sum: data.maxPayoffSum,
                  paymentDate: new Date(),
                };
              } else {
                this.isVisiblePayoffButton = false;
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    }
  },
  methods: {
    toggleShowWindowWithPaypal() {
      this.$emit('toggleShowWindowWithPaypal');
    },
    openWindowSettings() {
      this.toggleIconAction = !this.toggleIconAction;
    },
    closeChooseWindow() {
      this.toggleIconAction = false;
    },
    cancelOrder() {
      this.$emit('cancelOrder');
      this.toggleIconAction = false;
    },
    changeStatusToPaid() {
      this.$emit('toggleStatusToPaid');
      this.toggleIconAction = false;
    },
    showNewPaymentModal() {
      this.isVisibleNewPaymentModal = true;
      this.toggleIconAction = false;
    },
    showRefundModal() {
      this.isVisibleRefundModal = true;
      this.toggleIconAction = false;
    },
    showChequeModal(cheque) {
      this.paymentInfo = cheque;
      this.isVisibleChequeModal = true;
    },
    closeModal() {
      this.isVisibleChequeModal = false;
      this.isVisibleRefundModal = false;
      this.isVisibleNewPaymentModal = false;
    },
    openCheckModal() {
      this.isVisibleCheckModal = !this.isVisibleCheckModal;
    },
    payFull(data) {
      if (data) {
        this.$emit('progressBarClickEvent');
      }
    },
    payHalf(data) {
      if (data) {
        this.$emit('progressBarClickEvent');
      }
    },
    closePayPal() {
      if (this.currentOrderStatus === this.orderStatus.LAST_PAYMENT_REQUIRED) {
        this.openPayPal = false;
      }
    },
    finishOrder() {
      this.$emit('finishOrder');
    },
    cancelOrderPartner() {
      this.$emit('cancelOrderPartner');
    },
    acceptOrderPartner() {
      this.$emit('acceptOrderPartner');
    },
    addProjectManager() {
      this.$emit('addProjectManager');
    },
    handleEvent() {
      if (this.currentOrderStatus === this.orderStatus.LAST_PAYMENT_REQUIRED) {
        // this.openPayPal = !this.openPayPal;
        this.toggleShowWindowWithPaypal();
      } else {
        this.$emit('progressBarClickEvent');
      }
    },
    setOpenChat() {
      if (!this.isNewOrder) {
        this.$emit('update:openChat', true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.order {

  &-panel {

    .ub-btn_disable {
      color: $color-silver-chalice;
      border-color: $color-silver-chalice;
      cursor: default;
      background: transparent;
      pointer-events: none;
    }
  }

  .ub-btn {

    &__wrapper {
      margin-left: auto;
      width: 191px;
    }

    &_offset {
      margin-left: 9px;
    }
  }

  &-panel {
    box-shadow: 0 0 8px rgba($color-black, .04);
    border-radius: $borderRadius;
    min-height: 130px;
    background: $color-white;

    &__btn {
      margin: 0 9px;
    }

    &__text {
      margin-top: 5px;

      &_feedback {
        display: flex;
        align-items: center;
      }
    }

    &__circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 3px;
      margin-bottom: 1px;

      &_review {
        margin-bottom: 0;
      }

      &_waiting {
        background: $color-silver-chalice;
      }

      &_done {
        background: $color-green;
      }

      &_process {
        background: $color-dodger-blue;
      }
    }

    &-payment {
      position: relative;
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid $color-alabaster;
      padding: 14px 58px 18px 17px;
      user-select: none;
      z-index: 50;
    }

    &__wrapper {
      display: flex;

      &-btn {
        margin-right: 9px;
      }
    }

    &__btn {

      &_offest {
        margin-right: 16px;
      }
    }

    &__info {

      &-title {
        font: $font-size-xlg $font-global-medium;
      }
    }

    .progress {
      height: 56px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 14px 19px;
      user-select: none;

      &-bar {
        height: 8px;
        background: rgba($color-cornflower-blue, .04);
        border-radius: 44px;
        width: 70%;

        &__blue {
          background-color: $color-cornflower-blue;
        }
      }

      &-row {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__checks {
          font-family: $font-global-medium;
        }

        &__dialog {
          color: $color-silver-chalice;
          font: $font-size-base $font-global-medium;
          transition: opacity .15s ease-in;
          cursor: pointer;
          white-space: nowrap;
          pointer-events: none;
          position: relative;
          display: flex;

          i {
            color: $color-gallery;
          }

          &_active {
            pointer-events: all;
            color: $color-dodger-blue;

            i {
              color: inherit;
            }

          }

          &:hover {
            opacity: .8;
          }

          &_check {
            transition: opacity .15s ease-in;
            font-family: $font-global;
            margin-right: 9px;
            pointer-events: all;
            color: $color-dodger-blue;

              i {
                color: inherit;
              }

            &:hover {
              opacity: 1;
            }

            & .ub-icon-сheck-bank {
              font-size: $font-size-xlg;
            }
          }
        }

        &__status {
          display: flex;
          align-items: center;
          width: 70%;

          &-circle {
            width: 4px;
            height: 4px;
            background: $color-silver-chalice;
            display: inline-block;
            border-radius: 50%;
            margin-right: 8px;
          }

          &-bar {
            height: 8px;
            border-radius: 44px;
            width: 0;
            transition: width .4s ease-in;
          }

          &-procent {
            color: $color-dodger-blue;
            font: $font-size-base $font-global-medium;
            margin-left: 10px;
            margin-right: 6px;
          }

          &-days {
            color: $color-silver-chalice;
            display: flex;
            align-items: center;
            font: $font-size-base $font-global-medium;
            white-space: nowrap;
          }
        }

        &-modal {
          background: $color-white;
          box-shadow: 0 0 25px 2px rgba($color-black, .08);
          border-radius: $borderRadius;
          top: 20px;
          position: absolute;
          padding: 6px 0;
          width: 290px;
          z-index: 10;

          &__item {
            min-height: 54px;
            padding: 18px 21px 18px 18px;
            transition: background-color .15s ease-in, opacity 3s ease-in;
            display: flex;
            align-items: center;

            .ub-icon-eye-order, .ub-icon-layer-download  {
              opacity: 0;
              transition: opacity .15s ease-in;
            }

            &:hover {

              .ub-icon-eye-order, .ub-icon-layer-download {
                opacity: 1;
                transition: color .15s ease-in;

                &:hover {
                  color: $color-dodger-blue;
                }
              }
            }

            span {
              font-size: $font-size-md;
              color: $color-black;
              font-weight: normal;
              padding-bottom: 2px;
            }

            &:hover {
              background: $color-alabaster;
            }

            & .ub-icon-layer-download {
              position: absolute;
              right: 15px;
              font-size: $font-size-lg;
              color: $color-silver-chalice;
            }

            & .ub-icon-file-1 {
              font-size: $font-size-icon-xd;
            }

            & .ub-icon-eye-order {
              position: absolute;
              right: 46px;
              color: $color-silver-chalice;
              font-size: $font-size-ml;
            }

          }
        }
      }
    }
  }
}

.action-elements {
  position: absolute;
  right: 18px;
  top: 22px;
  font-size: $font-size-md;
  width: 24px;
  height: 24px;
  border: 1px solid rgba($color-dodger-blue, .08);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: $color-dodger-blue;
  cursor: pointer;
  transition: all .15s ease-in;
  z-index: auto;

  &-choose {
    user-select: none;
    position: absolute;
    top: 12px;
    right: 10px;
    left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba($color-black, .1);
    border-radius: $borderRadius;
    background-color: $color-white;
    color: $color-black;
    cursor: default;
    min-width: 226px;
    max-width: 226px;
    padding: 3px 0;
    z-index: 0;

    &-controls {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__button {
        line-height: 1.5;
        min-height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color .15s ease-in;
        padding: 0 12px;
        font: $font-size-md $font-global;

        &_disable {
          pointer-events: none;
          cursor: default;
          background: rgba($color-alabaster, .2);
          color: $color-silver-chalice;
        }

        &:hover {
          background: $color-alabaster;
        }
      }
    }
  }

  &:before {
    position: relative;
    border-color: transparent;
  }

  &:hover {
    background: $color-dodger-blue;
    color: $color-white;
    border-color: transparent;
  }
}

.ub-icon-cross-x {
  background: $color-dodger-blue;
  color: $color-white;

  &:before {
    font-size: $font-size-h6;
    left: -.5px;
  }
}
</style>
