<template>
  <div
    class="ub-number-cell"
    :class="['ub-number-cell_' + (column.className ? row.className : '')]"
  >
    {{ value }}
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'UBNumberCell',
  props: {
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
    index: {
      type: Number,
      default: () => -1,
    },
  },
  computed: {
    value() {
      const number = get(this.row, this.column.prop, '');
      return this.convertNumber(number);
    },
  },
  methods: {
    convertNumber(number) {
      const num = +number;
      return String(num.toFixed(2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-number-cell {
    display: inline-block;
    font-family: $font-global;

    &_red {
      margin-left: -7px;
      border-radius: $borderRadius;
      padding: 10px 7px;
      color: $color-alabaster;
      background-color: rgba($color-alabaster, .2);
    }
  }

</style>
