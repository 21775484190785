<template>
  <div class="admin-modal">
    <loader v-if="loader" />
    <div class="admin-modal-wrap">
      <div class="admin-modal-wrap-header">
        <div class="admin-modal-wrap-header__title">
          {{ $t('paymentModal.title') }}
        </div>
        <div
          class="admin-modal-wrap-header__close"
          @click="closeModal"
        >
          <i class="ub-icon-close-circle admin-modal-wrap-header__icon"></i>
        </div>
      </div>
      <div class="admin-modal-wrap-content">
        <div
          v-if="select"
          class="admin-form-field"
        >
          <div class="admin-form-field__label">
            {{ $t('paymentModal.formPayment') }}
          </div>
          <selectWithKeyTemplate
            :settings="chequeSelectSettings"
            :class="{'error': errors.has('selectedPayment')}"
            @changeSelect="changeSelectCheque($event)"
          />
          <transition name="fade-validation">
            <span
              v-if="errors.has('selectedPayment')"
              class="validation"
            >
              {{ errors.first('selectedPayment') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('paymentModal.amountLabelText') }}
          </div>
          <textInput
            :typeInput="'text'"
            :disabled="true"
            :value.sync="refundAmount"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('refundAmount')"
              class="validation"
            >
              {{ errors.first('refundAmount') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('paymentModal.refundLabelText') }}
          </div>
          <textInput
            :typeInput="'text'"
            :value.sync="refundText"
            :autofocus="!select"
            :class="{'error': errors.has('refundText')}"
            :errorStatus="errors.has('refundText')"
            :textCapitalize="true"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('refundText')"
              class="validation"
            >
              {{ errors.first('refundText') }}
            </span>
          </transition>
        </div>
      </div>
      <div class="admin-modal-wrap-footer">
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_cancel"
            @click="closeModal"
          >
            {{ $t('global.cancel') }}
          </button>
        </div>
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_primary"
            @click="refundPayment"
          >
            {{ $t('global.return') }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-shortkey="['esc']"
      class="admin-modal__overlay"
      @click="closeModal"
      @shortkey="closeModal"
    ></div>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';
import validationErrorMessage from '@/locales/validationErrorMessage';
import loader from '@/components/loader';
import staffRoles from '@/constants/staffRoles';
import paymentsApi from '@/api/payments/paymentsApi';
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';

export default {
  name: 'RefundModal',
  components: {
    textInput,
    selectWithKeyTemplate,
    loader,
  },
  props: {
    paymentsList: {
      type: Array,
      default: () => [],
    },
    select: {
      type: Boolean,
      default: false,
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
    staffList: {
      type: Array,
      default: () => [],
    },
    editStaff: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: '',
    },
    sum: {
      type: Number,
    },
    userId: {
      type: String,
    },
  },
  data() {
    return {
      staffRoles,
      loader: false,
      refundText: '',
      refundAmount: '',
      selectedPayment: '',
      paymentForm: '',
      validationError: {
        text: '',
        status: false,
      },
    };
  },
  computed: {
    selectList() {
      const newArr = [];
      this.paymentsList.forEach((item) => {
        if (!item.isRefunded) {
          newArr.push({
            text: item.isPrepayment ? this.$t('clientOrder.chequePrepayment')
              : this.$t('clientOrder.chequeAdditionalPayment'),
            key: item._id,
          });
        }
      });
      return newArr;
    },
    chequeSelectSettings() {
      return {
        optionList: this.selectList,
        placeholderText: this.$t('refundModal.placeholder'),
        selectedKey: this.selectedPayment,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: '',
        imagePosition: 'left',
        showImageInput: true,
      };
    },
    language() {
      return this.$i18n.locale;
    },
  },
  beforeMount() {
    if (!this.select) {
      this.refundAmount = this.sum;
    }
    const dict = {
      en: {
        custom: {
          refundText: {
            required: validationErrorMessage.en.inputRequired,
          },
          selectedPayment: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        custom: {
          refundText: {
            required: validationErrorMessage.ru.inputRequired,
          },
          selectedPayment: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'refundText', rules: { required: true } });
    this.$validator.attach({ name: 'selectedPayment', rules: { required: true } });
  },
  methods: {
    changeSelectCheque(key) {
      this.selectedPayment = key;
      const selected = this.paymentsList.find((item) => item._id === key);
      if (selected) {
        this.refundAmount = selected.sum;
      }
    },
    refundPayment() {
      this.$validator.validateAll({
        refundText: this.refundText === 'REFUND' ? true : '',
        refundAmount: this.refundAmount <= this.sum ? this.refundAmount : '',
        selectedPayment: !this.select ? true : this.selectedPayment,
      }).then((result) => {
        if (result) {
          const refund = {
            sum: +this.refundAmount,
            paymentService: this.row.paymentForm,
          };
          if (Object.keys(this.row).length) {
            refund.id = this.userId;
          } else {
            refund.id = this.selectedPayment;
          }
          this.loader = true;
          paymentsApi.refundPayment(refund).then((resp) => {
            if (this.row) {
              this.$emit('update', { row: resp.data });
            }
            if (this.select) {
              const selected = this.paymentsList.find((item) => item._id === this.selectedPayment);
              if (selected) {
                selected.isRefunded = true;
              }
            }
            this.loader = false;
            this.$emit('closeModal');
          }).catch((err) => {
            this.loader = false;
            // eslint-disable-next-line no-console
            console.error(err);
          });
        }
      });
    },
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";

.admin-modal-wrap {
  width: 400px;
}

</style>
