<template>
  <div
    class="chat"
  >
    <div
      class="chat-status"
      :class="{'chat-status_disabled': isNewOrder}"
      @click="toggleStatus()"
    >
      <div
        v-if="messageCount"
        class="chat-status__number"
      >
        <span>{{ messageCount }}</span>
      </div>
      <transition
        name="fade-content"
        mode="out-in"
      >
        <div
          v-if="statusChat"
          :key="'first'"
        >
          <i class="ub-icon-cross-x chat-status__off"></i>
        </div>
        <div
          v-else
          :key="'second'"
        >
          <i class="ub-icon-chan-on chat-status__on"></i>
        </div>
      </transition>
    </div>
    <transition name="fade-content">
      <div
        v-if="statusChat"
        class="chat-window"
        @mouseenter="checkUnreadMessage"
      >
        <div class="chat-window__header">
          <div
            class="chat-window__order"
            :class="{'chat-window__order_capitalize': isAdmin || isManager}"
          >
            {{ chatTitle }}
          </div>
        </div>
        <VuePerfectScrollbar
          ref="chatScroll"
          class="chat-window__body"
          @ps-scroll-y="checkUnreadMessage"
        >
          <div
            class="message__list"
            :class="{'message__list_empty': !messagesList.length}"
          >
            <div
              v-if="!messagesList.length"
              class="message__list-placeholder"
            >
              {{ $t('chat.placeholder') }}
            </div>
            <template v-if="messagesList.length">
              <div
                v-for="(item, i) in messagesList"
                :key="item._id"
              >
                <div
                  v-if="showChatDateRow(item.date, i)"
                  class="message__row message__row_center"
                >
                  <div
                    class="message-item message-item_date"
                  >
                    <span>
                      <template
                        v-if="showTodayText(item.date)"
                      >
                        {{ $t('chat.today') }},
                      </template>
                      {{ messageDate(item.date) }}
                    </span>
                  </div>
                </div>
                <div
                  :ref="'messageRow'"
                  class="message__row"
                  :class="{
                    'message__row_end': userInfo._id === item.senderId
                  }"
                >
                  <div
                    :id="item._id"
                    :ref="!item.isRead.includes(userInfo._id) && userInfo._id !== item.senderId ? 'unread' : 'read'"
                    class="message-item"
                    :class="{'message-item_me': userInfo._id === item.senderId}"
                  >
                    <transition name="fade-content">
                      <ChatFile
                        v-if="item.file"
                        :item="item"
                        :clientName="clientName"
                        :chatPartnerId="chatPartnerId"
                        :chatClientId="chatClientId"
                        :chatAdminsId="chatAdminsId"
                        :isMe="userInfo._id === item.senderId"
                      />
                      <ChatMessage
                        v-else
                        :item="item"
                        :clientName="clientName"
                        :chatPartnerId="chatPartnerId"
                        :chatClientId="chatClientId"
                        :chatAdminsId="chatAdminsId"
                        :isMe="userInfo._id === item.senderId"
                      />
                    </transition>
                    <div class="message-item__time">
                      <span>{{ formatDate(item.date) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </VuePerfectScrollbar>
        <div
          ref="downPage"
          class="chat-window__footer"
        >
          <div class="chat-window__media">
            <i class="ub-icon-media"></i>
            <Upload
              class="upload-file"
              :popupDown="false"
              :multiple="true"
              :upload="true"
              :item="elem"
              :isHover.sync="isHover"
              @upload="uploadAllFiles"
            />
          </div>
          <form
            class="chat-window__form"
            @submit.prevent="sendMessage($event)"
          >
            <textInput
              :typeInput="'text'"
              :autofocus="true"
              :value.sync="chatInfo.newMessage"
              :customClass="'text-field_chat'"
              :placeholderText="$t('chat.writeMessage')"
            >
            </textInput>
            <button
              type="submit"
              class="chat-window__send"
              @click.prevent="sendMessage($event)"
            >
              <i class="ub-icon-send"></i>
            </button>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import textInput from '@/components/fields/textInput';
import Upload from '@/components/Upload';
import filesApi from '@/api/files/filesApi';
import ChatFile from '@/components/chat/chatFile';
import ChatMessage from '@/components/chat/chatMessage';
import chatApi from '@/api/chat/chatApi';
import moment from 'moment';
import { findIndex, get, find } from 'lodash';
import getUniqueId from '@/mixins/uniqueId';
import { orderStatus } from '@/constants/orderStatus';
import {
  MANAGER, PARTNER, CLIENT, OWNER, CEO, DEPARTMENT_HEAD,
} from '@/constants/roleType';

export default {
  name: 'Chat',
  components: {
    ChatFile,
    ChatMessage,
    VuePerfectScrollbar,
    textInput,
    Upload,
  },
  props: {
    openChat: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
    },
    chatId: {
      type: String,
    },
  },
  data() {
    return {
      elem: {},
      isHover: false,
      chatDataDef: {},
      chatInfo: {
        newMessage: '',
      },
      messagesListDef: [],
    };
  },
  computed: {
    isPartner() {
      return this.userRole === PARTNER;
    },
    isManager() {
      return this.userRole === MANAGER;
    },
    isClient() {
      return this.userRole === CLIENT;
    },
    isAdmin() {
      return this.userRole === OWNER
          || this.userRole === CEO
          || this.userRole === DEPARTMENT_HEAD;
    },
    statusChat: {
      get() {
        return this.openChat;
      },
      set(data) {
        this.$emit('update:openChat', data);
      },
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    chatPartnerId() {
      const { partner = {} } = this.chatData;
      const { _id } = partner;
      return _id;
    },
    clientName() {
      const { client = {} } = this.order;
      const { firstName = '' } = client;
      return firstName;
    },
    chatClientId() {
      const { client = {} } = this.chatData;
      const { _id } = client;
      return _id;
    },
    chatAdminsId() {
      const { admins = [] } = this.chatData;
      return admins.map((user) => user._id);
    },
    userRole() {
      const { role } = this.userInfo;
      return role;
    },
    isNewOrder() {
      const { status: { code } } = this.order;
      return code === orderStatus.ON;
    },
    chatData: {
      get() {
        return this.chatDataDef;
      },
      set(data) {
        this.chatDataDef = data;
      },
    },
    chatTitle() {
      const { client, partner, orderId } = this.order;
      if (this.isAdmin || this.isManager) {
        const clientFirstName = get(client, 'firstName', '');
        const clientLastName = get(client, 'lastName', '');
        const clientFirstNameCapitalize = clientFirstName.charAt(0).toUpperCase() + clientFirstName.substr(1);
        const clientLastNameCapitalize = clientLastName.charAt(0).toUpperCase() + clientLastName.substr(1);
        const clientFullName = `${clientFirstNameCapitalize}${clientLastNameCapitalize
          ? ` ${clientLastNameCapitalize}` : ''}`;
        const partnerCompanyName = get(partner, 'partnerCompanyName', '');
        const partnerCompanyNameCapitalize = partnerCompanyName
          ? partnerCompanyName.charAt(0).toUpperCase() + partnerCompanyName.substr(1)
          : '';
        return `${this.$t('chat.adminTitle.first')} ${clientFullName} ${this.$t('chat.adminTitle.second')}
        ${partnerCompanyNameCapitalize}`;
      } if (this.isClient) {
        return 'UPPERBUSINESS';
      } if (this.isPartner) {
        const clientFirstName = get(client, 'firstName', '');
        const clientLastName = get(client, 'lastName', '');
        const clientFullName = `${clientFirstName}${clientLastName ? ` ${clientLastName}` : ''}`;
        return `${clientFullName}`;
      }
      return `${this.$t('chat.title')} ${orderId}`;
    },
    messagesList() {
      const { messages = [] } = this.chatData;
      return messages;
    },
    language() {
      return this.$i18n.locale;
    },
    messageCount() {
      const unreadMessages = this.messagesList.filter(
        (message) => message.senderId !== this.userInfo._id && !message.isRead.includes(this.userInfo._id),
      );
      return unreadMessages.length > 99 ? 99 : unreadMessages.length;
    },
  },
  watch: {
    statusChat(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          setTimeout(() => {
            const scrollPage = this.$refs.chatScroll.$el;
            const unreadMessageList = this.$refs.unread || [];
            const firstUnreadMessage = unreadMessageList[0];
            if (firstUnreadMessage) {
              const scrollPosition = firstUnreadMessage.getBoundingClientRect().top - scrollPage.scrollTop;
              const scrollPageTop = scrollPage.getBoundingClientRect().top;
              this.scrollTo(scrollPage, scrollPosition - scrollPageTop, 20, 'clear');
              this.checkUnreadMessage();
            } else {
              const readMessageList = this.$refs.read || [];
              const lastReadMessage = readMessageList[readMessageList.length - 1];
              if (lastReadMessage) {
                const scrollPosition = lastReadMessage.getBoundingClientRect().top - scrollPage.scrollTop;
                const scrollPageTop = scrollPage.getBoundingClientRect().top;
                this.scrollTo(scrollPage, scrollPosition - scrollPageTop, 20, 'clear');
              }
            }
          }, 100);
        });
      }
    },
    isNewOrder(newVal) {
      if (!newVal) {
        this.getChatInfo();
      }
    },
  },
  beforeMount() {
    if (!this.isNewOrder) {
      this.getChatInfo();
    }
  },
  beforeDestroy() {
    const { _id } = this.chatData;
    this.sockets.unsubscribe(_id);
  },
  methods: {
    checkUnreadMessage() {
      const scrollPage = this.$refs.chatScroll.$el;
      const unreadMessageList = this.$refs.unread || [];
      setTimeout(() => {
        const chatBottomPosition = scrollPage.getBoundingClientRect().bottom;
        unreadMessageList.every((message) => {
          if (chatBottomPosition > message.getBoundingClientRect().top) {
            setTimeout(() => {
              this.updateReadStatusMessage(message.id);
            }, 1000);
          }
          return chatBottomPosition > message.getBoundingClientRect().top;
        });
      }, 100);
    },
    updateReadStatusMessage(id) {
      const { _id } = this.userInfo;
      const currentMessage = find(this.messagesList, (message) => message._id === id);
      if (!currentMessage.isRead.includes(_id)) {
        currentMessage.isRead.push(_id);
        const { isRead } = currentMessage;
        const message = {
          isRead,
        };
        chatApi.updateMessage({ id, message })
          .then()
          .catch((err) => {
            console.error(err);
          });
      }
    },
    socketShowEvent(id) {
      this.sockets.subscribe(id, (data) => {
        if (data.senderId !== this.userInfo._id) {
          this.messagesList.push(data);
        }
      });
    },
    showChatDateRow(date, i) {
      if (i === 0) {
        return true;
      }
      const prevMessage = this.messagesList[i - 1];
      const prevMessageDate = moment(prevMessage.date).format('DD MMM YY');
      return !moment(moment(date).format('DD MMM YY')).isSame(prevMessageDate);
    },
    messageDate(date) {
      const format = this.language === 'ru' ? 'DD MMMM' : 'MMM, DD';
      return moment(date).locale(this.language).format(format);
    },
    showTodayText(date) {
      const currentDate = moment(new Date()).format('DD MMM YY');
      return moment(currentDate).isSame(moment(date).format('DD MMM YY'));
    },
    formatDate(date) {
      return moment(date).locale(this.language).format('LT');
    },
    uploadAllFiles(item) {
      item.forEach((el) => {
        const file = {
          _id: getUniqueId(),
          originalName: el.name,
          url: '',
          uploadFile: true,
        };
        const fileFull = this.setFileData(el);
        this.uploadFiles(fileFull, file);
      });
    },
    setFileData(file) {
      const fileData = new FormData();
      fileData.set('fieldname', 'uploadFile');
      fileData.set('label', file.name || '');
      fileData.set('file', file);
      return fileData;
    },
    uploadFiles(fileData, file) {
      this.checkUnreadMessage();
      const messageId = new Date().getTime();
      this.messagesList.push({
        message: '',
        senderId: this.userInfo._id,
        chatId: this.chatData._id,
        isRead: [this.userInfo._id],
        file,
        _id: messageId,
      });
      this.scrollToMessage();
      filesApi.createFile(fileData)
        .then((resp) => {
          const indexCurrentField = findIndex(this.messagesList, (file) => file._id === messageId);
          const { _id, originalName } = resp.data;
          const currentMessage = this.messagesList[indexCurrentField];
          const messageItem = {
            ...currentMessage,
            file: resp.data,
          };
          this.messagesList.splice(indexCurrentField, 1, messageItem);
          const message = this.chatInfo.newMessage;
          const { client, partner } = this.order;
          const recipientId = this.userInfo.role === 'Client' ? partner._id : client._id;
          const newMessage = {
            message: originalName,
            senderId: this.userInfo._id,
            chatId: this.chatData._id,
            file: _id,
            isRead: [this.userInfo._id],
            recipientId,
            isSystem: false,
          };
          chatApi.addMessage(newMessage)
            .then(() => {
              if (message) {
                this.sendMessage();
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    scrollToMessage() {
      const scrollPage = this.$refs.chatScroll.$el;
      this.$nextTick(() => {
        const messageRowList = this.$refs.messageRow || [];
        const scrollRow = messageRowList[messageRowList.length - 1];
        if (scrollRow) {
          const scrollPosition = scrollRow.getBoundingClientRect().top + scrollPage.scrollTop;
          const scrollPageTop = scrollPage.getBoundingClientRect().top;
          this.scrollTo(scrollPage, scrollPosition - scrollPageTop, 500, 'clear');
        }
      });
    },
    sendMessage() {
      this.checkUnreadMessage();
      this.scrollToMessage();
      const { client, partner } = this.order;
      const recipientId = this.userInfo.role === 'Client' ? partner._id : client._id;
      if (this.chatInfo.newMessage !== '') {
        const newMessage = {
          message: this.chatInfo.newMessage,
          isSystem: false,
          chatId: this.chatData._id,
          isRead: [this.userInfo._id],
          senderId: this.userInfo._id,
          recipientId,
        };
        chatApi.addMessage(newMessage)
          .then(() => {
          })
          .catch((err) => {
            console.error(err);
          });
        this.messagesList.push({
          message: this.chatInfo.newMessage,
          senderId: this.userInfo._id,
          chatId: this.chatData._id,
          _id: new Date().getTime(),
          date: new Date(),
          isRead: [this.userInfo._id],
        });
        this.chatInfo.newMessage = '';
      }
    },
    toggleStatus() {
      if (!this.isNewOrder) {
        this.statusChat = !this.statusChat;
      }
    },
    scrollTo(element, to, duration, clear) {
      if (duration === 0) {
        return;
      }
      if (to < 0) to = 0;
      const difference = to - element.scrollTop;
      const perTick = (difference / duration) * 10;
      if (clear) {
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
        let newTop = element.scrollTop + perTick;
        if (newTop < 0) newTop = 0;
        element.scrollTo({
          top: newTop,
        });
        if (newTop === 0) return false;
        if (element.scrollTop === to) return false;
        this.scrollTo(element, to, duration - 10);
        return false;
      }, 10);
    },
    getChatInfo() {
      const { _id, client, partner = {} } = this.order;
      const partnerId = partner._id;
      chatApi.getChat({ id: _id })
        .then((resp) => {
          const { data } = resp;
          if (data.length === 0) {
            const { role } = this.userInfo;
            if (role !== 'Admin') {
              const newChatObj = {
                status: 'active',
                partner: partnerId,
                client: client._id,
                order: _id,
              };
              chatApi.createChat(newChatObj)
                .then((resp) => {
                  const { data } = resp;
                  const { messages = [] } = data;
                  this.chatData = {
                    ...data,
                    messages,
                  };
                  if (this.chatId !== undefined) {
                    this.$emit('update:chatId', data._id);
                  }
                  this.socketShowEvent(data._id);
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          } else {
            const [currentChat] = data;
            const { role, _id } = this.userInfo;
            if (role === 'Admin' || role === 'Manager') {
              const { admins = [] } = currentChat;
              const adminsList = admins.map((user) => user._id);
              if (!adminsList.includes(_id)) {
                adminsList.push(_id);
                admins.push(this.userInfo);
                const chat = {
                  admins: adminsList,
                };
                chatApi.updateChat({ id: currentChat._id, chat })
                  .then()
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }
            this.socketShowEvent(currentChat._id);
            this.chatData = currentChat;
            if (this.chatId !== undefined) {
              this.$emit('update:chatId', currentChat._id);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../sass/variables";

  .message {

    &__list {
      display: inline-flex;
      flex-direction: column;
      width: 100%;

      &_empty {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        min-height: 490px;
      }

      &-placeholder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-silver-chalice;
        user-select: none;
      }
    }

    &__row {
      width: 100%;

      &_end {
        display: flex;
        justify-content: flex-end;
      }

      &_center {
        display: flex;
        justify-content: center;
      }
    }

    &-item {
      display: inline-flex;
      flex-direction: column;
      margin: 8px 0;

      &-progress {
        height: 4px;
        background: rgba($color-dodger-blue, .08);
        border-radius: $borderRadius;
        transition: all .2s ease-in;
        width: 100%;
        position: relative;
        overflow: hidden;

        &__line {
          background: $color-dodger-blue;
          border-radius: $borderRadius;
          transition: all .2s ease-in;
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 100%;
          animation: file-upload 3s linear infinite;
        }
      }

      &__docname {
        transition: all .15s ease-in;
      }

      &__content {
        &_upload {
          .message-item {
            &__docname {
              display: block;
              margin-bottom: 3px;
            }
          }
        }
      }

      &__value {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 12px;
        border-radius: $borderRadiusExtra $borderRadiusExtra $borderRadiusExtra;
        background: $color-alabaster;
        color: $color-black;
        font-size: $font-size-xlg;
        margin-bottom: 3px;
        max-width: 314px;
        line-height: 1.25;
        text-decoration: none;
        transition: background-color .15s ease-in;

        &-wrapper {
          display: flex;
          max-width: 100%;
          align-items: center;
          overflow: hidden;
        }

        &_file {

          &:hover {
            background: $color-gallery;
          }

        }

        i {
          color: $color-dodger-blue;
          position: relative;
          font-size: $font-size-icon-sm;

          &:before {
            margin: 0;
          }
        }
      }

      &__label {
        display: inline-block;
        padding: 2px 4px;
        margin-bottom: 7px;
        background: $color-gallery;
        border-radius: $borderRadius;
        font-size: $font-size-landing-bread-crums;
        color: $color-silver-chalice;

        &:first-letter {
          text-transform: capitalize;
        }
      }

      &__icon {
        width: 17px;
        min-width: 17px;
        margin-right: 3px;
      }

      &__time {
        font-size: $font-size-md;
        color: $color-silver-chalice;
      }

      &_me {
        align-items: flex-end;

        .message-item__value {
          background: rgba($color-dodger-blue, .08);
          border-radius: $borderRadiusExtra $borderRadiusExtra 0 $borderRadiusExtra;

          &_file {

            &:hover {
              background: rgba($color-dodger-blue, .12);
            }

          }

        }
      }

      &_date {
        margin-bottom: 4px;
        margin-top: 15px;

        span {
          color: $color-santas-gray;
        }
      }
    }
  }

  .chat {
    position: absolute;
    bottom: 0;
    right: 0;

    &-window {
      position: fixed;
      bottom: 95px;
      right: 25px;
      min-width: 395px;
      max-width: 395px;
      min-height: 570px;
      border-radius: $borderRadius;
      background: $color-white;
      box-shadow: 0 0 25px 1px rgba($color-black, .06);
      z-index: 10;

      &_admin {
        min-height: 530px;
      }

      &__header {
        display: flex;
        align-items: center;
        min-height: 57px;
        background: $color-dodger-blue;
        padding: 0 15px;
        justify-content: center;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        user-select: none;
      }

      &__footer {
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-top: 1px solid $color-alabaster;
      }

      &__media {
        cursor: pointer;
        position: relative;

        .upload-file {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }

        i {
          color: $color-silver-chalice;
          font-size: $font-size-xlg;
          transition: color .15s ease-in;
        }

        &:hover {
          i {
            color: $color-dodger-blue;
          }
        }
      }

      &__form {
        display: flex;
        width: 100%;
      }

      &__send {
        cursor: pointer;

        i {
          color: $color-dodger-blue;
          font-size: $font-size-dd;
          transition: color .15s ease-in;
        }

        &:hover {
          i {
            color: $color-denim;
          }
        }
      }

      &__order {
        color: $color-white;
        text-align: center;
        font: $font-size-xlg $font-global-medium;

        &_capitalize {
          text-transform: capitalize;
        }
      }

      &__body {
        padding: 0 12px 8px 12px;
        max-height: 469px;
        min-height: 469px;
      }
    }

    &-status {
      position: fixed;
      bottom: 25px;
      right: 25px;
      max-width: 60px;
      min-width: 60px;
      height: 60px;
      border-radius: 50%;
      background: $color-dodger-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color .15s ease-in;
      box-shadow: 0 0 8px rgba($color-black, .04);

      &_disabled {
        background: $color-silver-chalice;
        pointer-events: none;
      }

      i {
        color: $color-white;
      }

      &__off {
        font-size: $font-size-dd;
      }

      &__on {
        font-size: $font-size-icon-large;
      }

      &:hover {
        background: $color-denim;
      }

      &__number {
        min-width: 22px;
        max-width: 22px;
        height: 22px;
        border: 2px solid $color-white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-green;
        position: absolute;
        right: -7px;
        top: 1px;

        span {
          font: $font-size-md $font-global-bold;
          color: $color-white;
        }
      }
    }
  }
</style>
