<template>
  <div class="admin-form-field admin-form-field_half admin-form-field_list">
    <transition-group
      name="fade-content"
      appear
      mode="out-in"
    >
      <div
        v-for="(field, index) in dynamicList"
        :key="field.id"
        class="fields-settings-item"
        :class="{'fields-settings-item_active': toggleIconAction === field.id}"
      >
        <textInput
          :typeInput="'text'"
          :name="'textInput'"
          :placeholderText="$t('global.variant')"
          :textCapitalize="true"
          :value.sync="field.text"
          :class="{'error': field.text === '' && showErrorInputInside}"
          :errorStatus="field.text === '' && showErrorInputInside"
        >
        </textInput>
        <transition name="fade-content">
          <div
            v-show="field.text === '' && showErrorInputInside"
            class="validation"
          >
            {{ validationError[language].inputRequired }}
          </div>
        </transition>
        <div
          v-if="dynamicList.length > 2"
          class="action-elements"
          :class="toggleIconAction === field.id ? 'ub-icon-cross-x' : 'ub-icon-more-vertical'"
          @click.stop="openWindowSettings(field.id)"
        >
          <transition
            name="fade-el"
            mode="out-in"
          >
            <div
              v-if="toggleIconAction === field.id"
              v-click-outside="closeChooseWindow"
              class="action-elements-choose"
            >
              <div class="action-elements-choose-controls">
                <button
                  class="ub-button action-elements-choose-controls__button"
                  @click.stop
                  @click="updateVariant($event, index)"
                >
                  {{ $t('buttons.delete') }}
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition-group>
    <button
      class="button-action"
      @click="updateVariant($event, -1, 'new')"
    >
      <i class="ub-icon-cross"></i>
      <span>
        {{ $t('global.addVariant') }}
      </span>
    </button>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';
import validationErrorMessage from '@/locales/validationErrorMessage';
import { TYPE_LIST, TYPE_MULTILIST, TYPE_SWITCH } from '@/constants/typesAll';
import ClickOutside from 'vue-click-outside';
import { uniqueId } from 'lodash';

export default {
  name: 'GenerateDynamicFields',
  components: {
    textInput,
  },
  directives: {
    ClickOutside,
  },
  props: {
    dynamicListEl: {
      type: Array,
      default: () => [],
    },
    newFieldEl: {
      type: Object,
      default: () => {},
    },
    showErrorInput: {
      type: Boolean,
      default: false,
    },
    flag: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      validationError: validationErrorMessage,
      toggleIconAction: -1,
      typeList: TYPE_LIST,
      typeMultiList: TYPE_MULTILIST,
      typeSwitch: TYPE_SWITCH,
    };
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
    dynamicList: {
      get() {
        return this.dynamicListEl;
      },
      set(data) {
        this.$emit('update:dynamicListEl', data);
      },
    },
    newField: {
      get() {
        return this.newFieldEl;
      },
      set(data) {
        this.$emit('update:newFieldEl', data);
      },
    },
    showErrorInputInside: {
      get() {
        return this.showErrorInput;
      },
      set(data) {
        this.$emit('update:showErrorInput', data);
      },
    },
  },
  methods: {
    openWindowSettings(id) {
      this.toggleIconAction = this.toggleIconAction === id ? -1 : id;
    },
    closeChooseWindow() {
      this.toggleIconAction = -1;
    },
    updateVariant(e, index, action) {
      const {
        type,
        customSelectList,
        customMultiSelectList,
        customRadioButtonList,
        value,
      } = this.newField;
      let list = [];
      switch (type) {
      case this.typeList:
        list = this.flag === 'newEl' ? list = customSelectList : list = value;
        break;
      case this.typeMultiList:
        list = this.flag === 'newEl' ? list = customMultiSelectList : list = value;
        break;
      case this.typeSwitch:
        list = this.flag === 'newEl' ? list = customRadioButtonList : list = value;
        break;
      default:
        break;
      }
      if (action === 'new') {
        list.push({
          id: +uniqueId(),
          text: '',
          key: `variant${uniqueId()}`,
        });
      } else {
        list.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../sass/variables";

.fields-settings {
  border: 1px dashed $color-dodger-blue;
  background: rgba($color-dodger-blue, .08);
  border-radius: $borderRadius;
  padding: 10px 10px 16px 10px;
  max-width: 608px;
  margin: auto auto 30px auto;
  min-width: 608px;

  &-item {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 15px;

    .text-field {
      width: 285px;
    }

    &_active {
      z-index: $z-index-biggest;

      .text-field {
        position: relative;
        z-index: -1;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    margin-top: 9px;

    &_end {
      justify-content: flex-end;
    }
  }
}

.admin-form-field {
  width: 100%;

  & + .admin-form-field {
    margin-top: 10px;
  }

  &_height {
    height: 100%;
  }

  &_half {
    max-width: 264px;
    min-width: 264px;
  }

  &.admin-form-field_half.admin-form-field_list {
    margin-top: 20px;
    margin-left: 12px;
    min-width: 308px;
    max-width: 308px;
  }
}

.admin-modal-wrap {
  &-footer {
    padding: 37px 0 0 0;
  }
}

.action-elements {
  position: absolute;
  top: 8px;
  left: auto;
  right: -5px;
  font-size: $font-size-md;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: $color-dodger-blue;
  cursor: pointer;
  transition: all .15s ease-in;
  z-index: auto;

  &-choose {
    user-select: none;
    position: absolute;
    top: 12px;
    right: 10px;
    left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba($color-black, .1);
    border-radius: $borderRadius;
    background-color: $color-white;
    color: $color-black;
    cursor: default;
    min-width: 143px;
    max-width: 143px;
    padding: 3px 0;
    z-index: -1;

    &-controls {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__button {
        line-height: 1.5;
        min-height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color .15s ease-in;
        padding: 0 12px;
        font: $font-size-md $font-global;

        &:hover {
          background: $color-alabaster;
        }
      }
    }
  }

  &:before {
    position: relative;
    border-color: transparent;
  }

  &:hover {
    background: $color-dodger-blue;
    color: $color-white;
    border-color: transparent;
  }
}

.ub-icon-cross-x {
  background: $color-dodger-blue;
  color: $color-white;

  &:before {
    font-size: $font-size-h6;
  }
}

.button-action {
  width: 285px;

  i {
    font-size: $font-size-dd;
  }
}

.validation {
  z-index: -1;
}
</style>
