import i18n from '@/i18n';

import {
  MANAGER,
  DEPARTMENT_HEAD,
  OWNER,
  CEO,

} from '@/constants/listStaffRoles';

const staffRoles = [
  {
    key: DEPARTMENT_HEAD,
    text: i18n.t('staffRoles.departmentHead'),
  },
  {
    key: MANAGER,
    text: i18n.t('staffRoles.manager'),
  },
  {
    key: CEO,
    text: i18n.t('staffRoles.ceo'),
  },
  {
    key: OWNER,
    text: i18n.t('staffRoles.owner'),
  },
];
export default staffRoles;
