<template>
  <div
    v-click-outside="close"
    class="ub-inset-link"
    @click.stop
  >
    <label class="ub-inset-link-label">
      <textInput
        :placeholderText="'https://example.com'"
        :autofocus="true"
        :labelText="$t('global.link')"
        :typeInput="'text'"
        :value.sync="link.address"
        :clearButton="true"
      />
    </label>
    <div class="ub-inset-link-buttons">
      <button
        class="ub-btn ub-btn_cancel ub-inset-link__cancel"
        @click="close"
      >
        {{ $t('global.cancel') }}
      </button>
      <button
        class="ub-btn ub-btn_primary"
        @click="insetLink"
      >
        {{ $t('buttons.add') }}
      </button>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import textInput from '@/components/fields/textInput';

export default {
  name: 'InsetLink',
  directives: {
    ClickOutside,
  },
  components: {
    textInput,
  },
  data() {
    return {
      link: {
        address: '',
      },
    };
  },
  methods: {
    insetLink() {
      if (!this.link.address) {
        this.$emit('close');
        return;
      }
      const a = document.createElement('a');
      a.href = this.link.address;
      a.setAttribute('target', '_blank');
      this.$emit('insetLink', a);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../sass/variables";

  .ub-inset-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-white;
    width: 394px;
    height: 147px;

    &__cancel {
      margin-right: 5px;
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 4px;
    }

    &-label {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 8px;
      font: $font-size-md $font-global-medium;

      &__title {
        text-align: left;
        display: inline;
        color: $color-silver-chalice;
        margin-bottom: 4px;
      }
    }

    &_button {
      padding: 0;
      min-width: 55px;
      width: 55px;
      height: 30px;
      font-size: $font-size-md;
      margin-top: 8px;
    }
  }

</style>
