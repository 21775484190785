<template>
  <div class="card-client">
    <div class="card-client__top">
      <transition
        name="fade-content"
        mode="out-in"
        appear
      >
        <fieldsTabs
          :cardInfo.sync="cardInfoInside"
          :filterQueryTab.sync="filterQuery"
          @openActiveTab="openActiveTab"
        />
      </transition>
      <div
        v-tooltip.bottom="$t('global.settingsFields')"
        class="card-client__settings"
        @click="toggleElementsForClients(true)"
      >
        <i class="ub-icon-settings"></i>
      </div>
    </div>
    <portal to="modalSettings">
      <transition
        name="fade-el"
        mode="out-in"
      >
        <elementsForClient
          v-if="openModalElementsForClients"
          :filterQueryTab.sync="filterQuery"
          :cardInfo.sync="cardInfoInside"
          :defInfoListInside.sync="defInfoList"
          :emptyCard="emptyCard"
          @toggleElementsForClients="toggleElementsForClients"
          @openActiveTab="openActiveTab"
          @changeEmptyCard="changeEmptyCard"
        />
      </transition>
    </portal>
  </div>
</template>

<script>
import elementsForClient from '@/components/modals/elementsForClient';
import fieldsTabs from '@/components/fieldsTabs';

export default {
  name: 'ElementsProfileClient',
  components: {
    elementsForClient,
    fieldsTabs,
  },
  props: {
    filterQueryTab: {
      type: Object,
      default: () => {},
    },
    cardInfo: {
      type: Object,
      default: () => {},
    },
    defInfoListInside: {
      type: Array,
      default: () => [],
    },
    emptyCard: {
      type: Boolean,
      default: false,
    },
    openModalElementsForClientsInside: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filterQuery: {
      get() {
        return this.filterQueryTab;
      },
      set(data) {
        this.$emit('update:filterQueryTab', data);
      },
    },
    cardInfoInside: {
      get() {
        return this.cardInfo;
      },
      set(data) {
        this.$emit('update:cardInfo', data);
      },
    },
    defInfoList: {
      get() {
        return this.defInfoListInside;
      },
      set(data) {
        this.$emit('update:defInfoListInside', data);
      },
    },
    openModalElementsForClients: {
      get() {
        return this.openModalElementsForClientsInside;
      },
      set(data) {
        this.$emit('update:openModalElementsForClientsInside', data);
      },
    },
  },
  methods: {
    changeEmptyCard(val) {
      this.$emit('changeEmptyCard', val);
    },
    openActiveTab(tab) {
      this.$emit('openActiveTab', tab);
    },
    toggleElementsForClients(val) {
      this.openModalElementsForClients = val;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";
  .card-client {
    overflow: hidden;

    &__top {
      display: flex;
      justify-content: space-between;
      padding: 24px 20px 0;
    }

    &__settings {
      cursor: pointer;
      font-size: $font-size-h2;
      color: $color-silver-chalice;
      transition: color .15s ease-in;
      position: relative;
      top: 1px;
      height: 100%;

      &:hover {
        color: $color-dodger-blue;
      }
    }
  }
</style>
