<template>
  <div>
    <loader v-if="loader" />
    <div class="ub-description">
      <ToolBarTextEditor
        :quill="quill"
        class="jodit"
        :formatting="activeStyle"
        :selectDown="true"
      />
      <div
        id="joditDescription"
        class="ub-new-message-body__message"
      >
      </div>
      <transition name="fade-el">
        <div
          v-if="filesArray.length"
          class="ub-description__files"
        >
          <div
            v-for="(file, index) in filesArray"
            :key="file._id"
            class="ub-description__file"
          >
            <i class="ub-icon-file-1 ub-description__file-icon"></i>
            <a
              :href="file.url"
              class="ub-description__file-link"
            >{{ file.originalName }}</a>
            <i
              class="ub-icon-close-circle ub-description__file-icon_close"
              @click="deleteFile(index)"
            ></i>
          </div>
        </div>
      </transition>
    </div>
    <div class="ub-description__footer">
      <div class="ub-description__footer-item">
        <Upload
          class="ub-description__footer-icon"
          :popupDown="false"
          :multiple="true"
          :upload="true"
          :iconText="true"
          :item="fileObj"
          :buttonText="true"
          @upload="uploadAllFiles($event)"
        />
      </div>
      <div class="ub-description__footer-item">
        <button
          class="ub-btn ub-btn_cancel"
          @click="closeContentDescription"
        >
          {{ $t('global.cancel') }}
        </button>
      </div>
      <div class="ub-description__footer-item">
        <button
          class="ub-btn ub-btn_primary"
          :class="{'ub-btn_disabled': quillValueEmpty}"
          @click="!editStatus ? addDescription() : changeDescription()"
        >
          <template v-if="!editStatus">
            {{ $t('buttons.add') }}
          </template>
          <template v-else>
            {{ $t('buttons.save') }}
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ToolBarTextEditor from '@/components/elements/description/toolbarTextEditor/toolbarTextEditor';
import getUniqueId from '@/mixins/uniqueId';
import getFormatting from '@/mixins/getFormatting';
import { Jodit } from 'jodit';
import joditConfig from '@/constants/joditConfigOrder';
import { findIndex } from 'lodash';
import Upload from '@/components/Upload';
import filesApi from '@/api/files/filesApi';
import staffDescriptionApi from '@/api/staff-description/staffDescriptionApi';
import loader from '@/components/loader';

export default {
  name: 'UBContentDescription',
  components: {
    ToolBarTextEditor,
    Upload,
    loader,
  },
  mixins: [
    getFormatting,
  ],
  props: {
    descriptionArray: {
      type: Array,
      default: () => [],
    },
    row: {
      type: Object,
      default: () => {},
    },
    editStatus: {
      type: Boolean,
      default: false,
    },
    currentDescription: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      quill: '',
      activeStyle: {},
      fileObj: {},
      contentDescription: '',
      descriptionId: JSON.stringify(getUniqueId()),
      loader: false,
      filesArray: [],
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    quillValueEmpty() {
      return this.quill.value === '<div><br></div>' || this.quill.value === '';
    },
  },
  watch: {
    contentDescription(newVal) {
      this.quill.value = newVal;
    },
  },
  mounted() {
    if (Object.keys(this.currentDescription).length) {
      this.contentDescription = this.currentDescription.text;
      this.filesArray = this.currentDescription.files;
    } else {
      this.currentDescription.files = [];
    }
    this.quill = new Jodit('#joditDescription', joditConfig);
    this.quill.events.on('click', (e) => {
      this.getStyles(e);
    });
    this.quill.events.on('blur', () => {
      this.joditOnBlur();
    });
  },
  methods: {
    deleteFile(index) {
      this.filesArray.splice(index, 1);
    },
    setFileData(file) {
      const fileData = new FormData();
      fileData.set('fieldname', 'uploadFile');
      fileData.set('label', file.name || '');
      fileData.set('file', file);
      return fileData;
    },
    uploadFiles(fileData) {
      this.loader = true;
      filesApi.createFile(fileData)
        .then((resp) => {
          this.loader = false;
          this.filesArray.push(resp.data);
        })
        .catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    uploadAllFiles(item, e, index) {
      item.forEach((el) => {
        const file = {
          _id: getUniqueId(),
          originalName: el.name,
          url: '',
          uploadFile: true,
        };
        const fileFull = this.setFileData(el);
        this.uploadFiles(fileFull, file, this.currentDescription.id, index);
      });
    },
    changeDescription() {
      const currentIndex = findIndex(this.descriptionArray, (
        (el) => el._id === this.currentDescription._id));
      this.currentDescription.text = this.quill.value;
      this.descriptionArray.splice(currentIndex, 1, this.currentDescription);
      const { _id } = this.currentDescription;
      const arr = [];
      this.filesArray.forEach((el) => {
        arr.push(el._id);
      });
      const description = {
        text: this.currentDescription.text,
        files: arr,
      };
      this.updateRequest(_id, description);
      this.$emit('closeDescription');
    },
    addDescription() {
      this.currentDescription.text = this.contentDescription;
      const arr = [];
      this.filesArray.forEach((el) => {
        arr.push(el._id);
      });
      const { _id } = this.userInfo;
      const user = _id;
      const id = this.row._id;
      staffDescriptionApi.addStaffDescription({
        order: id,
        text: this.contentDescription,
        user,
        files: arr,
      })
        .then((resp) => {
          this.loader = false;
          this.descriptionArray.push(resp.data);
        })
        .catch((err) => {
          this.loader = false;
          console.error(err);
        });
      this.closeContentDescription();
    },
    updateRequest(id, description) {
      this.loader = true;
      staffDescriptionApi.updateStaffDescription({ id, description })
        .then((resp) => {
          this.loader = false;
          this.$emit('update:descriptionArray', resp.data.staffDescription);
        })
        .catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    closeContentDescription() {
      this.$emit('closeDescription');
    },
    getStyles(e) {
      if (e) {
        this.activeStyle = this.getFormatting(e.path);
      }
    },
    joditOnBlur() {
      this.contentDescription = this.quill.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/variables";

.ub-description {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: $color-white;
  cursor: pointer;
  box-shadow: 0 0 8px rgba($color-black, .08);
  border: 1px dashed $color-dodger-blue;

  &__files {
    padding: 20px;
    position: relative;
    width: 100%;
    cursor: default;

    &:before {
      position: absolute;
      content: '';
      width: 95%;
      height: 1px;
      background: $color-gallery;
      top: 2px;
    }
  }

  &__file {
    margin-bottom: 5px;
    align-items: center;
    display: flex;

    &-link {
      font-size: $font-size-landing-small;
      text-decoration: none;
      color: $color-black;
    }

    &-icon {
      color: $color-dodger-blue;
      font-size: $font-size-icon-xd;
      position: relative;
      left: -6px;

      &_close {
        color: $color-silver-chalice;
        font-size: $font-size-dd;
        cursor: pointer;
        padding-top: 2px;
      }
    }
  }

  &__footer {
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-icon {
      position: relative;
      color: $color-dodger-blue;
    }

    .ub-content-description__footer-item + .ub-content-description__footer-item {
      margin-left: 5px;
    }
  }
}
</style>
