var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ub-table",class:_vm.customClass},[(_vm.columns && _vm.columns.length > 0 && _vm.showTable)?_c('div',{staticClass:"ub-table-row ub-table-row_header",class:{
      'ub-table-row_header_hide-border': _vm.hoverIndex === 0
    },style:(_vm.columnsGrid)},_vm._l((_vm.columns),function(column){return _c('div',{key:column.id,staticClass:"ub-table-row-cell ub-table-row-cell_header",class:{
        'ub-table-row-cell_pointer': column.pointer
      },style:({
        justifyContent: column.justifyContent || 'flex-start'
      }),on:{"click":function($event){return _vm.clickHeader(column)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(column.title))+" ")]),(column.sortBy)?_c('i',{staticClass:"ub-icon-arrow ub-table-row-cell__icon-sort",class:{
          'ub-table-row-cell__icon-sort_active': _vm.sortBy === column.sortBy,
          'ub-table-row-cell__icon-sort_sorted': _vm.activeSort === column.sortBy
        }}):_vm._e()])}),0):_vm._e(),_c('VuePerfectScrollbar',{ref:"ubScrollContainer",staticClass:"ub-table-body",style:({maxHeight: _vm.maxHeight}),on:{"ps-scroll-y":_vm.closeActiveModal}},_vm._l((_vm.rows),function(row,index){return _c('div',{key:row._id},[_c('div',{ref:row._id,refInFor:true,staticClass:"ub-table-row",class:{
          'ub-table-row_hide-border': _vm.hoverIndex === index + 1,
          'ub-table-row_active': _vm.hoverRow === row._id,
          'ub-table-row_pointer': _vm.clickRow
        },style:(_vm.columnsGrid),on:{"mouseleave":function($event){return _vm.setHover(false, false)},"mouseenter":function($event){return _vm.setHover(row._id, index)},"click":function($event){return _vm.clickByRow(row._id)}}},_vm._l((_vm.columns),function(column){return _c('div',{key:column.id,staticClass:"ub-table-row-cell",style:({
            justifyContent: column.justifyContent || 'flex-start'
          })},[_c(_vm.getComponentByType(column),{tag:"component",attrs:{"row":row,"column":column,"hover":_vm.hoverRow,"countryFlag":_vm.countryFlag,"index":_vm.getIndexWithPage(index),"activeItemModal":_vm.activeItemModal},on:{"update:activeItemModal":function($event){_vm.activeItemModal=$event},"update:active-item-modal":function($event){_vm.activeItemModal=$event},"getCountriesList":_vm.getCountriesList,"showCountriesModal":_vm.showCountriesModal,"showPersonModal":_vm.showPersonModal,"update":_vm.updateItem,"delete":_vm.deleteItem,"edit":_vm.editItem,"copy":_vm.copyItem,"toggleActiveModal":_vm.toggleActiveModal,"toggleSetActiveModal":_vm.toggleSetActiveModal}})],1)}),0),(_vm.showInfo && _vm.activeRow === row._id)?_c('UBContent',{attrs:{"row":row,"rows":_vm.rows},on:{"activeRow":_vm.activeRow,"updateRow":_vm.updateRow,"updateCurrentRow":_vm.updateCurrentRow}}):_vm._e()],1)}),0),_c('transition',{attrs:{"name":"fade-content"}},[(_vm.activeItemModal)?_c('div',{ref:"countryList",staticClass:"ub-table__modal",style:({
        left: (_vm.leftPosition + "px"),
        top: (_vm.topPosition + "px")
      })},[_c('VuePerfectScrollbar',{staticClass:"ub-table__list"},_vm._l((_vm.countriesList),function(item){return _c('div',{key:item._id,staticClass:"ub-table__modal-item"},[_c('div',{staticClass:"ub-table__modal-img"},[_c('img',{attrs:{"src":_vm.getOptionImage(item.image),"alt":""}})]),_c('div',{staticClass:"ub-table__modal-text"},[_vm._v(" "+_vm._s(item.name[_vm.language])+" ")])])}),0)],1):_vm._e()]),_c('transition',{attrs:{"name":"fade-content"}},[(_vm.activeItemSelectedModal)?_c('div',{ref:"personList",staticClass:"ub-table__modal",style:({
        left: (_vm.leftPosition + "px"),
        top: (_vm.topPosition + "px")
      })},[_c('selectWithKeyTemplate',{attrs:{"settings":_vm.personSelectSettings},on:{"changeSelect":function($event){return _vm.changeSelectUser($event)},"infiniteHandler":_vm.infiniteHandler}})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade-content"}},[(_vm.activeItemModal)?_c('div',{ref:"countryList",staticClass:"ub-table__modal",style:({
        left: (_vm.leftPosition + "px"),
        top: (_vm.topPosition + "px")
      })},[_c('VuePerfectScrollbar',{staticClass:"ub-table__list"},_vm._l((_vm.countriesList),function(item){return _c('div',{key:item._id,staticClass:"ub-table__modal-item"},[_c('div',{staticClass:"ub-table__modal-img"},[_c('img',{attrs:{"src":_vm.getOptionImage(item.image),"alt":""}})]),_c('div',{staticClass:"ub-table__modal-text"},[_vm._v(" "+_vm._s(item.name[_vm.language])+" ")])])}),0)],1):_vm._e()]),(_vm.noData.status && (!_vm.rows || _vm.rows.length === 0) && !_vm.loader)?_c('UBTableNoData',{attrs:{"noData":_vm.noData}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }