import * as subType from '@/preset/elements/listSubTypes';

export const TYPE_ELEMENT = 'TYPE_ELEMENT';

export const HEADLINE = {
  name: 'Heading',
  icon: 'heading',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_HEADLINE,
  preview: '',
};

export const RADIO_BUTTON = {
  name: 'Radio Button',
  icon: 'heading',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_RADIO,
  preview: '',
};

export const ACCORDEON = {
  name: 'Accordeon',
  icon: 'heading',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_ACCORDEON,
  preview: '',
};

export const UPLOAD_FILE = {
  name: 'Upload File',
  icon: 'heading',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_UPLOAD_FILE,
  preview: '',
};

export const CHECKBOX_FIELD = {
  name: 'Checbox',
  icon: 'heading',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_CHECKBOX,
  preview: '',
};

export const DESCRIPTION = {
  name: 'Description',
  icon: 'heading',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_DESCRIPTION,
  preview: '',
};
