<template>
  <transition name="fade-el">
    <div
      class="ub-text-editor"
      :class="{'ub-text-editor__shadow': shadow}"
    >
      <button class="ub-text-editor-item ub-text-editor-item_size">
        <span
          class="ub-text-editor-item__icon ub-text-editor-item__icon_font-size"
          :class="{'ub-text-editor-item__icon_active': fontSizeOpen}"
          @click.stop="fontSizeOpen = !fontSizeOpen"
        >
          <transition name="fade-el">
            <FontSizeSelect
              v-if="fontSizeOpen"
              class="ub-text-editor-item__select"
              :class="{'ub-text-editor-item__select_down': selectDown}"
              @set="setFontSize"
              @close="closeFormatOptions('fontSizeOpen')"
            />
          </transition>
          {{ getActiveFontSize() }}
          <i
            class="ub-icon-arrow ub-text-editor-item__arrow"
            :class="{'ub-text-editor-item__arrow_open': fontSizeOpen}"
          />
        </span>
      </button>
      <div class="ub-text-editor-item ub-text-editor-item_font">
        <button
          class="ub-text-editor-item__icon"
          :class="{'ub-text-editor-item__icon_active': getFormatting('bold')}"
          type="button"
          @click="setFont('bold')"
        >
          <i class="ub-icon-bold" />
        </button>
        <button
          class="ub-text-editor-item__icon"
          :class="{'ub-text-editor-item__icon_active': getFormatting('italic')}"
          type="button"
          @click="setFont('italic')"
        >
          <i class="ub-icon-italic" />
        </button>
        <button
          class="ub-text-editor-item__icon"
          :class="{'ub-text-editor-item__icon_active': getFormatting('underline')}"
          type="button"
          @click="setFont('underline')"
        >
          <i class="ub-icon-underline " />
        </button>
      </div>
      <button class="ub-text-editor-item">
        <span
          class="ub-text-editor-item__icon ub-text-editor-item__icon_color-palette"
          :class="{'ub-text-editor-item__icon_active': colorTextOpen}"
          @click.stop="toggleColorText"
        >
          <transition name="fade-el">
            <ColorPalette
              v-if="colorTextOpen"
              class="ub-text-editor-item__select"
              :class="{'ub-text-editor-item__select_down': selectDown}"
              :activeColor="getFormatting('color')"
              @close="closeFormatOptions('colorTextOpen')"
              @set="setColorText"
            />
          </transition>
          <i class="ub-icon-edit-tools" />
          <i
            class="ub-icon-arrow ub-text-editor-item__arrow"
            :class="{'ub-text-editor-item__arrow_open': colorTextOpen}"
          />
        </span>
      </button>
      <button class="ub-text-editor-item">
        <span
          class="ub-text-editor-item__icon ub-text-editor-item__icon_bg ub-text-editor-item__icon_color-palette"
          :class="{'ub-text-editor-item__icon_active': colorBackgroundOpen}"
          @click.stop="toggleColorBackground"
        >
          <transition name="fade-el">
            <ColorPalette
              v-if="colorBackgroundOpen"
              class="ub-text-editor-item__select"
              :class="{'ub-text-editor-item__select_down': selectDown}"
              :activeColor="getFormatting('backgroundColor')"
              @close="closeFormatOptions('colorBackgroundOpen')"
              @set="setColorBackground"
            />
          </transition>
          <i class="ub-icon-highlighter" />
          <i
            class="ub-icon-arrow ub-text-editor-item__arrow"
            :class="{'ub-text-editor-item__arrow_open': colorBackgroundOpen}"
          />
        </span>
      </button>
      <div class="ub-text-editor-item ub-text-editor-item_list">
        <button
          class="ub-text-editor-item__icon"
          :class="{'ub-text-editor-item__icon_active': getFormatting('ordered')}"
          value="bullet"
          @click="setList('ordered')"
        >
          <i
            class="ub-icon-number-list"
            :class="['ub-text-editor-item__icon_list ub-text-editor-item__icon_number']"
          />
        </button>
        <button
          class="ub-text-editor-item__icon"
          :class="{'ub-text-editor-item__icon_active': getFormatting('unordered')}"
          value="ordered"
          @click="setList('unordered')"
        >
          <i
            class="ub-icon-list"
            :class="['ub-text-editor-item__icon_list ub-text-editor-item__icon_ordered']"
          />
        </button>
      </div>
      <div
        class="ub-text-editor-item"
      >
        <span
          class="ub-text-editor-item__icon"
          :class="{'ub-text-editor-item__icon_active': getFormatting('link')}"
          @click.stop="openModalInsetLink"
        >
          <i class="ub-icon-quill-link" />
          <transition name="fade-el">
            <InsetLink
              v-if="insetLinkOpen"
              class="ub-text-editor-item__select"
              :class="{'ub-text-editor-item__select_down': selectDown}"
              @insetLink="setLink"
              @close="insetLinkOpen = false"
            />
          </transition>
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
import ColorPalette from '@/components/elements/description/textEditor/colorPalette/colorPalette';
import FontSizeSelect from '@/components/elements/description/textEditor/fontSizeSelect/fontSizeSelect';
import InsetLink from '@/components/elements/description/textEditor/insetLink/insetLink';
import { get } from 'lodash';

export default {
  name: 'ToolbarTextEditor',
  components: {
    InsetLink,
    ColorPalette,
    FontSizeSelect,
  },
  props: ['quill', 'shadow', 'selectDown', 'formatting'],
  data() {
    return {
      fontFamily: 'ubuntu',
      fontSize: 14,
      lastSelection: {},
      colorTextOpen: false,
      colorBackgroundOpen: false,
      fontSizeOpen: false,
      insetLinkOpen: false,
    };
  },
  methods: {
    toggleColorBackground() {
      this.colorTextOpen = false;
      this.colorBackgroundOpen = !this.colorBackgroundOpen;
    },
    toggleColorText() {
      this.colorBackgroundOpen = false;
      this.colorTextOpen = !this.colorTextOpen;
    },
    forEachChild(node, valueStyle, typeStyle) {
      if (node.hasChildNodes) {
        node.childNodes.forEach((item) => {
          this.forEachChild(item, valueStyle, typeStyle);
        });
      }
      if (!node) return;
      if (node.style) {
        node.style[typeStyle] = valueStyle;
      }
    },
    replaceStyles(regex, style, valueStyle, typeStyle) {
      const { value } = this.quill;
      if (!regex.test(value)) return;
      this.quill.selection.eachSelection((node) => {
        this.forEachChild(node, valueStyle, typeStyle);
      });
    },
    getFormatting(props) {
      return get(this.formatting, props, false);
    },
    alignText(position) {
      this.quill.execCommand(position);
    },
    setList(list) {
      const command = list === 'ordered' ? 'insertOrderedList' : 'insertUnorderedList';
      this.quill.execCommand(command);
    },
    setFont(command) {
      this.quill.execCommand(command);
    },
    setColorText(color) {
      this.quill.execCommand('forecolor', true, color);
    },
    setColorBackground(color) {
      this.quill.execCommand('background', true, color);
    },
    setFontSize(size) {
      const style = `font-size: ${size}px;`;
      const regex = /font-size:.+?;/gmi;
      this.replaceStyles(regex, style, `${size}px`, 'fontSize');
      this.quill.execCommand('fontsize', true, size);
      this.fontSize = size;
    },
    getActiveFontSize() {
      const fontSize = get(this.formatting, 'fontSize');
      if (fontSize) {
        const number = parseInt(fontSize, 10);
        this.fontSize = Number.isNaN(number) || !number ? 16 : number;
      }
      return this.fontSize;
    },
    closeFormatOptions(target) {
      switch (target) {
      case 'colorTextOpen':
        this.colorTextOpen = false;
        break;
      case 'colorBackgroundOpen':
        this.colorBackgroundOpen = false;
        break;
      case 'fontSizeOpen':
        this.fontSizeOpen = false;
        break;
      default:
        break;
      }
    },
    openModalInsetLink() {
      const { html } = this.quill.selection;
      const [elem] = this.quill.selection.save();
      this.lastSelection = {
        ...elem,
        html,
      };
      this.insetLinkOpen = !this.insetLinkOpen;
    },
    setLink(link) {
      const { html, startId, endId } = this.lastSelection;
      const range = new Range();
      const startNode = document.getElementById(`${startId}`);
      const endNode = document.getElementById(`${endId}`);
      if (startNode && endNode) {
        range.setStartAfter(startNode);
        range.setEndBefore(endNode);
        link.innerHTML = html;
        this.quill.selection.setCursorBefore(startNode);
        this.quill.selection.insertHTML(link);
        range.extractContents();
        this.quill.selection.removeMarkers();
      }
      this.insetLinkOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../../sass/variables";

  .ub-text-editor {
    position: relative;
    right: 0;
    display: inline-flex;
    padding: 7px 0;
    background: $color-white;
    border-top-right-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    height: 44px;
    width: 100%;
    border: 1px solid $color-gallery;

    &__shadow {
      margin: auto;
      padding: 7px 7px;
      box-shadow: 0 0 10px rgba($color-black, 0.15);
    }

    &-item {
      padding: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $font-size-ml;
      border-right: 1px solid $color-gallery;
      cursor: pointer;

      &_size {
        width: 48px;
      }

      &_font {
        width: 95px;
      }

      &_list {
        width: 80px;

        button {
          min-width: 28px;
          max-width: 28px;
          width: 28px;
        }
      }

      &-upload {

        &__input {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }
      }

      &__select {
        position: absolute;
        top: 25px;
        left: 0;
        padding: 4px;
        border-radius: $borderRadius;
        background-color: $color-white;
        box-shadow: 0 0 20px rgba($color-black, 0.25);
        z-index: 6;

        &_font-family {
          overflow: hidden;
        }

        &.ub-inset-link {
          padding: 16px;
        }

        &_down {
          top: 25px;
          bottom: auto;
        }
      }

      &__icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
        max-width: 28px;
        color: $color-silver-chalice;
        cursor: pointer;
        border-radius: $borderRadius;
        margin: 0 1px;
        padding:0 5px;
        transition: background-color .15s ease-in, color .15s ease-in;

        &:last-child {
          margin-right: 3px;
        }

        i {

          &:before {
            margin: 0;
          }
        }

        &_font-family {
          padding: 0 12px;
          justify-content: space-between;
          width: 130px;
          max-width: 140px;
        }

        &_color-palette {
          font-size: $font-size-lg;
          max-width: 39px;
          min-width: 39px;
          margin-left: 8px;
        }

        &_line-height {
          font-size: $font-size-lg;
        }

        &_font-size {
          max-width: 37px;
          width: 37px;
          padding: 0;
        }

        &_list {
          font-size: $font-size-h2;
        }

        &_active,

        &:hover {
          color: $color-cornflower-blue;
          background-color: rgba($color-dodger-blue, .08);
        }

        &.ub-text-editor-item__icon_font-size {
          width: 36px;
          font: $font-size-md $font-global-medium;
        }

        &_bg {
          margin-left: 10px;
        }

        &_ordered {
          font-size: $font-size-md;
          position: relative;
          top: 0;
        }

        &_number {
          font-size: $font-size-lg;
          margin-left: -3px
        }
      }

      &__arrow {
        font-size: $font-size-icon;
        margin-left: 5px;
        transition: .2s;

        &_color-palette {
          margin-left: 5px;
        }

        &_open {
          transform: rotate(180deg);
        }
      }
    }
  }
</style>
