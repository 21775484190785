<template>
  <div
    class="orders-list"
  >
    <loader v-if="loader" />
    <div class="orders-list__title">
      {{ $t('ordersPage.orders') }}
    </div>
    <UBTable
      v-if="ordersList.length"
      :columns="columns"
      :rows="ordersList"
      :limit="queryParams.perPage"
      :page="queryParams.page"
      :activeRow.sync="activeRow"
      :noData="noData"
      :totalPages="fullInfo.totalPages"
      :maxHeight="'calc(100vh - 55px)'"
      :loader.sync="loader"
      :clickRow="true"
      :showInfo="true"
      :customClass="'ub-table_margin ub-table_sticky'"
      @clickByRow="setActiveRow"
      @updateRow="updateRow"
    />
    <UBPagination
      v-show="ordersList.length > 0"
      :data="fullInfo"
      :getQuery="getRequests"
      :limit="queryParams.perPage"
      :sticky="true"
    />
  </div>
</template>

<script>
import ordersApi from '@/api/orders/ordersApi';
import UBTable from '@/components/UBTable/UBTable';
import UBPagination from '@/components/UBTable/UBPagination/UBPagination';
import clientsProfileSettings from '@/constants/tablesSettings/clientsProfileSettings';
import queryString from 'query-string';
import moment from 'moment';
import loader from '@/components/loader';
import {
  MANAGER, OWNER, CEO, DEPARTMENT_HEAD,
} from '@/constants/roleType';
import { statusColorList, orderStatus } from '@/constants/orderStatus';
import { findIndex } from 'lodash';

export default {
  name: 'ClientOrdersList',
  components: {
    UBTable,
    UBPagination,
    loader,
  },
  props: {
    clientId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      conditionShowProgress: [
        orderStatus.ORDER_CONFIRMED_AND_PAYED,
      ],
      ordersList: [],
      activeRow: -1,
      loader: false,
      fullInfo: {},
      columns: clientsProfileSettings,
      queryParams: {
        page: 1,
        perPage: 20,
        filter: '',
      },
      noData: {
        status: false,
        title: this.$t('ordersList.title'),
      },
    };
  },
  computed: {
    isAdmin() {
      const { role } = this.userInfo;
      return role === OWNER
          || role === CEO
          || role === DEPARTMENT_HEAD;
    },
    isManager() {
      const { role } = this.userInfo;
      return role === MANAGER;
    },
    allCountriesList: {
      get() {
        return this.$store.getters.allCountriesList;
      },
      set(data) {
        this.$store.dispatch('setAllCountriesList', data);
      },
    },
    language() {
      return this.$i18n.locale;
    },
    userInfo: {
      get() {
        return this.$store.getters.userInfo;
      },
      set(data) {
        this.$store.dispatch('setUserInfo', data);
      },
    },
  },
  async beforeMount() {
    const { location } = window;
    const parsed = queryString.parse(location.search);
    const {
      page = this.queryParams.page,
      perPage = this.queryParams.perPage,
      filter = this.queryParams.filter,
      orderId,
    } = parsed;
    this.activeRow = orderId || -1;
    this.queryParams = {
      page: +page < 1 ? 1 : +page,
      perPage: +perPage < 1 ? 1 : +perPage,
      filter,
      orderId,
    };
    const queryParams = {
      ...parsed,
      ...this.queryParams,
    };
    if (orderId) {
      this.loader = true;
      await ordersApi.getOrderById(orderId)
        .then((resp) => {
          this.pushToOrdersList(resp.data);
        })
        .catch(() => {
          this.loader = false;
          this.$router.push({
            name: 'page404',
            query: {},
          });
        });
    }
    await this.getRequests(queryParams, orderId);
  },
  methods: {
    setActiveRow(id) {
      this.activeRow = this.activeRow === id ? -1 : id;
      const { query } = this.$route;
      this.$router.push({
        query: {
          ...query,
          orderId: this.activeRow !== -1 ? this.activeRow : undefined,
        },
      });
    },
    getPaginationData({ totalDocs, page, perPage }) {
      const totalPages = Math.ceil(totalDocs / perPage);
      const nextPage = totalPages > page ? page + 1 : undefined;
      const prevPage = page !== 1 ? page - 1 : undefined;
      this.fullInfo = {
        totalPages,
        page,
        nextPage,
        prevPage,
        totalDocs,
      };
    },
    async getRequests(queryParams, id) {
      const {
        page = this.queryParams.page,
        perPage = this.queryParams.perPage,
        filter = this.queryParams.filter,
      } = queryParams;
      this.queryParams = {
        page,
        perPage,
        filter,
      };
      const filterQuery = JSON.stringify({ client: this.clientId });
      this.loader = true;
      await ordersApi.getAllOrders({
        page, perPage, filter: filterQuery,
      })
        .then((resp) => {
          this.loader = false;
          const { totalDocs, resource } = resp.data;
          this.noData = {
            ...this.noData,
            status: totalDocs === 0,
          };
          this.getPaginationData({ totalDocs, page, perPage });
          if (!id) {
            this.ordersList = [];
          }
          resource.forEach((order) => {
            if (id) {
              const currentOrder = this.ordersList.find((item) => item._id === order._id);
              if (!currentOrder) {
                this.pushToOrdersList(order);
              }
            } else {
              this.pushToOrdersList(order);
            }
          });
        }).catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    updateRow(order) {
      this.pushToOrdersList(order, 'update');
    },
    pushToOrdersList(order, update) {
      const {
        partner = {},
        projectManager = {},
        _id: id,
        partnerAssignmentConfirmation,
        status,
      } = order;
      const { _id: partnerId } = partner;
      const { code = '' } = status;
      const { _id: projectManagerId } = projectManager;
      const partnerFullName = partnerId ? `${partner.partnerCompanyName || ''}` : '';
      const projectManagerFullName = projectManagerId ? `${projectManager.firstName || ''}
             ${projectManager.lastName || ''}` : '';
      const projectManagerAvatar = projectManagerId
        // eslint-disable-next-line global-require
        ? projectManager.userAvatarUrl || require('@/assets/images/placeholder/avatar.svg')
        : undefined;
      const partnerAvatar = partnerId
        // eslint-disable-next-line global-require
        ? partner.userAvatarUrl || require('@/assets/images/placeholder/avatar.svg')
        : undefined;
      const rejectedStatus = {
        code,
        client: {
          en: this.$t('statuses.inProgress'),
          ru: this.$t('statuses.inProgress'),
        },
        partner: {
          en: this.$t('statuses.cancelled'),
          ru: this.$t('statuses.cancelled'),
        },
        manager: {
          en: this.$t('statuses.assignPerformer'),
          ru: this.$t('statuses.assignPerformer'),
        },
        color: statusColorList[order.status.code],
      };
      const defaultStatus = {
        ...order.status,
        color: statusColorList[order.status.code],
      };
      const isRejected = partnerAssignmentConfirmation === 'rejected';
      const changedStatus = isRejected ? rejectedStatus : defaultStatus;
      const currentOrder = {
        ...order,
        createdAt: moment(order.createdAt).locale(this.language).format('MMMM DD, YYYY'),
        partner: !isRejected && partnerId ? {
          ...partner,
          fullName: partnerFullName,
          userAvatarUrl: partnerAvatar,
        } : undefined,
        projectManager: {
          ...projectManager,
          fullName: projectManagerFullName,
          userAvatarUrl: projectManagerAvatar,
        },
        status: changedStatus,
        country: {
          ...order.country,
          image: this.getOptionImage(order.country.code),
        },
      };
      if (!update) {
        this.ordersList.push(currentOrder);
      } else {
        const currentRow = findIndex(this.ordersList, ((row) => row._id === id));
        this.ordersList.splice(currentRow, 1, currentOrder);
      }
      this.ordersList.forEach((item) => {
        const { status: { code } } = item;
        if ((this.conditionShowProgress.includes(item.status.code))
            && (!item.projectExecutor)) {
          if (this.isAdmin || this.isManager) {
            if (code !== orderStatus.ORDER_CANCELLED) {
              item.status.manager[this.language] = this.$t('statuses.assignPerformer');
            }
          }
        }
        if (code === orderStatus.ORDER_CONFIRMED_AND_PAYED && Object.keys(partner).length
            && Object.keys(projectManager).length) {
          item.status.client[this.language] = this.$t('statuses.partnerAppointed');
        }
        if (code === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
          item.status.client[this.language] = this.$t('statuses.assignPerformer');
        }
        if (code === orderStatus.ORDER_CONFIRMED_AND_PAYED
            && item.projectExecutor === 'projectManager') {
          item.status.manager[this.language] = this.$t('statuses.inProgressUB');
        }
        if (this.isAdmin || this.isManager) {
          const { partner = {}, projectManager, partnerAssignmentConfirmation } = item;
          const { _id } = partner;
          if (_id && projectManager && partnerAssignmentConfirmation === 'waitForResponse'
              && code === orderStatus.ORDER_CONFIRMED_AND_PAYED) {
            item.status.manager[this.language] = this.$t('statuses.partnerAppointed');
          }
        }
      });
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/country/${image}.svg`);
        /* eslint-enable */
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.orders-list {
  margin-left: 20px;

  &__title {
    font: $font-size-xlg $font-global-medium;
    margin: 14px 0;
  }
}
</style>
