import { TYPE_ELEMENT } from '@/preset/listElements';
import { SUB_TYPE_RADIO } from '@/preset/elements/listSubTypes';
import getUniqueId from '@/mixins/uniqueId';

const NEW_RADIO_BUTTON = () => ({
  fieldId: getUniqueId(),
  type: TYPE_ELEMENT,
  sub: SUB_TYPE_RADIO,
  value: '',
  placeholder: {
    value: {
      ru: 'Radiobutton',
      en: 'Radiobutton',
    },
  },
  checked: false,
  groupRadio: '',
  parentId: [],
});

export default NEW_RADIO_BUTTON;
