import { TYPE_ELEMENT } from '@/preset/listElements';
import { SUB_TYPE_UPLOAD_FILE } from '@/preset/elements/listSubTypes';
import getUniqueId from '@/mixins/uniqueId';

const NEW_UPLOAD_FILE = () => ({
  fieldId: getUniqueId(),
  type: TYPE_ELEMENT,
  sub: SUB_TYPE_UPLOAD_FILE,
  fileInfo: {},
  parentId: [],
});

export default NEW_UPLOAD_FILE;
