<template>
  <div class="profile-client">
    <loader v-if="loader" />
    <template v-else>
      <template>
        <clientProfile
          :currentUser="currentUser"
          :clientId="clientId"
          :queryParams.sync="queryParams"
          @showEditModal="showEditModal"
        />
        <portal to="modalProfile">
          <transition name="fade-el">
            <clientProfileModal
              v-if="isVisibleEditProfileModal"
              :currentUser="currentUser"
              @closeModal="closeModal"
              @updateClientProfile="updateClientProfile"
            />
          </transition>
        </portal>
      </template>
      <transition
        name="fade-content"
        mode="out-in"
        appear
      >
        <ClientOrdersListProfile
          :clientId="clientId"
        />
      </transition>
    </template>
  </div>
</template>

<script>

import userApi from '@/api/user/userApi';
import loader from '@/components/loader';
import ClientOrdersListProfile from '@/components/ordersList/clientOrdersListProfile';
import clientProfile from '@/components/profile/clientProfile';
import clientProfileModal from '@/components/modals/clientProfileModal';
import { find } from 'lodash';
import queryString from 'query-string';

export default {
  name: 'Client',
  components: {
    loader,
    ClientOrdersListProfile,
    clientProfile,
    clientProfileModal,
  },
  data() {
    return {
      isEdit: false,
      currentUser: {},
      loader: false,
      toggleIconAction: false,
      isVisibleEditProfileModal: false,
      queryParams: {
        page: 1,
        perPage: 20,
        tab: '',
        orderId: '',
      },
    };
  },
  computed: {
    clientId() {
      return this.currentUser._id;
    },
    defaultCountries() {
      return this.$store.getters.defaultCountries;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  beforeMount() {
    this.loader = true;
    const { params: { id = '' } = {} } = this.$route;
    const { location } = window;
    const parsed = queryString.parse(location.search);
    const {
      page = this.queryParams.page,
      perPage = this.queryParams.perPage,
      orderId = this.queryParams.orderId,
      tab = this.queryParams.tab,
    } = parsed;
    this.queryParams = {
      page: +page < 1 ? 1 : +page,
      perPage: +perPage < 1 ? 1 : +perPage,
      orderId,
      tab,
    };
    if (id) {
      userApi.getUserById(id)
        .then((resp) => {
          this.loader = false;
          this.currentUser = resp.data;
          const { clientCountry } = resp.data;
          const countryInfo = find(this.defaultCountries, (country) => country.code === clientCountry);
          this.currentUser.country = countryInfo;
        })
        .catch(() => {
          this.loader = false;
          this.$router.push({
            name: 'page404',
            query: {},
          });
        });
    }
  },
  methods: {
    showEditModal(data) {
      this.isVisibleEditProfileModal = data;
    },
    closeModal() {
      this.isVisibleEditProfileModal = false;
    },
    updateClientProfile(data) {
      this.currentUser = data;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../sass/variables";

.profile-client {
  display: flex;
  position: relative;
  z-index: 1;
}

</style>
