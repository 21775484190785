import { findIndex } from 'lodash';

export default {
  methods: {
    getFormatting(path) {
      const index = findIndex(path, (node) => {
        if (node.classList) {
          return node.classList.contains('jodit');
        }
        return false;
      });
      if (!path || index === -1) return false;
      const arrayNodes = path.splice(0, index);
      const allStyles = {};
      arrayNodes.forEach((node) => {
        this.setStyles(node, allStyles);
        this.setTags(node, allStyles);
      });
      return allStyles;
    },
    setStyles(node, allStyles) {
      const stylesChecked = ['fontSize', 'backgroundColor', 'color'];
      stylesChecked.forEach((styleName) => {
        if (!allStyles[styleName]) {
          const styles = node.style || {};
          allStyles[styleName] = styles[styleName] || '';
        }
      });
    },
    setTags(node, allStyles) {
      const tagNamesChecked = [
        { tagName: 'STRONG', key: 'bold' },
        { tagName: 'EM', key: 'italic' },
        { tagName: 'U', key: 'underline' },
        { tagName: 'UL', key: 'unordered' },
        { tagName: 'OL', key: 'ordered' },
        { tagName: 'A', key: 'link' },
      ];
      tagNamesChecked.forEach((tags) => {
        const checkedTag = node.tagName === tags.tagName;
        if (!allStyles[tags.key] && checkedTag) allStyles[tags.key] = checkedTag;
      });
    },
  },
};
