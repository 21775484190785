<template>
  <div
    class="ub-table-date"
    :class="['ub-table-date_' + highlightClassName]"
  >
    {{ value }}
  </div>
</template>

<script>
import { get } from 'lodash';
import moment from 'moment';

export default {
  name: 'UBDateCell',
  props: {
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
    index: {
      type: Number,
      default: () => -1,
    },
  },
  computed: {
    value() {
      const date = get(this.row, this.column.prop, '');
      return this.formatDate(date);
    },
    highlightClassName() {
      if (!this.column.highlightPast) return '';
      const date = get(this.row, this.column.prop, '');
      const today = moment().isSame(date, 'day');
      const isAfter = moment().isAfter(date, 'day');
      if (today) {
        return 'today';
      }
      if (isAfter) {
        return 'past';
      }
      return null;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    formatDate(date) {
      const momentDate = moment(date).locale(this.language);
      const isValid = momentDate.isValid();
      return isValid ? momentDate.format('DD, MMM YYYY') : '';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-table-date {
    display: inline-block;
    padding: 7px 8px;
    margin-left: -8px;
    border-radius: $borderRadiusMedium;
    font-weight: normal;
    font: $font-size-h3 $font-global;
    line-height: 16px;

    &_today {
      color: $color-alabaster;
      background: rgba($color-alabaster, 0.2)
    }

    &_past {
      color: $color-alabaster;
      background: rgba($color-alabaster, .2)
    }
  }

</style>
