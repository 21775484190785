var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdownMenu),expression:"closeDropdownMenu"}],ref:"ubSelect",staticClass:"ub-select",attrs:{"tabindex":"0"},on:{"mouseleave":_vm.closeDropdownMenu,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDropdownMenu($event)}}},[_c('label',{staticClass:"ub-select-header",class:{'ub-select-header_error': _vm.error},on:{"click":function($event){$event.stopPropagation();_vm.dropdownOpen = !_vm.dropdownOpen}}},[(_vm.selectedText)?_c('div',{staticClass:"ub-select-header__selected"},[_vm._v(" "+_vm._s(_vm.selectedText)+" ")]):(_vm.placeholder)?_c('div',{staticClass:"ub-select-header__placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),(_vm.clearable && _vm.selectedText)?_c('i',{staticClass:"ub-icon-cross ub-select-header__cross",on:{"click":function($event){$event.stopPropagation();return _vm.setValue({})}}}):_vm._e(),_c('i',{staticClass:"ub-icon-arrow ub-select-header__arrow",class:{'ub-select-header__arrow_open': _vm.dropdownOpen}}),(_vm.error)?_c('span',{staticClass:"ub-error-text ub-select-header__error-text"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.searchable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],ref:"ubSelectInput",staticClass:"ub-select-header__selected__input ub-input",class:{
        'ub-select-header__selected__input_active': _vm.dropdownOpen && !_vm.searchValue,
        'ub-select-header__selected__input_introduced': _vm.searchValue
      },attrs:{"type":"text"},domProps:{"value":(_vm.searchValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchValue=$event.target.value}}}):_vm._e()]),_c('div',{staticClass:"ub-select__indent"}),_c('transition',{attrs:{"name":"ub-slide-select"}},[(_vm.options && _vm.dropdownOpen)?_c('VuePerfectScrollbar',{ref:"ubSelectScroll",staticClass:"ub-select-dropdown",style:(_vm.positionScroll),attrs:{"settings":_vm.scrollSettings},on:{"ps-scroll-down":_vm.scrollEnd}},[_c('div',{staticClass:"ub-select-dropdown__indent ub-select-dropdown__indent_top"}),_vm._l((_vm.filtersOptions),function(elem,index){return _c('div',{key:elem.id,staticClass:"ub-select-dropdown-item",class:{
          'ub-select-dropdown-item_last': index === _vm.options.length - 1,
          'ub-select-dropdown-item_selected': elem.id === _vm.selectedValue.id,
          'ub-select-dropdown-item_with-image': _vm.image
        },on:{"click":function($event){return _vm.setValue(elem)}}},[(_vm.image)?_c('img',{staticClass:"ub-select-dropdown-item__image",attrs:{"src":elem[_vm.image] || _vm.defaultImage,"alt":"'table'"}}):_vm._e(),_vm._v(" "+_vm._s(elem[_vm.label || _vm.defaultLabel])+" ")])}),_c('div',{staticClass:"ub-select-dropdown__indent ub-select-dropdown__indent_bot"})],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }