<template>
  <div class="admin-modal">
    <div class="admin-modal-wrap">
      <div class="admin-modal-wrap-header">
        <div class="admin-modal-wrap-header__title">
          {{ $t('elements.settingsText') }}
        </div>
        <div
          class="admin-modal-wrap-header__close"
          @click="closeModal(false)"
        >
          <i class="ub-icon-close-circle admin-modal-wrap-header__icon"></i>
        </div>
      </div>
      <VuePerfectScrollbar
        class="admin-modal-wrap-content admin-modal-wrap-content__scroll"
      >
        <VuePerfectScrollbar class="admin-modal-wrap-content__horizontal-scroll">
          <draggable
            v-model="cardInfoGroups"
            class="dragAreaTabs"
            :options="{
              filter: '.action-elements',
              animation: 150,
              dropzoneSelector: '.drag-inner-list',
              draggableSelector: '.ub-tab_drag',
              handle: '.ub-tab__drag',
              showDropzoneAreas: true,
              multipleDropzonesItemsDraggingEnabled: true
            }"
            @end="dragEndTab"
          >
            <div
              v-for="(tab, index) in cardInfoGroups"
              :key="tab.id"
              class="ub-tab ub-tab_drag"
              :class="{'ub-tab_main': tab.id === 1}"
            >
              <div class="ub-tab__wrapper">
                <div
                  class="ub-tab__drag"
                  :class="{'ub-tab__drag_disabled': editMode}"
                >
                  <i class="ub-icon-drag"></i>
                </div>
                <div
                  class="ub-tab__elem"
                  :class="{
                    'ub-tab__elem_active': +filterQuery.tab === tab.id,
                    'ub-tab__elem_edit': editMode && +filterQuery.tab === tab.id,
                    'ub-tab__elem_disabled': editMode && +filterQuery.tab !== tab.id
                  }"
                  @click.stop="openActiveTab(tab.id)"
                >
                  <div
                    v-if="editMode && +filterQuery.tab === tab.id"
                    class="ub-tab__input"
                  >
                    <textInput
                      :typeInput="'text'"
                      :textCapitalize="true"
                      :autofocus="true"
                      :value.sync="tab.title"
                      :hideIconError="true"
                      :customIcon="'ub-icon-check-mark-select'"
                      :customClass="'text-field_blue'"
                      :class="{'error': +filterQuery.tab === tab.id && showErrorInput}"
                      :errorStatus="+filterQuery.tab === tab.id && showErrorInput"
                      @handleClickIcon="handleClickIcon(tab.title)"
                    >
                    </textInput>
                    <transition name="fade-content">
                      <div
                        v-show="+filterQuery.tab === tab.id && showErrorInput"
                        class="validation"
                      >
                        {{ validationError[language].inputRequiredAlt }}
                      </div>
                    </transition>
                  </div>
                  <span v-else>{{ tab.title }}</span>
                </div>
                <div
                  v-if="tab.id !== 1"
                  class="action-elements action-elements_tabs"
                  :class="[
                    toggleIconActionTab === tab.id ? 'ub-icon-cross-x' : 'ub-icon-more-vertical',
                    {'action-elements_disabled': editMode}
                  ]"
                  @click.stop="openWindowSettingsTab(tab.id, $event)"
                >
                  <transition
                    name="fade-window"
                    mode="out-in"
                  >
                    <div
                      v-if="toggleIconActionTab === tab.id"
                      v-click-outside="closeChooseWindowTab"
                      class="action-elements-choose"
                      :style="popupPositionChoose"
                      @click.stop
                    >
                      <div class="action-elements-choose-controls">
                        <button
                          class="ub-button action-elements-choose-controls__button"
                          @click="changeTab()"
                        >
                          {{ $t('buttons.change') }}
                        </button>
                      </div>
                      <div class="action-elements-choose-controls">
                        <button
                          class="ub-button action-elements-choose-controls__button"
                          @click="openAreYouShureWindow(tab.id)"
                        >
                          {{ $t('buttons.delete') }}
                        </button>
                      </div>
                    </div>
                  </transition>
                  <transition
                    name="fade-window"
                    appear
                    mode="out-in"
                  >
                    <div
                      v-if="areYouShureWindow === tab.id"
                      v-click-outside="closeAreYouShureWindow"
                      class="ub-toolbar-confirm"
                      :style="popupAreYouShurePosition"
                      @click.stop
                    >
                      <div class="ub-toolbar-confirm__text">
                        {{ $t('table.deleteElementGroup') }}
                        <span
                          v-tooltip.bottom="{content: tab.title, classes: 'tooltip_elems'}"
                        >
                          "{{ tab.title || '' }}"
                        </span>
                        ?
                      </div>
                      <div class="ub-toolbar-confirm-controls">
                        <button
                          class="ub-button ub-toolbar-confirm-controls__button"
                          @click.stop="closeAreYouShureWindow()"
                        >
                          {{ $t('buttons.no') }}
                        </button>
                        <button
                          class="ub-button ub-toolbar-confirm-controls__button"
                          @click.stop="deleteItem(index, tab.id)"
                        >
                          {{ $t('buttons.yes') }}
                        </button>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </draggable>
          <button
            class="ub-tab__new"
            :class="{'ub-tab__new_disabled': editMode}"
            @click="addNewTab()"
          >
            <i class="ub-icon-cross"></i>
          </button>
        </VuePerfectScrollbar>
        <draggable
          v-if="cardInfoList.length"
          v-model="cardInfoList"
          class="dragArea"
          :options="{
            filter: '.action-elements',
            animation: 150,
            dropzoneSelector: '.drag-inner-list',
            draggableSelector: '.field_drag',
            handle: '.field__drag',
            ghostClass: 'ghost-item',
            showDropzoneAreas: true,
            multipleDropzonesItemsDraggingEnabled: true
          }"
          @end="dragEnd"
        >
          <template
            v-for="(elem, index) in cardInfoList"
          >
            <div
              v-if="filterQuery.tab === elem.groupId"
              :key="elem.fieldId"
              class="field field_drag"
              :class="{'field_active': toggleIconAction === elem.fieldId || activeElEdit === elem.fieldId}"
            >
              <div
                v-if="activeElEdit !== elem.fieldId"
                class="field__wrapper"
              >
                <div class="field__drag">
                  <i class="ub-icon-drag"></i>
                </div>
                <div
                  class="field__elem"
                  @click="openEditWindowSettings(elem.fieldId)"
                >
                  <span>{{ elem.label }}</span>
                </div>
                <div
                  class="action-elements"
                  :class="toggleIconAction === elem.fieldId ? 'ub-icon-cross-x' : 'ub-icon-more-vertical'"
                  @click.stop="openWindowSettings(elem.fieldId)"
                >
                  <transition
                    name="fade-el"
                    mode="out-in"
                  >
                    <div
                      v-if="toggleIconAction === elem.fieldId"
                      v-click-outside="closeChooseWindow"
                      class="action-elements-choose"
                      @click.stop
                    >
                      <div class="action-elements-choose-controls">
                        <button
                          class="ub-button action-elements-choose-controls__button"
                          @click="removeEl(elem.fieldId, index)"
                        >
                          {{ $t('buttons.delete') }}
                        </button>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <transition
                name="fade-content"
                mode="out-in"
                appear
              >
                <fieldsSettingsEdit
                  v-if="activeElEdit === elem.fieldId"
                  :currentElem.sync="elem"
                  :cardInfo.sync="cardInfoInside"
                  @closeEditWindow="closeEditWindow"
                />
              </transition>
            </div>
          </template>
        </draggable>
        <transition
          name="fade-content"
          mode="out-in"
          appear
        >
          <fieldsSettings
            v-if="showFieldsSettings"
            :cardInfo.sync="cardInfoInside"
            :filterQueryTab.sync="filterQuery"
            :emptyCard="emptyCard"
            @toggleFieldsSettings="toggleFieldsSettings"
            @changeEmptyCard="changeEmptyCard"
          />
        </transition>
      </VuePerfectScrollbar>
      <div class="admin-modal-wrap-footer">
        <transition
          name="fade-btn"
          mode="out-in"
        >
          <button
            class="button-action"
            :class="{'button-action_disabled': editMode}"
            @click="toggleFieldsSettings(true)"
          >
            <i class="ub-icon-cross"></i>
            <span>{{ $t('elements.addField') }}</span>
          </button>
        </transition>
      </div>
    </div>
    <div
      v-shortkey="['esc']"
      class="admin-modal__overlay"
      @click="closeModal(false)"
      @shortkey="closeModal(false)"
    ></div>
  </div>
</template>

<script>
import fieldsSettings from '@/components/fieldsSettings';
import fieldsSettingsEdit from '@/components/fieldsSettingsEdit';
import textInput from '@/components/fields/textInput';
import ClickOutside from 'vue-click-outside';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import draggable from 'vuedraggable';
import userCardApi from '@/api/user-card/userCardApi';
import getUniqueId from '@/mixins/uniqueId';
import validationErrorMessage from '@/locales/validationErrorMessage';
import { cloneDeep } from 'lodash';

export default {
  name: 'ElementsForClient',
  components: {
    fieldsSettings,
    textInput,
    fieldsSettingsEdit,
    draggable,
    VuePerfectScrollbar,
  },
  directives: {
    ClickOutside,
  },
  props: {
    filterQueryTab: {
      type: Object,
      default: () => {},
    },
    cardInfo: {
      type: Object,
      default: () => {},
    },
    defInfoListInside: {
      type: Array,
      default: () => [],
    },
    emptyCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validationError: validationErrorMessage,
      showErrorInput: false,
      editMode: false,
      popupAreYouShurePosition: {},
      eTargetIcon: {},
      areYouShureWindow: -1,
      popupPositionChoose: {},
      activeElEdit: -1,
      toggleIconAction: -1,
      toggleIconActionTab: -1,
      showFieldsSettings: false,
    };
  },
  computed: {
    defInfoList: {
      get() {
        return this.defInfoListInside;
      },
      set(data) {
        this.$emit('update:defInfoListInside', data);
      },
    },
    cardInfoGroups: {
      get() {
        const { cardData: { groups } } = this.cardInfoInside;
        return groups;
      },
      set(data) {
        this.cardInfoInside.cardData.groups = data;
      },
    },
    language() {
      return this.$i18n.locale;
    },
    filterQuery: {
      get() {
        return this.filterQueryTab;
      },
      set(data) {
        this.$emit('update:filterQueryTab', data);
      },
    },
    cardInfoInside: {
      get() {
        return this.cardInfo;
      },
      set(data) {
        this.$emit('update:cardInfo', data);
      },
    },
    cardInfoList: {
      get() {
        const { cardData: { fields } } = this.cardInfo;
        return fields;
      },
      set(data) {
        this.cardInfo.cardData.fields = data;
      },
    },
  },
  methods: {
    changeEmptyCard(val) {
      this.$emit('changeEmptyCard', val);
    },
    changeTab() {
      this.toggleIconActionTab = -1;
      this.editMode = true;
    },
    fieldAction() {
      if (!this.emptyCard) {
        this.$emit('changeEmptyCard', true);
        userCardApi.createNewField({ ...this.cardInfoInside })
          .then((resp) => {
            const {
              _id: cardId,
            } = resp.data;
            this.cardInfoInside = {
              ...this.cardInfoInside,
              cardId,
            };
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        const { cardId = '' } = this.cardInfoInside;
        const data = {
          ...this.cardInfoInside,
          user: undefined,
          cardId: undefined,
        };
        userCardApi.updateField(cardId, data)
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
      }
    },
    handleClickIcon(title) {
      if (title === '') {
        this.showErrorInput = true;
      } else {
        this.showErrorInput = false;
        this.editMode = false;
        this.fieldAction();
      }
    },
    addNewTab() {
      this.showFieldsSettings = false;
      this.cardInfoGroups.push({
        id: getUniqueId(),
        position: this.cardInfoGroups.length,
        title: '',
      });
      const lastEl = this.cardInfoGroups[this.cardInfoGroups.length - 1];
      const { id } = lastEl;
      this.openActiveTab(id);
      this.editMode = true;
    },
    deleteItem(index, id) {
      const { cardData: { groups } } = this.cardInfoInside;
      groups.splice(index, 1);
      this.cardInfoList = this.cardInfoList.filter((field) => field.groupId !== id);
      const { cardId = '' } = this.cardInfoInside;
      const data = {
        ...this.cardInfoInside,
        user: undefined,
        cardId: undefined,
      };
      this.openActiveTab(this.cardInfoGroups[0].id);
      userCardApi.updateField(cardId, data)
        .then()
        .catch((err) => {
          console.error(err);
        });
    },
    closeAreYouShureWindow() {
      this.areYouShureWindow = -1;
    },
    openAreYouShureWindow(id) {
      this.closeChooseWindowTab();
      this.areYouShureWindow = id;
      const position = this.eTargetIcon.target.getBoundingClientRect();
      if (this.toggleIconActionTab && position) {
        this.popupAreYouShurePosition = {
          position: 'fixed',
          top: `${position.top + 10}px`,
          right: 'auto',
          left: `${position.left - 218}px`,
        };
      } else {
        this.popupAreYouShurePosition = {};
      }
    },
    openActiveTab(tab) {
      this.$emit('openActiveTab', tab);
      this.editMode = false;
    },
    dragEnd() {
      const { cardData: { fields } } = this.cardInfo;
      fields.forEach((item, index) => {
        item.position = index;
      });
      const { cardId = '' } = this.cardInfoInside;
      const data = {
        ...this.cardInfoInside,
        user: undefined,
        cardId: undefined,
      };
      userCardApi.updateField(cardId, data)
        .then()
        .catch((err) => {
          console.error(err);
        });
    },
    dragEndTab() {
      const { cardData: { groups } } = this.cardInfo;
      groups.forEach((item, index) => {
        item.position = index;
      });
      this.fieldAction();
    },
    openEditWindowSettings(id) {
      this.activeElEdit = this.activeElEdit === id ? -1 : id;
      this.showFieldsSettings = false;
    },
    removeEl(id, index) {
      const { cardData: { fields, deletedFields } } = this.cardInfoInside;
      deletedFields.push(id);
      fields.splice(index, 1);
      const { cardId = '' } = this.cardInfoInside;
      const data = {
        ...this.cardInfoInside,
        user: undefined,
        cardId: undefined,
      };
      userCardApi.updateField(cardId, data)
        .then()
        .catch((err) => {
          console.error(err);
        });
    },
    closeChooseWindow() {
      this.toggleIconAction = -1;
    },
    closeChooseWindowTab() {
      this.toggleIconActionTab = -1;
    },
    closeEditWindow() {
      this.activeElEdit = -1;
    },
    openWindowSettings(id) {
      this.toggleIconAction = this.toggleIconAction === id ? -1 : id;
    },
    openWindowSettingsTab(id, e) {
      this.openActiveTab(id);
      this.toggleIconActionTab = this.toggleIconActionTab === id ? -1 : id;
      this.areYouShureWindow = -1;
      this.eTargetIcon = e;
      const position = e.target.getBoundingClientRect();
      if (this.toggleIconActionTab && position) {
        this.popupPositionChoose = {
          position: 'fixed',
          top: `${position.top + 10}px`,
          right: 'auto',
          left: `${position.left - 130}px`,
        };
      } else {
        this.popupPositionChoose = {};
      }
    },
    closeModal(data) {
      this.defInfoList = cloneDeep(this.cardInfoList);
      if (this.cardInfoGroups[this.cardInfoGroups.length - 1].title === '') {
        this.cardInfoGroups.pop();
      }
      this.$emit('toggleElementsForClients', data);
    },
    toggleFieldsSettings(val) {
      this.showFieldsSettings = val;
      this.activeElEdit = -1;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";

  .ghost-item {
    opacity: 0;
  }

  .admin-modal-wrap {
    width: 651px;

    &-footer {
      padding: 15px 18px 20px;
    }

    &-content {
      padding: 0 5px;

      &__scroll {
        padding: 8px 18px 15px 18px;
        max-height: 540px;
      }

      &__horizontal-scroll {
        padding: 15px 37px 25px 14px;
        max-height: 90px;
        min-height: 90px;
        display: flex;
        align-items: center;
      }
    }
  }

  .button-action {
    transition: all .15s ease-in;

    i {
      font-size: $font-size-dd;
    }

    &_disabled {
      pointer-events: none;
      border-color: $color-silver-chalice;
      color: $color-silver-chalice;

      i {
        color: $color-silver-chalice;
      }
    }
  }

  .field, .ub-tab {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;

    &__wrapper {
      max-width: 576px;
      width: 100%;
    }

    &__drag {
      position: absolute;
      cursor: pointer;
      color: $color-dodger-blue;
      left: -9px;
      top: 10px;
      font-size: $font-size-icon-sm;
      transition: color .15s ease-in;

      &_disabled {
        pointer-events: none;
        color: $color-silver-chalice;
      }
    }

    &__elem {
      background: $color-white;
      border: 1px solid $color-gallery;
      border-radius: $borderRadius;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      position: relative;
      cursor: pointer;
      transition: background-color .15s ease-in;

      &:hover {
        background: rgba($color-dodger-blue, .08);
      }

      span {
        font: $font-size-base $font-global-medium;
        color: $color-black;
      }
    }

    &_active {
      z-index: $z-index-biggest !important;

      .field {

        &__elem {
          z-index: -1;

        }
      }
    }
  }

  .field {
    margin-bottom: 12px;
  }

  .dragArea {
    padding-top: 10px;
  }

  .dragAreaTabs {
    display: flex;
    align-items: center;
  }

  .ub-tab {
    flex-direction: row;

    &_main {
      margin-right: -20px;
    }

    &__input {
      min-width: 128px;
      max-width: 128px;
      display: flex;
      align-items: center;
    }

    &__elem {
      transition: border-color .15s ease-in;

      span {
        white-space: nowrap;
      }

      &_active, &:hover {
        border-color: $color-dodger-blue;
        background: $color-white;
      }

      &_edit {
        padding: 0;
        border: none;
      }

      &_disabled {
        pointer-events: none;
      }
    }

    &__new {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 1px dashed $color-dodger-blue;
      border-radius: $borderRadius;
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      cursor: pointer;
      margin-left: 32px;

      i {
        color: $color-dodger-blue;
        font-size: $font-size-xlg;
      }

      &_disabled {
        pointer-events: none;
        border-color: $color-silver-chalice;

        i {
          color: $color-silver-chalice;
        }
      }
    }

    & + .ub-tab {
      margin-left: 48px;
    }

    &__drag {
      left: -18px;
    }

    &__wrapper {
      width: auto;
    }
  }

.action-elements {
  position: absolute;
  top: 8px;
  left: auto;
  right: -12px;
  font-size: $font-size-md;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: $color-dodger-blue;
  cursor: pointer;
  transition: all .15s ease-in;
  z-index: auto;

  &-choose {
    user-select: none;
    position: absolute;
    top: 12px;
    right: 10px;
    left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba($color-black, .1);
    border-radius: $borderRadius;
    background-color: $color-white;
    color: $color-black;
    cursor: default;
    min-width: 143px;
    max-width: 143px;
    padding: 3px 0;
    z-index: -1;

    &-controls {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__button {
        line-height: 1.5;
        min-height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color .15s ease-in;
        padding: 0 12px;
        font: $font-size-md $font-global;

        &:hover {
          background: $color-alabaster;
        }
      }
    }
  }

  &:before {
    position: relative;
    border-color: transparent;
  }

  &:hover {
    background: $color-dodger-blue;
    color: $color-white;
    border-color: transparent;
  }

  &_tabs {
    right: -28px;
    z-index: 1;
  }

  &_disabled {
    pointer-events: none;
    color: $color-silver-chalice;
  }

}

.ub-icon-cross-x {
  background: $color-dodger-blue;
  color: $color-white;

  &:before {
    font-size: $font-size-h6;
  }
}

.ub-toolbar {
  &-confirm {
    position: absolute;
    top: 0;
    right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba($color-black, .1);
    border-radius: $borderRadius;
    background-color: $color-white;
    color: $color-black;
    cursor: default;
    z-index: -1;
    min-width: 232px;
    max-width: 232px;
    padding: 10px 13px 10px 13px;
    min-height: 95px;

    &__text {
      font-size: $font-size-md;
      font-style: normal;
      text-align: center;
      margin-bottom: 12px;
      max-width: 180px;
      line-height: 1.5;

      span {
        display: inline-flex;
        margin-top: 4px;
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-controls {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;

      &__button {
        font-size: $font-size-h3;
        color: $color-dodger-blue;
        font-family: $font-global-medium;
        padding: 0 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;

        & + .ub-toolbar-confirm-controls__button {
          margin-left: 18px;
        }
      }
    }
  }
}

</style>
