<template>
  <div
    v-click-outside="close"
    class="ub-font-size"
  >
    <div
      v-for="size in fontSizeOptions"
      :key="size"
      class="ub-font-size__item"
      @click="setSize(size)"
    >
      {{ size }}
    </div>
  </div>
</template>

<script>
import fontSizes from '@/constants/toolbarEditorOptions/fontSizes';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'FontSizeSelect',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      fontSizeOptions: fontSizes,
    };
  },
  methods: {
    setSize(size) {
      this.$emit('set', size);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../sass/variables";

  .ub-font-size {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    min-width: 37px;
    padding: 6px 0;
    width: 100%;

    &__item {
      text-align: center;
      width: 100%;
      padding: 5px 0!important;
      color: $color-silver-chalice;

      &:hover {
        background-color: rgba($color-dodger-blue, .08);
      }
    }
  }

</style>
