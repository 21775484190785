<template>
  <div
    class="ub-reviews-popup"
    @click.stop
  >
    <VuePerfectScrollbar
      class="ub-reviews-popup__scroll"
    >
      <div class="ub-reviews-popup__text">
        {{ comment }}
      </div>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'UBReviewsPopup',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    comment: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../../../sass/variables";

  .ub-reviews-popup {
    position: fixed;
    background: $color-white;
    box-shadow: 0 0 25px 2px rgba($color-black, .08);
    border-radius: $borderRadius;
    width: 301px;
    padding: 18px;
    min-height: 120px;
    max-height: 120px;
    z-index: 50;
    left: 0;
    top: 0;
    overflow: hidden;

    &__text {
      font: $font-size-h3 $font-global;
      line-height: 1.4;
    }

    &__scroll {
      max-height: 80px;
    }
  }

</style>
