<template>
  <div
    class="ub-accordion-content"
    :class="{'ub-accordion-content_open': !isHidden}"
    :style="{height: !isHidden ? heightContent : 0}"
  >
    <transition name="ub-slide-select">
      <div
        v-if="display"
        ref="ubDescriptionText"
        class="ub-accordion-content__text"
        :class="{'ub-accordion-content__text_hide': isHidden}"
      >
        {{ description }}
      </div>
    </transition>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'UBAccordionContentCell',
  props: {
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      heightContent: 'auto',
      isHidden: true,
    };
  },
  computed: {
    display() {
      return get(this.row, '$accordionContent', false);
    },
    description() {
      return get(this.row, this.column.prop, '');
    },
  },
  watch: {
    display(newVal) {
      if (newVal) {
        this.$nextTick(this.calcHeight);
      } else {
        this.isHidden = true;
      }
    },
  },
  methods: {
    setOpen() {
      this.isHidden = !this.display;
    },
    calcHeight() {
      const node = this.$refs.ubDescriptionText;
      this.heightContent = node ? `${node.offsetHeight}px` : 'auto';
      this.setOpen();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-accordion-content {
    font-family: $font-global;
    transition: 0.25s ease-out;

    &__text {
      word-break: break-word;
      margin-top: 10px;
      font-weight: normal;
      font-size: $font-size-md;
      line-height: 16px;
      padding: 12px;
      background-color: $color-white;
      border-radius: $borderRadius;
      transition: 0.3s;

      &_hide {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
      }
    }
  }

</style>
