<template>
  <VuePerfectScrollbar class="card-client-tabs">
    <div class="card-client-tabs__wrapper">
      <div
        v-for="tab in cardInfoGroups"
        :key="tab.id"
        class="card-client-tabs__item"
        :class="{'card-client-tabs__item_active': +filterQuery.tab === tab.id}"
        @click="openActiveTab(tab.id)"
      >
        <span>{{ tab.title }}</span>
      </div>
    </div>
  </VuePerfectScrollbar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'FieldsTabs',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    cardInfo: {
      type: Object,
      default: () => {},
    },
    filterQueryTab: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    cardInfoGroups: {
      get() {
        const { cardData: { groups } } = this.cardInfoInside;
        return groups;
      },
      set(data) {
        this.cardInfoInside.cardData.groups = data;
      },
    },
    cardInfoInside: {
      get() {
        return this.cardInfo;
      },
      set(data) {
        this.$emit('update:cardInfo', data);
      },
    },
    filterQuery: {
      get() {
        return this.filterQueryTab;
      },
      set(data) {
        this.$emit('update:filterQueryTab', data);
      },
    },
  },
  methods: {
    openActiveTab(tab) {
      this.$emit('openActiveTab', tab);
    },
  },
};
</script>

<style lang="scss">

@import "../sass/variables";
.card-client-tabs {
  &:hover {
    .ps__rail-x {
      opacity: .6;
    }
  }
}

</style>

<style scoped lang="scss">
@import "../sass/variables";

  .card-client-tabs {
    display: flex;
    align-items: center;
    height: 50px;
    padding-bottom: 20px;
    max-width: 470px;
    overflow: hidden;

    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__item {
      font: $font-size-xlg $font-global-medium;
      color: $color-silver-chalice;
      cursor: pointer;
      transition: color .15s ease-in;
      position: relative;
      text-align: center;
      height: 28px;
      margin-right: 28px;

      span {
        white-space: nowrap;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: $color-dodger-blue;
        transition: width .1s ease-in;
      }

      &:hover, &_active {
        color: $color-dodger-blue;

        &:after {
          width: 100%;
        }
      }
    }
  }
</style>
