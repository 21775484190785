import { TYPE_ELEMENT } from '@/preset/listElements';
import { SUB_TYPE_CHECKBOX } from '@/preset/elements/listSubTypes';
import getUniqueId from '@/mixins/uniqueId';

const NEW_CHECKBOX = () => ({
  fieldId: getUniqueId(),
  type: TYPE_ELEMENT,
  sub: SUB_TYPE_CHECKBOX,
  value: '',
  placeholder: {
    value: {
      ru: 'Checkbox',
      en: 'Checkbox',
    },
  },
  checked: false,
  parentId: [],
});

export default NEW_CHECKBOX;
