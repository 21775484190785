export const orderStatus = {
  NEW_ORDER: 'newOrder',
  ORDER_CONFIRMED_NOT_PAYED: 'orderConfirmedNotPayed',
  ORDER_CONFIRMED_AND_PAYED: 'orderConfirmedAndPayed',
  PROJECT_LAUNCHED_BY_MANAGER: 'projectLaunchedByManager',
  PARTNER_FOR_PROJECT_ASSIGNED: 'partnerForProjectAssigned',
  PARTNER_FOR_PROJECT_REJECTED_ASSIGNMENT: 'partnerForProjectRejectedAssignment',
  PARTNER_FOR_PROJECT_ACCEPTED_ASSIGNMENT: 'partnerForProjectAcceptedAssignment',
  DOCUMENTS_REQUESTED: 'documentsRequested',
  DOCUMENTS_RECEIVED: 'documentsReceived',
  ALL_STEPS_COMPLETED: 'allStepsCompleted',
  PROJECT_WITH_FULLY_PAYED_ORDER_ACCEPTED: 'projectWithFullyPayedOrderAccepted',
  LAST_PAYMENT_REQUIRED: 'lastPaymentRequired',
  LAST_PAYMENT_PAYED: 'lastPaymentPayed',
  LAST_PAYMENT_CONFIRMED: 'lastPaymentConfirmed',
  PROJECT_FINISHED: 'projectFinished',
  DOCUMENTS_SENT_AFTER_LAST_PAYMENT: 'documentsSentAfterLastPayment',
  PARTNER_REWARD_PERCENT_CHANGED: 'partnerRewardPercentChanged',
  ORDER_CANCELLED: 'orderCancelled',
  ON: 'ON',
  RD: 'RD',
  PD: 'PD',
  DD: 'DD',
  IP: 'IP',
  CNP: 'CNP',
  PPA: 'PPA',
  // AT: 'AT',
  PR: 'PR',
  SD: 'SD',
  CTD: 'CTD',
  CD: 'CD',
};

export const managerConfirmedNotPayedOrder = 'managerConfirmedNotPayedOrder';
export const managerConfirmedPayedOrder = 'managerConfirmedPayedOrder';
export const orderPayedAfterManagerConfirmed = 'orderPayedAfterManagerConfirmed';
export const requestDocuments = 'requestDocuments';
export const receiveDocuments = 'receiveDocuments';
export const completeAllOrderSteps = 'completeAllOrderSteps';
export const acceptWorkWithFullyPayedOrder = 'acceptWorkWithFullyPayedOrder';
export const acceptedProjectRequireLastPay = 'acceptedProjectRequireLastPay';
export const clientPayedLastPayment = 'clientPayedLastPayment';
export const managerConfirmedLastPayment = 'managerConfirmedLastPayment';
export const managerFinishedProject = 'managerFinishedProject';
export const sendPayedProjectDocuments = 'sendPayedProjectDocuments';
export const notAcceptPartnerWork = 'notAcceptPartnerWork';

// newOrder: {
//   managerConfirmedNotPayedOrder,
//   managerConfirmedNotPayedOrder,
// }

// newOrder: managerConfirmedNotPayedOrder, managerConfirmedPayedOrder),
// orderConfirmedNotPayed(orderPayedAfterManagerConfirmed);
// orderConfirmedAndPayed(managerLaunchedProject, managerAssignedPartner);
// partnerForProjectAssigned(partnerRejectedAssignment, partnerAcceptedAssignment);
// partnerForProjectRejectedAssignment(managerLaunchedProject, managerAssignedPartner);
// partnerForProjectAcceptedAssignment(requestDocuments);
// projectLaunchedByManager(requestDocuments);
// documentsRequested(receiveDocuments);
// documentsReceived(completeAllOrderSteps, acceptPartnerWorkWithFullyPayedOrder, acceptedProjectRequireLastPay);
// allStepsCompleted(notAcceptPartnerWork, acceptPartnerWorkWithFullyPayedOrder, acceptedProjectRequireLastPay);
// projectWithFullyPayedOrderAccepted(managerFinishedProject, sendPayedProjectDocuments);
// lastPaymentRequired(clientPayedLastPayment);
// lastPaymentPayed(managerConfirmedLastPayment);
// lastPaymentConfirmed(managerFinishedProject, sendPayedProjectDocuments);
// documentsSentAfterLastPayment(managerFinishedProject);
// projectFinished(changePartnerRewardPercent);
// partnerRewardPercentChanged(managerFinishedProject);

export const statusColorList = {
  [orderStatus.ON]: 'orange',
  [orderStatus.RD]: 'main',
  [orderStatus.PD]: 'main',
  [orderStatus.DD]: 'main',
  [orderStatus.IP]: 'main',
  [orderStatus.CNP]: 'orange',
  [orderStatus.CTD]: 'green',
  [orderStatus.PR]: 'main',
  [orderStatus.PPA]: 'orange',
  [orderStatus.AT]: 'main',
  [orderStatus.SD]: 'main',
  [orderStatus.CD]: 'green',
};
