<template>
  <div
    class="cell-list"
    :style="{zIndex: zIndexEl ? 1 : -1}"
  >
    <transition
      name="fade-content"
    >
      <component
        :is="listComponent[element.sub]"
        :item.sync="element"
        :stepsField="stepsField"
        :step="step"
        :lang="lang"
        :fieldsError="fieldsError"
        :customClass="customClass"
        :service="service"
      >
      </component>
    </transition>
  </div>
</template>

<script>
import { LIST_COMPONENT } from '@/preset/elements/listElementsToComponent';
import LIST_FUNCTION from '@/preset/elements/listElementsToFunction';

export default {
  name: 'ElementDefault',
  props: {
    lang: {
      type: String,
      default: 'ru',
    },
    customClass: {
      type: String,
      default: '',
    },
    step: {
      type: Object,
      default: () => {},
    },
    element: {
      type: Object,
      default: () => {},
    },
    stepsField: {
      type: Boolean,
      default: false,
    },
    zIndexEl: {
      type: Boolean,
      default: false,
    },
    fieldsError: {
      type: Array,
      default: () => [],
    },
    stepsList: {
      type: Array,
      default: () => [],
    },
    service: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      listComponent: LIST_COMPONENT,
      listFunctions: LIST_FUNCTION,
    };
  },
};
</script>

<style lang="scss">
  @import "../../sass/_variables.scss";

  .cell-list {
    max-width: 780px;
    width: 100%;
    position: relative;
    z-index: -1;
  }
</style>
