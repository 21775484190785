<template>
  <div class="admin-modal">
    <div
      class="admin-modal-wrap"
      @click.stop
    >
      <div class="admin-modal-wrap-header">
        <div class="admin-modal-wrap-header__title">
          {{ $t('global.editReward') }}
        </div>
        <button
          class="admin-modal-wrap-header__close"
          @click="closeModal"
        >
          <i class="ub-icon-close-circle admin-modal-wrap-header__icon"></i>
        </button>
      </div>
      <div class="admin-modal-wrap-content">
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('global.partnerReward') }}
          </div>
          <textInput
            v-validate="'numeric|min_value:1|max_value:100'"
            :typeInput="'numeric'"
            :name="'rewardRate'"
            :autofocus="true"
            :value.sync="row.rewardRate"
            :class="{'error': $validator.errors.has('rewardRate')}"
            :errorStatus="$validator.errors.has('rewardRate')"
          >
          </textInput>
          <transition name="fade-validation">
            <div
              v-if="!errors.has('rewardRate')"
              class="admin-form-field__icon admin-form-field__icon_right"
            >
              <span>%</span>
            </div>
          </transition>
          <transition name="fade-validation">
            <span
              v-if="errors.has('rewardRate')"
              class="validation"
            >
              {{ $t('servicesPage.addServiceModal.validationRewardRate') }}
            </span>
          </transition>
        </div>
      </div>
      <div class="admin-modal-wrap-footer">
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_cancel"
            @click="closeModal"
          >
            {{ $t('global.cancel') }}
          </button>
        </div>
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_primary"
            @click="updateRewardRate"
          >
            {{ $t('buttons.save') }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-shortkey="['esc']"
      class="admin-modal__overlay"
      @click.stop="closeModal"
      @shortkey="closeModal"
    ></div>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';
import ordersApi from '@/api/orders/ordersApi';

export default {
  name: 'AddNewTypeService',
  components: {
    textInput,
  },
  props: {
    row: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      validator: {},
    };
  },
  computed: {
    language: {
      get() {
        return this.languageDef;
      },
      set(data) {
        this.languageDef = data;
      },
    },
  },
  methods: {
    updateRewardRate() {
      const { rewardRate, _id: id } = this.row;
      this.$validator.validateAll({
        rewardRate: +rewardRate,
      }).then((result) => {
        if (result) {
          const order = {
            rewardRate: +rewardRate,
          };
          ordersApi.updateOrderById({ id, order })
            .then(() => {
              this.closeModal();
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },
    closeModal() {
      this.$emit('showReward', false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";

.admin-modal-wrap {
  width: 400px;

  &-header-lang {
    position: relative;
    right: 43px;
  }
}

</style>
