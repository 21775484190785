import getUniqueId from '@/mixins/uniqueId';
import { TYPE_FLAG } from '@/constants/typesAll';
import {
  OWNER, CEO, UB_CLIENT_MANAGER, UB_CLIENT_DEPARTMENT_HEAD,
  UB_CONTACT_MANAGER, UB_CONTACT_DEPARTMENT_HEAD,
  UB_BUSINESS_DEVELOPMENT_MANAGER, UB_BUSINESS_DEVELOPMENT_DEPARTMENT_HEAD, UB_CONTENT_MANAGER, UB_SERVICES_MANAGER,
} from '@/constants/roles';

const NEW_FLAG = () => ({
  fieldId: getUniqueId(),
  type: TYPE_FLAG,
  value: false,
  label: '',
  position: 0,
  groupId: 0,
  roles: [
    OWNER, CEO, UB_CLIENT_MANAGER, UB_CLIENT_DEPARTMENT_HEAD,
    UB_CONTACT_MANAGER, UB_CONTACT_DEPARTMENT_HEAD,
    UB_BUSINESS_DEVELOPMENT_MANAGER, UB_BUSINESS_DEVELOPMENT_DEPARTMENT_HEAD,
    UB_CONTENT_MANAGER, UB_SERVICES_MANAGER,
  ],
  author: '',
});

export default NEW_FLAG;
