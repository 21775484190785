<template>
  <div class="ub-breadcrumb">
    <div
      v-for="(item, index) in items"
      :key="item.name"
      class="ub-breadcrumb__item"
      :class="{'ub-breadcrumb__item_active': index === items.length -1}"
      @click="index !== items.length -1 ? proceedTo(item.route) : false"
    >
      <i
        v-if="arrowIcon && index === 0"
        class="ub-icon-arrow-back ub-breadcrumb__item-icon"
      ></i>
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumbs',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    arrowIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    proceedTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";
@import "../../sass/mixins";

  .ub-breadcrumb {
    display: flex;
    margin-bottom: 16px;
    flex-wrap: wrap;

    &__item {
      color: $color-silver-chalice;
      font: $font-size-landing-small $font-global-medium;
      cursor: pointer;

      &-icon {
        margin-right: 5px;
      }

      @include mobile {
        font-size: $font-size-landing-bread-crums;
      }

      &_active {
        color: $color-dodger-blue;
        cursor: default;
      }
    }

    &__item {
      height: 16px;
      margin-top: 3px;
      transition: color .15s ease-in;

      &:hover {
        color: $color-dodger-blue;
      }

      &:after {
        content: '/';
        margin-right: 6px;
        margin-left: 2px;
        color: $color-silver-chalice;
      }

      &:last-child {

        &:after {
          display: none;
        }
      }
    }
  }
</style>
