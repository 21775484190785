<template>
  <div
    ref="ubSelect"
    v-click-outside="closeDropdownMenu"
    class="ub-select"
    tabindex="0"
    @mouseleave="closeDropdownMenu"
    @keydown.esc="closeDropdownMenu"
  >
    <label
      class="ub-select-header"
      :class="{'ub-select-header_error': error}"
      @click.stop="dropdownOpen = !dropdownOpen"
    >
      <div
        v-if="selectedText"
        class="ub-select-header__selected"
      >
        {{ selectedText }}
      </div>
      <div
        v-else-if="placeholder"
        class="ub-select-header__placeholder"
      >
        {{ placeholder }}
      </div>
      <i
        v-if="clearable && selectedText"
        class="ub-icon-cross ub-select-header__cross"
        @click.stop="setValue({})"
      />
      <i
        class="ub-icon-arrow ub-select-header__arrow"
        :class="{'ub-select-header__arrow_open': dropdownOpen}"
      />
      <span
        v-if="error"
        class="ub-error-text ub-select-header__error-text"
      >
        {{ error }}
      </span>
      <input
        v-if="searchable"
        ref="ubSelectInput"
        v-model="searchValue"
        class="ub-select-header__selected__input ub-input"
        :class="{
          'ub-select-header__selected__input_active': dropdownOpen && !searchValue,
          'ub-select-header__selected__input_introduced': searchValue
        }"
        type="text"
      />
    </label>
    <div class="ub-select__indent" />
    <transition name="ub-slide-select">
      <VuePerfectScrollbar
        v-if="options && dropdownOpen"
        ref="ubSelectScroll"
        class="ub-select-dropdown"
        :style="positionScroll"
        :settings="scrollSettings"
        @ps-scroll-down="scrollEnd"
      >
        <div class="ub-select-dropdown__indent ub-select-dropdown__indent_top" />
        <div
          v-for="(elem, index) in filtersOptions"
          :key="elem.id"
          class="ub-select-dropdown-item"
          :class="{
            'ub-select-dropdown-item_last': index === options.length - 1,
            'ub-select-dropdown-item_selected': elem.id === selectedValue.id,
            'ub-select-dropdown-item_with-image': image
          }"
          @click="setValue(elem)"
        >
          <img
            v-if="image"
            class="ub-select-dropdown-item__image"
            :src="elem[image] || defaultImage"
            alt="'table'"
          >
          {{ elem[label || defaultLabel] }}
        </div>
        <div class="ub-select-dropdown__indent ub-select-dropdown__indent_bot" />
      </VuePerfectScrollbar>
    </transition>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { get, filter } from 'lodash';

export default {
  name: 'UBSelect',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    options: {
      type: [Array, Object],
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Number, String],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    item: {
      type: [Array, Object],
      default: () => [],
    },
    image: {
      type: String,
      default: '',
    },
    defaultImage: {
      type: String,
      default: '',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchableBy: {
      type: String,
      default: '',
    },
    searchableGet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchValue: '',
      dropdownOpen: false,
      defaultLabel: 'name',
      selectedValue: {},
      positionScroll: {},
      scrollSettings: {
        wheelPropagation: false,
      },
    };
  },
  computed: {
    filtersOptions() {
      if (!this.searchable || !this.searchValue) {
        return this.options;
      } if (this.searchValue) {
        const regSearch = new RegExp(this.searchValue, 'gmi');
        const regSort = new RegExp(`^${this.searchValue}`, 'gmi');
        const filterOption = filter(this.options, (item) => regSearch.test(this.getValBySearch(item)));
        return filterOption.sort((a, b) => regSort.test(this.getValBySearch(b)) - regSort.test(this.getValBySearch(a)));
      } return undefined;
    },
    selectedText() {
      return get(this.selectedValue, this.label || this.defaultLabel, '');
    },
  },
  watch: {
    value() {
      this.setValue(this.value);
    },
    searchValue() {
      if (this.searchableGet && typeof this.searchableGet === 'function') this.searchableGet(this.searchValue);
      const scroll = get(this.$refs, 'ubSelectScroll.$el', '');
      if (scroll) scroll.scrollTop = 0;
    },
    dropdownOpen(newVal) {
      const input = this.$refs.ubSelectInput;
      if (newVal && input) {
        this.$nextTick(() => {
          input.focus();
        });
      }
      if (this.dropdownOpen) {
        this.setPositionSelect();
      } else {
        this.setPositionSelectDefault();
      }
    },
  },
  mounted() {
    this.setPositionSelect();
  },
  beforeMount() {
    this.setPositionSelectDefault();
    this.setValue(this.value);
  },
  methods: {
    getValBySearch(item) {
      return get(item, this.searchableBy, '');
    },
    scrollEnd() {
      this.$emit('scrollEnd');
    },
    closeDropdownMenu() {
      this.searchValue = '';
      this.dropdownOpen = false;
    },
    setValue(value) {
      this.searchValue = '';
      this.selectedValue = value || {};
      this.$emit('change', this.selectedValue);
      if (!this.searchable) this.dropdownOpen = false;
    },
    setPositionSelect() {
      const select = this.$refs.ubSelect;
      if (select) {
        const position = select.getBoundingClientRect();
        this.positionScroll = {
          position: 'fixed',
          left: `${position.left}px`,
          top: `${position.top + position.height}px`,
          width: `${position.width}px`,
        };
      }
    },
    setPositionSelectDefault() {
      this.positionScroll = {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 'auto',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-select {
    position: relative;
    font: inherit $font-global;
    line-height: inherit;
    height: 100%;
    width: 100%;
    text-transform: capitalize;

    &-header {
      position: relative;
      display: flex;
      align-items: center;
      border: 1px solid $color-mercury;
      box-sizing: border-box;
      border-radius: $borderRadiusLarge;
      min-height: 33px;
      padding: 7px 42px 7px 8px;
      cursor: pointer;

      &_error {
        border-color: $color-black;
      }

      &__error-text {
        top: -15px
      }

      &__selected {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &__input {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          cursor: pointer;
          border: none;
          background-color: transparent;
          transition: 0.3s;

          &:disabled {
            opacity: 0;
          }

          &_active {
            background-color: rgba($color-white, .6);
          }

          &_introduced {
            background-color: $color-white;
          }
        }
      }

      &__placeholder {
        color: $color-alabaster;
      }

      &__arrow {
        position: absolute;
        right: 10px;
        color: $color-alabaster;
        font-size: $font-size-sm;
        transition: 0.3s;
        pointer-events: none;

        &_open {
          transform: rotate(180deg);
        }
      }

      &__cross {
        position: absolute;
        right: 25px;
        color: $color-alabaster;
        font-size: $font-size-md;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          color: $color-alabaster;
        }
      }
    }

    &__indent {
      height: 4px;
    }

    &-dropdown {
      position: absolute;
      left: 0;
      padding: 0 8px;
      background-color: $color-white;
      box-shadow: 0 0 12px rgba($color-black, .12);
      border-radius: $borderRadiusLarge;
      width: 100%;
      max-height: 150px;
      z-index: 99;
      overflow: hidden;

      &-item {
        margin-bottom: 14px;
        transition: 0.3s;
        cursor: pointer;

        &_with-image {
          display: grid;
          grid-template-columns: 28px auto;
          grid-gap: 8px;
          align-items: center;
        }

        &:hover, &_selected {
          color: $color-alabaster;
        }

        &_last{
          margin-bottom: 0;
        }

        &__image {
          width: 28px;
          height: 28px;
          object-fit: cover;
          border-radius: 50%;
          background-color: $color-white;
        }
      }

      &__indent {
        position: sticky;
        height: 8px;
        width: 100%;
        background-color: $color-white;
        z-index: 2;

        &_top {
          top: 0
        }

        &_bot {
          bottom: 0;
        }
      }
    }
  }

</style>
