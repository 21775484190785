<template>
  <div
    class="cell-list"
  >
    <transition
      name="fade-content"
    >
      <component
        :is="listComponent[element.type]"
        :item.sync="element"
      >
      </component>
    </transition>
  </div>
</template>

<script>
import { LIST_FIELD } from '@/preset/elements/listElementsToComponent';

export default {
  name: 'ShowDefaultField',
  props: {
    element: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      listComponent: LIST_FIELD,
    };
  },
};
</script>

<style lang="scss">
  @import "../../sass/_variables.scss";
  .cell-list {
    width: 100%;
    position: relative;
    z-index: 1;
  }
</style>
