<template>
  <div class="fields-settings-edit">
    <div class="fields-settings-edit__wrapper">
      <div class="admin-form-field">
        <div class="admin-form-field__label">
          {{ $t('elements.roleAvailability') }}
        </div>
        <multipleSelectTemplate
          :settings="rolesSelectSettings"
          :class="{'error': errors.has('roles')}"
          @changeSelect="changeSelectRoles($event)"
        />
        <transition name="fade-validation">
          <span
            v-if="errors.has('roles')"
            class="validation"
          >
            {{ errors.first('roles') }}
          </span>
        </transition>
      </div>
      <div class="fields-settings-edit__row">
        <div
          class="admin-form-field admin-form-field_height"
          :class="{'admin-form-field_half':
            currentDynamicEl.type === typeList
            || currentDynamicEl.type === typeMultiList
            || currentDynamicEl.type === typeSwitch
          }"
        >
          <div class="admin-form-field__label">
            {{ $t('elements.fieldName') }}
          </div>
          <textInput
            :typeInput="'text'"
            :autofocus="true"
            :textCapitalize="true"
            :disabled="currentDynamicEl.type === typeBirthday"
            :value.sync="currentDynamicEl.label"
            :class="{'error': $validator.errors.has('label')}"
            :errorStatus="$validator.errors.has('label')"
          >
          </textInput>
          <transition name="fade-content">
            <span
              v-show="$validator.errors.has('label')"
              class="validation"
            >
              {{ $validator.errors.first('label') }}
            </span>
          </transition>
        </div>
        <transition
          name="fade-content"
          appear
          mode="out-in"
        >
          <generateDynamicFields
            v-if="showDynamicEl"
            :dynamicListEl.sync="dynamicEl"
            :newFieldEl.sync="currentDynamicEl"
            :showErrorInput.sync="showErrorInput"
            :flag="'editEl'"
          />
        </transition>
      </div>
    </div>
    <div class="admin-modal-wrap-footer">
      <div class="admin-modal-wrap-footer__item">
        <button
          class="ub-btn ub-btn_cancel admin-modal-wrap-footer__cancel"
          @click="closeWindow"
        >
          {{ $t('global.cancel') }}
        </button>
      </div>
      <div class="admin-modal-wrap-footer__item">
        <button
          class="ub-btn ub-btn_primary"
          @click="saveElement"
        >
          {{ $t('buttons.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  cloneDeep,
} from 'lodash';
import multipleSelectTemplate from '@/components/fields/multipleSelectTemplate';
import generateDynamicFields from '@/components/generateDynamicFields';
import rolesList from '@/constants/rolesList';
import textInput from '@/components/fields/textInput';
import ClickOutside from 'vue-click-outside';
import validationErrorMessage from '@/locales/validationErrorMessage';
import userCardApi from '@/api/user-card/userCardApi';
import {
  TYPE_BIRTHDAY, TYPE_LIST, TYPE_MULTILIST, TYPE_SWITCH, TYPE_NUMBER,
} from '@/constants/typesAll';

export default {
  name: 'FieldsSettingsEdit',
  components: {
    textInput,
    multipleSelectTemplate,
    generateDynamicFields,
  },
  directives: {
    ClickOutside,
  },
  props: {
    cardInfo: {
      type: Object,
      default: () => {},
    },
    currentElem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      validationError: validationErrorMessage,
      showErrorInput: false,
      toggleIconAction: -1,
      typeList: TYPE_LIST,
      typeMultiList: TYPE_MULTILIST,
      typeSwitch: TYPE_SWITCH,
      typeBirthday: TYPE_BIRTHDAY,
      rolesList,
      currentDynamicElDef: {},
    };
  },
  computed: {
    cardInfoList: {
      get() {
        const { cardData: { fields } } = this.cardInfoInside;
        return fields;
      },
      set(data) {
        this.cardInfoInside.cardData.fields = data;
      },
    },
    showDynamicEl() {
      return this.currentDynamicEl.type === this.typeList
          || this.currentDynamicEl.type === this.typeMultiList
          || this.currentDynamicEl.type === this.typeSwitch;
    },
    dynamicEl() {
      const {
        value,
      } = this.currentDynamicEl;
      return value;
    },
    language() {
      return this.$i18n.locale;
    },
    cardInfoInside: {
      get() {
        return this.cardInfo;
      },
      set(data) {
        this.$emit('update:cardInfo', data);
      },
    },
    currentDynamicEl: {
      get() {
        return this.currentDynamicElDef;
      },
      set(data) {
        this.currentDynamicElDef = data;
      },
    },
    rolesSelectSettings() {
      return {
        optionList: this.rolesList,
        placeholderText: '',
        selectedKey: this.currentDynamicEl.roles,
        disable: false,
        toTop: false,
        tabindex: 1,
      };
    },
  },
  beforeMount() {
    const roles = this.currentElem.roles.map((role) => role.key || role);
    this.currentDynamicEl = { ...cloneDeep(this.currentElem), roles };
    const dict = {
      en: {
        custom: {
          label: {
            required: validationErrorMessage.en.inputRequired,
          },
          roles: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        custom: {
          label: {
            required: validationErrorMessage.ru.inputRequired,
          },
          roles: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'label', rules: { required: true } });
    this.$validator.attach({ name: 'roles', rules: { required: true } });
  },
  methods: {
    closeChooseWindow() {
      this.toggleIconAction = -1;
    },
    openWindowSettings(id) {
      this.toggleIconAction = this.toggleIconAction === id ? -1 : id;
    },
    saveElement() {
      const {
        value,
      } = this.currentDynamicEl;
      const list = value;
      if (typeof list === 'object') {
        list.forEach((el) => {
          this.showErrorInput = el.text === '';
        });
      }
      const { label, fieldId, roles } = this.currentDynamicEl;
      this.$validator.validateAll({
        label,
        roles,
      }).then((result) => {
        if (result && !this.showErrorInput) {
          const { cardData: cardDateInside } = this.cardInfoInside;
          const { fields: fieldsInside } = cardDateInside;
          const indexEl = fieldsInside.findIndex((elem) => elem.fieldId === fieldId);
          fieldsInside.splice(indexEl, 1, this.currentDynamicEl);
          const { cardId = '' } = this.cardInfoInside;
          const data = {
            ...this.cardInfoInside,
            user: undefined,
            cardId: undefined,
          };
          const { cardData } = data;
          const { fields } = cardData;
          fields.forEach((item) => {
            if (item.type === TYPE_NUMBER) {
              item.value = +item.value;
            }
            const roles = item.roles.map((role) => {
              const currentRole = this.rolesList.find((el) => el.key === role || el.key === role.key);
              return role.key ? currentRole[role.key] : currentRole[role];
            });
            item.roles = roles;
          });
          userCardApi.updateField(cardId, data)
            .then()
            .catch((err) => {
              console.error(err);
            });
          this.closeWindow();
        }
      });
    },
    changeSelectRoles(key) {
      this.currentDynamicEl.roles = key;
    },
    closeWindow() {
      this.$emit('closeEditWindow', false);
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../sass/variables";

  .fields-settings-edit {
    background: rgba($color-dodger-blue, .08);
    border-radius: $borderRadius;
    padding: 10px 10px 16px 10px;
    max-width: 608px;
    min-width: 608px;

    &-item {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 15px;

      .text-field {
        width: 285px;
      }

      &_active {
        z-index: $z-index-biggest;

        .text-field {
          position: relative;
          z-index: -1;
        }
      }
    }

    &__row {
      display: flex;
      justify-content: flex-start;
      margin-top: 9px;
    }
  }

  .admin-form-field {
    width: 100%;

    & + .admin-form-field {
      margin-top: 10px;
    }

    &_height {
      height: 100%;
    }

    &_half {
      max-width: 264px;
      min-width: 264px;
    }

    &_list {
      margin-top: 20px !important;
      margin-left: 12px;
      min-width: 308px;
      max-width: 308px;
    }
  }

  .admin-modal-wrap {
    &-footer {
      padding: 37px 0 0 0;
    }
  }
</style>
