<template>
  <transition name="ub-bounce">
    <div
      v-if="hover === row._id"
      v-click-outside="closeToolbar"
      class="ub-toolbar-menu"
    >
      <i
        class="ub-icon-menu"
        @click.stop="setDisplayToolbar($event, true)"
      />
      <transition name="ub-bounce">
        <div
          v-if="showToolbar"
          class="ub-toolbar-menu-popup"
          :style="toolbarPosition"
        >
          <UBToolbarTable
            :row="row"
            :column="column"
            :index="index"
            :hover="hover"
            :right="true"
            @delete="deleteItem"
            @edit="editItem"
          />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import UBToolbarTable from '@/components/UBTable/UBToolbarTable/UBToolbarTable';

export default {
  name: 'UBToolbarMenu',
  components: { UBToolbarTable },
  props: {
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
    index: {
      type: Number,
      default: () => -1,
    },
    hover: {
      type: [Boolean, Number],
      default: () => false,
    },
  },
  data() {
    return {
      showToolbar: false,
      toolbarPosition: {},
    };
  },
  watch: {
    hover() {
      this.showToolbar = false;
    },
  },
  methods: {
    setDisplayToolbar(e, val) {
      this.showToolbar = val;
      const position = e.target.getBoundingClientRect();
      if (position) {
        this.toolbarPosition = {
          position: 'fixed',
          top: `${position.top + 21}px`,
          left: `${position.left - 32}px`,
        };
      }
    },
    closeToolbar() {
      this.toolbarPosition = {};
      this.showToolbar = false;
    },
    deleteItem(currentElem) {
      this.$emit('delete', currentElem);
    },
    editItem(currentElem) {
      this.$emit('edit', currentElem);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-toolbar-menu {
    position: relative;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-black;
    font: $font-size-h3 $font-global;
    cursor: pointer;

    &:hover {
      color: $color-alabaster;
    }

    &-popup {
      position: absolute;
      top: calc(100% + 5px);
      padding: 12px;
      border-radius: $borderRadiusLarge;
      width: 76px;
      box-shadow: 0 0 20px rgba($color-black, .05);
      background: $color-white;
      z-index: 5;
    }
  }

</style>
