import { uniqueId } from 'lodash';

const columns = [
  {
    title: 'ordersPage.clientTable.number',
    prop: 'orderId',
    width: 'minmax(110px, 1fr)',
    field: '_id',
  },
  {
    title: 'ordersPage.clientTable.date',
    prop: 'createdAt',
    width: 'minmax(150px, 1fr)',
  },
  {
    title: 'ordersPage.clientTable.PM',
    type: 'image',
    prop: 'projectManager.fullName',
    displayTitle: 'projectManager.fullName',
    display: 'projectManager.userAvatarUrl',
    imageStyle: {
      padding: 2,
      background: '#276EF1',
      width: 36,
      height: 36,
    },
    width: 'minmax(180px, 1fr)',
    defaultIcon: 'question',
    selectModal: 'projectManager',
  },
  {
    title: 'ordersPage.adminTable.partner',
    type: 'image',
    prop: 'partner.fullName',
    displayTitle: 'partner.fullName',
    display: 'partner.userAvatarUrl',
    imageStyle: {
      padding: 2,
      background: '#276EF1',
      width: 36,
      height: 36,
    },
    width: 'minmax(180px, 1fr)',
    defaultIcon: 'question',
    selectModal: 'partner',
  },
  {
    title: 'ordersPage.clientTable.status',
    type: 'tag',
    prop: 'status.manager',
    width: 'minmax(190px, 1.2fr)',
    locale: true,
    tagColor: 'status.color',
    reviews: {
      type: 'reviews',
      prop: 'feedback',
      width: 'minmax(70px, 0.8fr)',
    },
  },
  {
    title: 'ordersPage.clientTable.priceSecond',
    prop: 'steps',
    prefix: 'currency',
    locale: true,
    sum: 'cost',
    filterParams: 'isActive',
    width: 'minmax(120px, 0.8fr)',
    justifyContent: 'flex-end',
  },
];

const listSettingsServices = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default listSettingsServices;
