<template>
  <div class="admin-modal">
    <div class="admin-modal-wrap">
      <div class="admin-modal-wrap-header">
        <div class="admin-modal-wrap-header__title">
          {{ $t('refundModal.title') }}
        </div>
        <div
          class="admin-modal-wrap-header__close"
          @click="closeModal(false)"
        >
          <i class="ub-icon-close-circle admin-modal-wrap-header__icon"></i>
        </div>
      </div>
      <div class="admin-modal-wrap-content">
        <div
          v-if="!orderModal"
          class="admin-form-field"
        >
          <div class="admin-form-field__label">
            {{ $t('refundModal.numberOfOrder') }}
          </div>
          <selectWithKeyTemplate
            :settings="selectSettings"
            :class="{'error': errors.has('activePayOffVal')}"
            @changeSelect="changeSelect($event)"
          />
          <transition name="fade-validation">
            <span
              v-if="errors.has('activePayOffVal')"
              class="validation"
            >
              {{ errors.first('activePayOffVal') }}
            </span>
          </transition>
        </div>
        <div
          v-else
          class="admin-form-field"
        >
          <div class="admin-form-field__label">
            {{ $t('refundModal.numberOfOrder') }}
          </div>
          <textInput
            :typeInput="'text'"
            :value.sync="orderId"
            :showModal="true"
            :disabled="true"
            :textCapitalize="true"
            :readonly="true"
            :class="[
              {'error': $validator.errors.has('paymentDate')},
              {'active': datePicker}
            ]"
            :errorStatus="$validator.errors.has('paymentDate')"
          >
          </textInput>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('refundModal.numberOfDate') }}
          </div>
          <textInput
            :typeInput="'text'"
            :value.sync="formatDate"
            :showModal="true"
            :disabled="orderModal ? false : !activePayOff"
            :textCapitalize="true"
            :readonly="true"
            :class="[
              {'error': $validator.errors.has('paymentDate')},
              {'active': datePicker}
            ]"
            :errorStatus="$validator.errors.has('paymentDate')"
            :calendar="true"
            @handleClickIcon="showCalendar"
          >
          </textInput>
          <transition name="fade-el">
            <span
              v-show="$validator.errors.has('paymentDate')"
              class="validation"
            >
              {{ $validator.errors.first('paymentDate') }}
            </span>
          </transition>
          <transition name="fade-el">
            <datePickerModal
              v-if="datePicker"
              :yearSettings="true"
              :date.sync="newPayment.paymentDate"
              :datePicker.sync="datePicker"
              @closeModal="closeModal"
            >
            </datePickerModal>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('refundModal.amount') }}
          </div>
          <textInput
            :name="'sum'"
            :typeInput="'numeric'"
            :disabled="orderModal ? false : !activePayOff"
            :value.sync="newPayment.sum"
            :class="{'error': summError}"
            :errorStatus="summError"
          >
          </textInput>
          <div
            v-if="!summError"
            class="admin-form-field__icon"
          >
            <span>{{ currentPayment.currency }}</span>
          </div>
          <transition name="fade-el">
            <span
              v-show="summError"
              class="validation"
            >
              <template
                v-if="!newPayment.sum"
              >
                {{ $t('loginPage.loginForm.inputValidation') }}
              </template>
              <template
                v-else
              >
                {{ $t('servicesPage.addServiceModal.validationSumm') }} {{ currentPayment.maxPayoffSum }}
              </template>
            </span>
          </transition>
        </div>
      </div>
      <div class="admin-modal-wrap-footer">
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_cancel"
            @click="closeModal(false)"
          >
            {{ $t('global.cancel') }}
          </button>
        </div>
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_primary"
            @click="addNewPayments()"
          >
            {{ $t('global.payOff') }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-shortkey="['esc']"
      class="admin-modal__overlay"
      @click="closeModal(false)"
      @shortkey="closeModal(false)"
    ></div>
  </div>
</template>

<script>
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';
import partnerPayoffsApi from '@/api/partner-payoffs/partnerPayoffsApi';
import datePickerModal from '@/components/modals/datePickerModal';
import moment from 'moment';
import textInput from '@/components/fields/textInput';
import {
  find,
} from 'lodash';
import validationErrorMessage from '@/locales/validationErrorMessage';
import ordersApi from '@/api/orders/ordersApi';

export default {
  name: 'NewCountryModal',
  components: {
    selectWithKeyTemplate,
    textInput,
    datePickerModal,
  },
  props: {
    partnerId: {
      type: String,
      default: '',
    },
    orderId: {
      type: String,
      default: '',
    },
    orderModal: {
      type: Boolean,
      default: false,
    },
    payoff: {
      type: Boolean,
      default: false,
    },
    orderRefund: {
      type: Boolean,
      default: false,
    },
    orderPayment: {
      type: Object,
      default: () => {},
    },
    paymentsListData: {
      type: Array,
      default: () => [],
    },
    isVisiblePayoffButton: {
      type: Boolean,
      default: true,
    },
    newPaymentListModalData: {
      type: Array,
      default: () => [],
    },
    showWindowPaymentsProps: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      summError: false,
      summa: 50,
      validator: {},
      newPayment: {
        order: '',
        partner: '',
        paymentDate: new Date(),
        currency: '',
        sum: '',
      },
      activePayOffDef: '',
      datePicker: false,
      currentPayment: {},
    };
  },
  computed: {
    formatDate: {
      get() {
        return moment(this.newPayment.paymentDate).locale(this.language).format('MMMM DD, YYYY');
      },
      set(data) {
        this.newPayment.paymentDate = data;
      },
    },
    showWindowPayments: {
      get() {
        return this.showWindowPaymentsProps;
      },
      set(data) {
        this.$emit('update:showWindowPaymentsProps', data);
      },
    },
    newPaymentListModal: {
      get() {
        return this.newPaymentListModalData;
      },
      set(data) {
        this.$emit('update:newPaymentListModalData', data);
      },
    },
    paymentsList: {
      get() {
        return this.paymentsListData;
      },
      set(data) {
        this.$emit('update:paymentsListData', data);
      },
    },
    activePayOff: {
      get() {
        return this.activePayOffDef;
      },
      set(data) {
        this.activePayOffDef = data;
      },
    },
    selectSettings() {
      return {
        optionList: this.selectPaymentsList,
        placeholderText: this.$t('global.chooseNumber'),
        selectedKey: this.activePayOff,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: '',
        imagePosition: 'left',
        showImageInput: true,
      };
    },
    language() {
      return this.$i18n.locale;
    },
    selectPaymentsList() {
      const newArr = [];
      this.newPaymentListModal.forEach((item) => {
        newArr.push({
          text: item.orderId,
          key: item.orderId,
        });
      });
      return newArr;
    },
  },
  beforeMount() {
    if (this.orderRefund) {
      const { partner, order } = this.orderPayment;
      this.newPayment = this.orderPayment;
      ordersApi.getOrdersPartnerForPayoffs(partner, order)
        .then((resp) => {
          const { data } = resp;
          if (Object.keys(data).length) {
            this.currentPayment = {
              ...data,
              sum: data.maxPayoffSum,
              paymentDate: new Date(),
            };
          } else {
            this.isVisiblePayoffButton = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (Object.keys(this.orderPayment).length) {
      this.newPayment = this.orderPayment;
      this.updateLoader(true);
      ordersApi.getOrdersForPayoffs(this.newPayment.partner)
        .then((resp) => {
          const { resource } = resp.data;
          this.newPaymentListModal = resource;
          this.currentPayment = find(resp.data.resource, (el) => el.orderId === this.orderId);
          this.updateLoader(false);
        })
        .catch(() => {
          this.updateLoader(false);
        });
    }
    const dict = {
      en: {
        messages: {
          numeric: () => `${this.$t('loginPage.loginForm.insertNumber')}`,
          _default: (min, max) => `${this.$t('servicesPage.addServiceModal.fieldValue')} ${max[0]}`,
        },
        custom: {
          paymentDate: {
            required: validationErrorMessage.en.inputRequired,
          },
          activePayOffVal: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        messages: {
          numeric: () => `${this.$t('loginPage.loginForm.insertNumber')}`,
          _default: (min, max) => `${this.$t('servicesPage.addServiceModal.fieldValue')} ${max[0]}`,
        },
        custom: {
          paymentDate: {
            required: validationErrorMessage.ru.inputRequired,
          },
          activePayOffVal: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'paymentDate', rules: { required: true } });
    this.$validator.attach({ name: 'activePayOffVal', rules: { required: true } });
  },
  methods: {
    updateLoader(val) {
      this.$emit('updateLoader', val);
    },
    addNewPayments() {
      const {
        paymentDate,
        sum = 1,
      } = this.newPayment;
      const {
        maxPayoffSum = 1,
      } = this.currentPayment;
      this.summError = sum <= 0 || sum > maxPayoffSum;
      this.$validator.validateAll({
        paymentDate,
        activePayOffVal: this.payoff ? this.payoff : this.activePayOff,
      }).then((result) => {
        if (result && !this.summError) {
          const newPaymentData = {
            ...this.newPayment,
            sum: +sum,
          };
          this.updateLoader(true);
          partnerPayoffsApi.addPartnerPayoffs(newPaymentData)
            .then((resp) => {
              if (maxPayoffSum === sum) {
                this.$emit('update:isVisiblePayoffButton', false);
              }
              this.updateLoader(false);
              this.$emit('getOrders');
              const { orderRewardRate, payoffsSumToPay } = resp.data;
              this.newPayment.sum = payoffsSumToPay;
              this.paymentsList.unshift({
                ...resp.data,
                orderRewardRate: `${orderRewardRate}%`,
              });
              this.showWindowPayments = false;
              this.$emit('closeModal');
            })
            .catch((err) => {
              this.updateLoader(false);
              console.error(err);
            });
        }
      });
    },
    showCalendar() {
      this.datePicker = !this.datePicker;
    },
    closeModal(data) {
      this.showWindowPayments = data;
      this.$emit('closeModal');
    },
    changeSelect(id) {
      this.activePayOff = id;
      this.currentPayment = find(this.newPaymentListModal, (el) => el.orderId === id);
      const {
        _id = '',
        currency = '',
        maxPayoffSum = 1,
      } = this.currentPayment;
      const partner = this.partnerId;
      this.newPayment = {
        order: _id,
        partner,
        currency,
        sum: maxPayoffSum,
        paymentDate: new Date(),
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";

.admin-modal-wrap {
  width: 400px;
}

</style>
