import getUniqueId from '@/mixins/uniqueId';
import { TYPE_LIST } from '@/constants/typesAll';
import {
  OWNER, CEO, UB_CLIENT_MANAGER, UB_CLIENT_DEPARTMENT_HEAD,
  UB_CONTACT_MANAGER, UB_CONTACT_DEPARTMENT_HEAD,
  UB_BUSINESS_DEVELOPMENT_MANAGER, UB_BUSINESS_DEVELOPMENT_DEPARTMENT_HEAD, UB_CONTENT_MANAGER, UB_SERVICES_MANAGER,
} from '@/constants/roles';
import {
  customSelectList,
} from '@/constants/dynamicElList';

const NEW_LIST = () => ({
  fieldId: getUniqueId(),
  type: TYPE_LIST,
  value: [],
  activeValue: '',
  label: '',
  position: 0,
  groupId: 0,
  roles: [
    OWNER, CEO, UB_CLIENT_MANAGER, UB_CLIENT_DEPARTMENT_HEAD,
    UB_CONTACT_MANAGER, UB_CONTACT_DEPARTMENT_HEAD,
    UB_BUSINESS_DEVELOPMENT_MANAGER, UB_BUSINESS_DEVELOPMENT_DEPARTMENT_HEAD,
    UB_CONTENT_MANAGER, UB_SERVICES_MANAGER,
  ],
  customSelectList,
  author: '',
});

export default NEW_LIST;
