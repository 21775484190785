<template>
  <div>
    <transition name="fade-window">
      <div
        v-if="hover === row._id && (checkRefund || !checkRoleSEO)"
        class="ub-toolbar"
      >
        <i
          v-if="!isRefunded"
          class="action-table"
          :class="toggleIconAction ? 'ub-icon-cross-x' : 'ub-icon-more-vertical'"
          @click.stop="setOpenChoose($event, true)"
        >
          <transition name="fade-window">
            <div
              v-if="chooseWindow"
              v-click-outside="closeChooseWindow"
              class="ub-toolbar-choose"
              :class="{'ub-toolbar-choose_reward': reward}"
              :style="popupPositionChoose"
              @click.stop
              @mouseleave="closeChooseWindow"
            >
              <div class="ub-toolbar-choose-controls">
                <template v-if="!reward && !modalAction">
                  <button
                    class="ub-button ub-toolbar-choose-controls__button"
                    @click.stop="setEditItem"
                  >
                    {{ $t('buttons.change') }}
                  </button>
                  <button
                    v-if="copyAction"
                    class="ub-button ub-toolbar-choose-controls__button"
                    @click.stop="copyItem"
                  >
                    {{ $t('global.copy') }}
                  </button>
                  <button
                    class="ub-button ub-toolbar-choose-controls__button"
                    @click.stop="openAreYouShureWindow(true)"
                  >
                    {{ $t('buttons.delete') }}
                  </button>
                </template>
                <template v-else-if="modalAction">
                  <button
                    class="ub-button ub-toolbar-choose-controls__button"
                    @click.stop="showRefundModal"
                  >
                    {{ $t('buttons.refund') }}
                  </button>
                </template>
                <template v-else>
                  <button
                    class="ub-button ub-toolbar-choose-controls__button"
                    @click.stop="showReward(true)"
                  >
                    {{ $t('global.changeReward') }}
                  </button>
                </template>
              </div>
            </div>
          </transition>
          <transition name="fade-window">
            <div
              v-if="areYouShureWindow"
              v-click-outside="closeAreYouShureWindow"
              class="ub-toolbar-confirm"
              :style="popupAreYouShurePosition"
              @click.stop
              @mouseleave="closeAreYouShureWindow"
            >
              <div class="ub-toolbar-confirm__text">
                {{ $t('table.deleteElement') }}
              </div>
              <div class="ub-toolbar-confirm-controls">
                <button
                  class="ub-button ub-toolbar-confirm-controls__button"
                  @click.stop="closeAreYouShureWindow()"
                >
                  {{ $t('buttons.no') }}
                </button>
                <button
                  class="ub-button ub-toolbar-confirm-controls__button"
                  @click.stop="deleteItem"
                >
                  {{ $t('buttons.yes') }}
                </button>
              </div>
            </div>
          </transition>
        </i>
      </div>
    </transition>
    <transition
      name="fade-el"
      appear
      mode="out-in"
    >
      <UbRewardModal
        v-if="rewardModal"
        :row="row"
        @showReward="showReward"
      />
    </transition>
    <transition name="fade-window">
      <refundModal
        v-if="isVisibleRefundModal"
        :value="value"
        :sum="sum"
        :userId="userId"
        :row="row"
        @closeModal="closeModal"
        @update="update"
      />
    </transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import UbRewardModal from '@/components/UBTable/UBToolbarTable/UbRewardModal';
import refundModal from '@/components/modals/refundModal';
import { get } from 'lodash';
import {
  OWNER, CEO,
} from '@/constants/roleType';
import {
  UB_BUSINESS_DEVELOPMENT,
} from '@/constants/listStaffDepartments';

export default {
  name: 'UBToolbarTable',
  components: {
    UbRewardModal,
    refundModal,
  },
  directives: {
    ClickOutside,
  },
  props: {
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
    index: {
      type: Number,
      default: () => -1,
    },
    hover: {
      type: [Boolean, String],
      default: () => false,
    },
    right: {
      type: [String, Number],
    },
  },
  data() {
    return {
      rewardModal: false,
      eTargetIcon: {},
      toggleIconAction: false,
      isVisibleRefundModal: false,
      chooseWindow: false,
      areYouShureWindow: false,
      popupPositionChoose: {},
      popupAreYouShurePosition: {},
    };
  },
  computed: {
    checkRoleSEO() {
      const { role } = this.row;
      return role === OWNER && this.userRole === CEO;
    },
    checkRefund() {
      const { name } = this.$route;
      return (name === 'adminOrderList' && (this.userDepartment === UB_BUSINESS_DEVELOPMENT
          || this.userRole === CEO || this.userRole === OWNER));
    },
    userDepartment() {
      const { department = '' } = this.userInfo;
      return department;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userRole() {
      const { role = '' } = this.userInfo;
      return role;
    },
    reward() {
      return get(this.column, 'reward', '');
    },
    value() {
      const fromProp = get(this.row, this.column.prop, '');
      const prefix = get(this.row, this.column.prefix, '');
      const value = get(this.row, this.column.display, fromProp);
      return prefix + value;
    },
    modalAction() {
      return get(this.column, 'modalAction', false);
    },
    copyAction() {
      return get(this.column, 'copyAction', false);
    },
    currency() {
      return get(this.row, this.column.prop, '');
    },
    sum() {
      return get(this.row, 'sum', '');
    },
    isRefunded() {
      return get(this.row, 'isRefunded', false);
    },
    userId() {
      return get(this.row, '_id', null);
    },
  },
  methods: {
    update({ row, column, value }) {
      this.$emit('update', { row, column, value });
    },
    copyItem() {
      const { _id } = this.row;
      this.$emit('copy', _id);
    },
    showReward(mode) {
      this.rewardModal = mode;
    },
    closeModal() {
      this.chooseWindow = false;
      this.toggleIconAction = false;
      this.isVisibleRefundModal = false;
    },
    showRefundModal() {
      this.isVisibleRefundModal = true;
      this.chooseWindow = false;
      this.toggleIconAction = false;
    },
    closeChooseWindow() {
      if (!this.modalAction) {
        this.chooseWindow = false;
        this.popupPositionChoose = {};
        this.toggleIconAction = false;
      }
    },
    closeAreYouShureWindow() {
      this.popupAreYouShurePosition = {};
      this.areYouShureWindow = false;
      this.toggleIconAction = false;
    },
    deleteItem() {
      const currentElem = { row: this.row, column: this.column, index: this.index };
      this.$emit('delete', currentElem);
    },
    setEditItem() {
      this.toggleIconAction = false;
      this.chooseWindow = false;
      const currentElem = { row: this.row, column: this.column, index: this.index };
      this.$emit('edit', currentElem);
      this.closeChooseWindow();
    },
    openAreYouShureWindow(val) {
      this.closeChooseWindow();
      this.areYouShureWindow = val;
      const position = this.eTargetIcon.target.getBoundingClientRect();
      if (val && position && !this.rewardModal) {
        this.popupAreYouShurePosition = {
          position: 'fixed',
          top: `${position.top + 13}px`,
          right: 'auto',
          left: this.right ? `${position.left + 30}px` : `${position.left - 217}px`,
        };
      } else {
        this.popupAreYouShurePosition = {};
      }
      this.toggleIconAction = val;
    },
    setOpenChoose(e, val) {
      if (!this.isVisibleRefundModal) {
        this.eTargetIcon = e;
        this.areYouShureWindow = false;
        this.toggleIconAction = !this.toggleIconAction;
        if (!this.chooseWindow) {
          this.chooseWindow = val;
        } else {
          this.chooseWindow = false;
        }
        const position = e.target.getBoundingClientRect();
        if (val && position && !this.reward) {
          const { y } = position;
          this.popupPositionChoose = {
            position: 'fixed',
            top: window.innerHeight - y < 150 && this.copyAction ? `${position.top - 103}px` : `${position.top + 13}px`,
            right: 'auto',
            left: this.right ? `${position.left + 30}px` : `${position.left - 127}px`,
          };
        } else if (val && position && this.reward) {
          this.popupPositionChoose = {
            position: 'fixed',
            top: `${position.top + 13}px`,
            right: 'auto',
            left: this.right ? `${position.left + 30}px` : `${position.left - 174}px`,
          };
        } else {
          this.popupPositionChoose = {};
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-toolbar {
    display: grid;
    grid-template-columns: repeat(auto-fill, 21px);
    grid-gap: 10px;

    &__icon {
      position: relative;
    }

    &-choose, &-confirm {
      position: absolute;
      top: 0;
      right: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 10px rgba($color-black, .1);
      border-radius: $borderRadius;
      background-color: $color-white;
      color: $color-black;
      cursor: default;
      min-width: 143px;
      max-width: 143px;
      padding: 3px 0;
      z-index: -1;

      &_reward {
        min-width: 187px;
        max-width: 187px;
      }

      &-controls {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__button {
          line-height: 18px;
          min-height: 36px;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: background-color .15s ease-in;
          padding: 0 12px;
          font: $font-size-md $font-global;
          min-width: 40px;
          text-align: center;

          &:hover {
            background: $color-alabaster;
          }
        }
      }
    }

    &-confirm {
      min-width: 232px;
      max-width: 232px;
      padding: 13px 13px 4px 13px;
      min-height: 100px;

      &__text {
        font-size: $font-size-md;
        font-style: normal;
        text-align: center;
        margin-bottom: 12px;

        span {
          display: block;
          margin-top: 4px;
        }
      }

      &-controls {
        flex-direction: row;
        justify-content: flex-end;

        &__button {
          font-size: $font-size-h3;
          color: $color-dodger-blue;
          padding: 0 5px;
          cursor: pointer;
          display: flex;
          justify-content: center;

          & + .ub-toolbar-confirm-controls__button {
            margin-left: 18px;
          }
        }
      }
    }
  }

  .action-table {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba($color-dodger-blue, 0.08);
    border-radius: 50%;
    color: $color-dodger-blue;
    cursor: pointer;
    transition: all .15s ease-in;

    &_cursor {
      cursor: default;
    }

    &:before {
      position: relative;
    }

    &:hover {
      background: $color-dodger-blue;
      color: $color-white;
      border-color: transparent;
    }
  }

  .ub-icon-more-vertical {
    &:before {
      font-size: $font-size-md;
    }
  }

  .ub-icon-cross-x {
    background: $color-dodger-blue;
    color: $color-white;

    &:before {
      font-size: $font-size-h6;
      left: -.5px;
    }
  }

</style>
