import i18n from '@/i18n';
import {
  OWNER,
  CEO,
  UB_CLIENT_MANAGER,
  MANAGER,
  DEPARTMENT_HEAD,
  UB_CLIENT_DEPARTMENT_HEAD,
  UB_CONTACT_MANAGER,
  UB_CONTACT_DEPARTMENT_HEAD,
  UB_BUSINESS_DEVELOPMENT_MANAGER,
  UB_BUSINESS_DEVELOPMENT_DEPARTMENT_HEAD,
  UB_CONTENT_MANAGER,
  UB_SERVICES_MANAGER,
} from '@/constants/roles';

const rolesList = [
  {
    id: 1,
    text: i18n.t('roles.owner'),
    key: OWNER,
    Owner: {
      role: OWNER,
      key: OWNER,
    },
  },
  {
    id: 2,
    text: i18n.t('roles.ceo'),
    key: CEO,
    CEO: {
      role: CEO,
      key: CEO,
    },
  },
  {
    id: 3,
    text: i18n.t('roles.ubContactManager'),
    key: UB_CONTACT_MANAGER,
    UB_Contact_Manager: {
      role: MANAGER,
      department: 'ubContact',
      key: UB_CONTACT_MANAGER,
    },
  },
  {
    id: 4,
    text: i18n.t('roles.ubContactDepartmentHead'),
    key: UB_CONTACT_DEPARTMENT_HEAD,
    UB_Contact_Department_Head: {
      role: DEPARTMENT_HEAD,
      department: 'ubContact',
      key: UB_CONTACT_DEPARTMENT_HEAD,
    },
  },
  {
    id: 5,
    text: i18n.t('roles.ubClientDepartmentHead'),
    key: UB_CLIENT_DEPARTMENT_HEAD,
    UB_Client_Department_Head: {
      role: DEPARTMENT_HEAD,
      department: 'ubClient',
      key: UB_CLIENT_DEPARTMENT_HEAD,
    },
  },
  {
    id: 6,
    text: i18n.t('roles.ubClientManager'),
    key: UB_CLIENT_MANAGER,
    UB_Client_Manager: {
      role: MANAGER,
      department: 'ubClient',
      key: UB_CLIENT_MANAGER,
    },
  },
  {
    id: 7,
    text: i18n.t('roles.ubBusinessDevelopmentDepartmentHead'),
    key: UB_BUSINESS_DEVELOPMENT_DEPARTMENT_HEAD,
    UB_Business_Development_Department_Head: {
      role: DEPARTMENT_HEAD,
      department: 'ubBusinessDevelopment',
      key: UB_BUSINESS_DEVELOPMENT_DEPARTMENT_HEAD,
    },
  },
  {
    id: 8,
    text: i18n.t('roles.ubBusinessDevelopmentManager'),
    key: UB_BUSINESS_DEVELOPMENT_MANAGER,
    UB_Business_Development_Manager: {
      role: MANAGER,
      department: 'ubBusinessDevelopment',
      key: UB_BUSINESS_DEVELOPMENT_MANAGER,
    },
  },
  {
    id: 9,
    text: i18n.t('roles.ubContentManager'),
    key: UB_CONTENT_MANAGER,
    UB_Content_Manager: {
      role: MANAGER,
      department: 'ubContent',
      key: UB_CONTENT_MANAGER,
    },
  },
  {
    id: 10,
    text: i18n.t('roles.ubServicesManager'),
    key: UB_SERVICES_MANAGER,
    UB_Services_Manager: {
      role: MANAGER,
      department: 'ubServices',
      key: UB_SERVICES_MANAGER,
    },
  },
];

export default rolesList;
