import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  addPartnerPayoffs(data) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.PARTNERPAYOFFS_API,
    });
    return instWithCred.post('/partner-payoffs', data);
  },
};
