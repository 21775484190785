<template>
  <transition name="ub-bounce">
    <div
      v-if="hover === row._id"
      class="ub-complete-project"
      @mouseleave="closePopup"
      @mouseenter="stopClose"
    >
      <div
        class="ub-complete-project-button"
        @click.stop="setPopupPosition"
      >
        <i class="ub-icon-check-mark ub-complete-project__icon" />
        <span class="ub-complete-project__title">
          {{ $t('table.complete') }}
        </span>
      </div>
      <transition name="ub-bounce">
        <div
          v-if="popupOpen"
          v-click-outside="closePopup"
          class="ub-complete-project-popup"
          :style="popupPosition"
          @click.stop
          @mouseleave="closePopup"
        >
          <div class="ub-complete-project-popup__title">
            {{ $t('table.complete') }}
          </div>
          <div class="ub-complete-project-popup-controls">
            <button
              class="ub-button ub-complete-project-popup-controls__button"
              @click.stop="clickToIcon"
            >
              {{ $t('buttons.yes') }}
            </button>
            <button
              class="ub-button ub-complete-project-popup-controls__button"
              @click.stop="closePopup"
            >
              {{ $t('buttons.no') }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'UBCompleteProject',
  props: {
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
    hover: {
      type: [Boolean, Number],
      default: () => false,
    },
  },
  data() {
    return {
      popupOpen: false,
      popupPosition: {},
      stopClosePopup: false,
    };
  },
  computed: {
    icon() {
      return get(this.column, 'icon', '');
    },
  },
  watch: {
    hover() {
      this.popupOpen = false;
    },
  },
  methods: {
    stopClose() {
      this.stopClosePopup = true;
    },
    closePopup() {
      this.stopClosePopup = false;
      setTimeout(() => {
        if (!this.stopClosePopup) {
          this.popupOpen = false;
          this.popupPosition = {};
        }
      }, 200);
    },
    setPopupPosition(e) {
      this.popupOpen = true;
      const position = e.target.getBoundingClientRect();
      if (position) {
        this.popupPosition = {
          position: 'fixed',
          top: `${position.top}px`,
          right: 'auto',
          left: `${position.left - 145}px`,
        };
      } else {
        this.popupPosition = {};
      }
    },
    clickToIcon() {
      this.closePopup();
      this.$emit('update', { row: this.row, column: this.column });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-complete-project {
    font-family: $font-global;

    &-button {
      display: flex;
      color: $color-black;
      cursor: pointer;
      padding: 5px;
      transition: 0.3s;

      &:hover {
        color: $color-white;
      }
    }

    &__title {
      white-space: nowrap;
      font-size: $font-size-md;
      line-height: 14px;
    }

    &-popup {
      position: absolute;
      top: 0;
      right: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 117px;
      background-color: $color-white;
      box-shadow: 0 0 15px rgba($color-black, .05);
      border-radius: $borderRadiusLarge;
      padding: 14px;
      color: $color-black;
      font-size: $font-size-h3;
      line-height: 17px;
      z-index: $z-index-small;
      cursor: default;

      &__title {
        text-align: center;
        max-width: 87px;
      }

      &-controls {
        display: grid;
        justify-content: space-between;
        align-items: center;
        grid-template-columns: 39px 39px;
        grid-gap: 10px;
        margin-top: 13px;

        &__button {
          text-transform: capitalize;
          font-weight: 400;
          font-size: $font-size-h3;
          line-height: 16px;
          padding: 5px 8px;
          cursor: pointer;
        }
      }
    }
  }

</style>
