<template>
  <transition name="fade-window">
    <div
      v-if="review"
      class="ub-reviews"
    >
      <div
        :ref="refIdPopup"
        class="ub-reviews__rating"
        @mouseover="openPopup()"
        @mouseleave="closeReviewsPopup(-1)"
      >
        <StarRating
          :padding="5"
          :readOnly="true"
          :activeColor="'#EEAC05'"
          :inactiveColor="'#EEEEEE'"
          :showRating="false"
          :roundedCorners="true"
          :starSize="16"
          :rating="review.grade"
          :starPoints="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
        />
        <transition
          name="fade-content"
          appear
          mode="out-in"
        >
          <UBReviewsPopup
            v-if="openReviewsPopup === idComment"
            :comment="comment"
            :style="{
              top: `${topCordPopup}px`,
              left: `${leftCordPopup}px`
            }"
          />
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import UBReviewsPopup from '@/components/UBTable/UBReviews/UBReviewsPopup';
import StarRating from 'vue-star-rating';

export default {
  name: 'UBReviews',
  components: {
    StarRating,
    UBReviewsPopup,
  },
  props: {
    review: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      refIdPopup: Math.floor(new Date().valueOf() * Math.random()),
      topCordPopup: 0,
      leftCordPopup: 0,
      openReviewsPopupDef: -1,
    };
  },
  computed: {
    comment() {
      const { comment = '' } = this.review;
      return comment;
    },
    idComment() {
      const { _id = '' } = this.review;
      return _id;
    },
    openReviewsPopup: {
      get() {
        return this.openReviewsPopupDef;
      },
      set(data) {
        this.openReviewsPopupDef = data;
      },
    },
  },
  methods: {
    openPopup() {
      const bounding = this.$refs[this.refIdPopup].getBoundingClientRect();
      this.topCordPopup = bounding.top + 16;
      this.leftCordPopup = bounding.left - 35;
      this.openReviewsPopup = this.review._id;
    },
    closeReviewsPopup(data) {
      this.openReviewsPopup = data;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-reviews {
    margin-left: 14px;
    position: relative;
    top: 4px;

    &__rating {
      width: 105px;
    }
  }

</style>
