/* eslint-disable */
export const downloadFile = () => `
  <svg id="svg" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.37112 0H4.62932C4.2625 0 3.96525 0.297369 3.96525 0.664071V6.67279C3.96525 7.01035 3.69161 7.284 3.35404 7.284H1.06458C0.586261 7.284 0.357609 7.87185 0.710174 8.19516L6.26258 13.2848C6.67992 13.6674 7.32052 13.6674 7.73799 13.2848L13.2904 8.19516C13.643 7.87197 13.4143 7.284 12.936 7.284H10.6465C10.309 7.284 10.0353 7.01035 10.0353 6.67279V0.664071C10.0352 0.297369 9.73782 0 9.37112 0Z" fill="#276EF1"/>
        <path d="M0.322998 15.1427C0.322998 15.6161 0.706788 15.9998 1.18007 15.9998H12.8199C13.2933 15.9998 13.6769 15.616 13.6769 15.1427V15.036C13.6769 14.5626 13.2932 14.179 12.8199 14.179H1.18007C0.706665 14.179 0.322998 14.5627 0.322998 15.036V15.1427Z" fill="currentColor"/>
</svg>
`;

export const uploadFile = () => `
 <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 11.0375C24 13.55 22.0594 15.5938 19.7344 15.5938H14.8547C14.6437 15.5938 14.475 15.425 14.475 15.2141V12.2469C14.475 11.9703 14.7 11.75 14.9719 11.75H16.8375C17.2266 11.75 17.4141 11.2719 17.1281 11.0047L12.6 6.85625C12.2578 6.54219 11.7375 6.54219 11.3953 6.85625L6.86719 11.0047C6.58125 11.2672 6.76406 11.75 7.15781 11.75H9.02344C9.3 11.75 9.52031 11.975 9.52031 12.2469V15.2141C9.52031 15.425 9.35156 15.5938 9.14062 15.5938H4.26562C1.94063 15.5938 0 13.55 0 11.0375C0 8.78281 1.57031 6.90781 3.5625 6.54219C3.5625 6.52344 3.5625 6.5 3.5625 6.48125C3.5625 3.12969 6.08438 0.40625 9.1875 0.40625C11.1938 0.40625 13.0031 1.54063 14.0062 3.36406C14.6672 2.92344 15.4266 2.68438 16.2188 2.68438C18.3234 2.68438 20.0719 4.35781 20.3859 6.5375C22.4062 6.875 24 8.76406 24 11.0375Z" fill="#276EF1"/>
   </svg>
`;
export const closeIcon = () => `
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.49076 7.74109L4 5.25027L1.50924 7.74109C1.16396 8.08631 0.604196 8.08631 0.258915 7.74109C-0.0863051 7.39581 -0.0863051 6.83604 0.258915 6.49076L2.74974 4L0.258915 1.50924C-0.0863051 1.16396 -0.0863051 0.604196 0.258915 0.258915C0.604196 -0.0863051 1.16396 -0.0863051 1.50924 0.258915L4 2.74974L6.49076 0.258915C6.83604 -0.0863051 7.39581 -0.0863051 7.74109 0.258915C8.08631 0.604196 8.08631 1.16396 7.74109 1.50924L5.25027 4L7.74109 6.49076C8.08631 6.83604 8.08631 7.39581 7.74109 7.74109C7.39581 8.08631 6.83604 8.08631 6.49076 7.74109Z" fill="currentColor"/>
  </svg>`;


export const closeIconStripe = () => `
  <svg width="16" height="16" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.49076 7.74109L4 5.25027L1.50924 7.74109C1.16396 8.08631 0.604196 8.08631 0.258915 7.74109C-0.0863051 7.39581 -0.0863051 6.83604 0.258915 6.49076L2.74974 4L0.258915 1.50924C-0.0863051 1.16396 -0.0863051 0.604196 0.258915 0.258915C0.604196 -0.0863051 1.16396 -0.0863051 1.50924 0.258915L4 2.74974L6.49076 0.258915C6.83604 -0.0863051 7.39581 -0.0863051 7.74109 0.258915C8.08631 0.604196 8.08631 1.16396 7.74109 1.50924L5.25027 4L7.74109 6.49076C8.08631 6.83604 8.08631 7.39581 7.74109 7.74109C7.39581 8.08631 6.83604 8.08631 6.49076 7.74109Z" fill="currentColor"/>
  </svg>`;
