import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  getAllStaffDescription() {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.TYPE_SERVICES_API,
    });
    return instWithCred.get('orders/staff-descriptions');
  },
  deleteStaffDescription(id) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.TYPE_SERVICES_API,
    });
    return instWithCred.delete(`orders/staff-descriptions/${id}`);
  },
  addStaffDescription(staffDescription) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.TYPE_SERVICES_API,
    });
    return instWithCred.post('orders/staff-descriptions', staffDescription);
  },
  updateStaffDescription({ id, description }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.patch(`orders/staff-descriptions/${id}`, description);
  },
};
