<template>
  <div class="ub-index-cell">
    {{ index + 1 }}
  </div>
</template>

<script>
export default {
  name: 'UBIndexCell',
  props: {
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
    index: {
      type: Number,
      default: () => -1,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-index-cell {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    padding: 2px;
    background: rgba($color-black, .15);
    border-radius: 50%;
    color: $color-black;
    font-weight: bold;
    font-size: $font-size-sm;
    line-height: 1;
    cursor: default;
  }

</style>
