<template>
  <div
    class="paypal"
    :class="{'open': isOpenPaySystem}"
    @click="clickOutside"
  >
    <div
      v-if="isOpenPaySystem"
      class="paypal__background"
      @click.stop
    >
      <div ref="paypal" />
    </div>
    <div
      v-else
      ref="paypal"
    />
  </div>
</template>

<script>

import paymentsApi from '@/api/payments/paymentsApi';
import ordersApi from '@/api/orders/ordersApi';

import ClickOutside from 'vue-click-outside';
import { PAYPAL_LOCAL_CLIENT_ID } from '@/constants/paypalLocalClientId';

export default {
  name: 'Paypal',
  directives: {
    ClickOutside,
  },
  props: {
    loader: {
      type: Boolean,
    },
    currentOrder: {
      type: Object,
      default: () => {},
    },
    showChangeStatusPayment: {
      type: Boolean,
      default: true,
    },
    fundingSource: {
      type: String,
      default: 'paypal',
    },
    colorBtnPayPal: {
      type: String,
      default: 'black',
    },
  },
  data() {
    return {
      loaded: false,
      isOpenPaySystem: false,
    };
  },
  computed: {
    loaderDef: {
      get() {
        return this.loader;
      },
      set(data) {
        this.$emit('update:loader', data);
      },
    },
    currentOrderProps: {
      get() {
        return this.currentOrder;
      },
      set(data) {
        this.$emit('update:currentOrder', data);
      },
    },
    paymentsCheckoutData() {
      if (Object.keys(this.currentOrderProps).length) {
        return {
          clientId: this.currentOrderProps.client._id,
          orderId: this.currentOrderProps._id,
          isPrepayment: this.calcPrepaymentCondition,
          lang: this.language,
          paymentService: 'Paypal',
        };
      }
      return false;
    },
    calcPrepaymentCondition() {
      const { isPrepaymentFulfilled, prePayment } = this.currentOrderProps;
      return !isPrepaymentFulfilled && !!prePayment;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    const clientId = process.env.VUE_APP_PAYPAL_CLIENT_ID || PAYPAL_LOCAL_CLIENT_ID;
    const script = document.createElement('script');
    const { currency = 'USD' } = this.currentOrderProps;
    // eslint-disable-next-line max-len
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&components=buttons,funding-eligibility`;
    script.setAttribute('data-csp-nonce', 'xyz-123');
    script.addEventListener('load', this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    updatePaypalError(data) {
      if (this.$parent.showErrorPaypal) {
        this.$parent.showErrorPaypal(data);
      }
    },
    updatePayPalHalf() {
      if (this.$parent.progressBarClickEvent) {
        this.$parent.progressBarClickEvent();
      }
    },
    updateData(data) {
      if (this.$parent.updateFullData) {
        this.$parent.updateFullData(data, false);
      }
    },
    updateLoader(data) {
      if (this.$parent.updatePaymentLoader) {
        this.$parent.updatePaymentLoader(data);
      }
    },
    clickOutside() {
      this.$emit('closePayPal', false);
    },
    setLoaded() {
      const self = this;
      const payments = {
        ...this.paymentsCheckoutData,
      };
      this.loaded = true;
      window.paypal.Buttons({
        style: {
          color: self.colorBtnPayPal,
          label: 'paypal',
          tagline: 'false',
          size: 'small',
          height: 40,
        },
        fundingSource: self.fundingSource === 'card' ? window.paypal.FUNDING.CARD : window.paypal.FUNDING.PAYPAL,
        createOrder() {
          return paymentsApi.addPaymentsCheckout(payments)
            .then((resp) => resp.data.id)
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error('err', err);
              self.isOpenPaySystem = false;
              self.updatePaypalError(true);
            });
        },
        onApprove(data) {
          if (self.showChangeStatusPayment) {
            self.$emit('changeStatusPayment', true);
            self.$emit('changePaymentLoader', true);
          } else {
            self.$emit('closePayPal', false);
          }
          paymentsApi.approvePayment({ paymentService: 'Paypal', externalPaymentSystemId: data.orderID })
            .then((resp) => {
              if (resp.data.status === 'COMPLETED') {
                const timeOut = self.showChangeStatusPayment ? 3000 : 0;
                setTimeout(() => {
                  ordersApi.getOrderById(self.currentOrderProps._id)
                    .then((resp) => {
                      self.updateLoader(false);
                      self.updatePayPalHalf();
                      if (!self.showChangeStatusPayment) {
                        self.$emit('payFull', true);
                      } else {
                        self.updateData(resp.data);
                        self.$emit('update:loader', false);
                      }
                    })
                    .catch((err) => {
                      // eslint-disable-next-line no-console
                      console.error(err);
                    });
                }, timeOut);
              }
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);
            });
        },
        onError: (error) => {
          this.$emit('textError', error.message);
        },
        onClick(data) {
          const { fundingSource } = data;
          if (fundingSource === 'card') {
            self.isOpenPaySystem = true;
          }
        },
        onCancel() {
          self.isOpenPaySystem = false;
        },
      })
        .render(this.$refs.paypal);
    },
  },
};
</script>

>

<style scoped lang="scss">
@import "../../sass/variables";

  .paypal {

    &__title {
      font: $font-size-md $font-global-medium;
      text-align: center;
      color: $color-black;
      margin-bottom: 15px;
    }

    &__background {
      padding: 20px 10px;
      background: $color-white;
      border-radius: 4px;
      width: 500px;
      margin: 0 auto;
    }
  }
  .paypal.open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-black,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
