<template>
  <div
    class="ub-status"
  >
    <div
      v-if="value"
      v-click-outside="clickOutside"
      class="ub-tag-cell"
      :class="`ub-tag-cell_${tagColor}`"
      @mouseenter="clickCell"
      @mouseleave="isVisibleModal = -1"
    >
      {{ value }}
    </div>
    <template v-if="typeModal === 'payments'">
      <transition name="fade-el">
        <UBPaymentModal
          v-if="showModal"
          :style="{
            top: `${topPosition}px`
          }"
          :row="row"
        />
      </transition>
    </template>
    <UBReviews
      v-if="review"
      :review="review"
    />
  </div>
</template>

<script>
import { get } from 'lodash';
import UBReviews from '@/components/UBTable/UBReviews/UBReviews';
import ClickOutside from 'vue-click-outside';
import UBPaymentModal from '@/components/UBTable/UBPaymentModal/UBPaymentModal';

export default {
  name: 'UBTagCell',
  directives: {
    ClickOutside,
  },
  components: {
    UBReviews,
    UBPaymentModal,
  },
  props: {
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
    index: {
      type: Number,
      default: () => -1,
    },
  },
  data() {
    return {
      isVisibleModal: -1,
      topPosition: 0,
      rightPosition: 0,
    };
  },
  computed: {
    field() {
      const field = get(this.column, 'field', '');
      return get(this.row, `${field}`, '');
    },
    showModal() {
      return this.isVisibleModal === this.field && this.statuses;
    },
    statuses() {
      return this.tagColor === 'orange' || this.tagColor === 'green' || this.tagColor === 'blue';
    },
    language() {
      return this.$i18n.locale;
    },
    typeModal() {
      return get(this.column, 'typeModal', '');
    },
    review() {
      const prop = get(this.column, 'reviews.prop', '');
      const value = get(this.row, prop, '');
      return value;
    },
    value() {
      const { locale = false } = this.column;
      return get(this.row, locale ? `${this.column.prop}.${this.language}` : this.column.prop, '');
    },
    tagColor() {
      return get(this.row, this.column.tagColor, 'gray');
    },
  },
  methods: {
    clickCell(e) {
      const { payments } = this.row;
      const el = e.currentTarget;
      const { y, height } = el.getBoundingClientRect();
      const checkPayments = payments.length === 1 ? -83 : -125;
      const top = y + height > window.innerHeight - 200
        ? checkPayments
        : 32;
      this.rightPosition = y + height > window.innerWidth - 200
        ? checkPayments
        : 32;
      this.topPosition = top;
      const res = this.isVisibleModal === -1 ? this.isVisibleModal = this.field
        : this.isVisibleModal = -1;
      return res;
    },
    clickOutside() {
      this.isVisibleModal = -1;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-status {
    display: flex;
    align-items: center;
  }

  .ub-tag-cell {
    display: inline-block;
    padding: 4px 8px;
    border-radius: $borderRadius;
    font-weight: normal;
    font: $font-size-h3 $font-global;
    line-height: 1.5;

    &_gray {
      background: $color-gallery;
      color: $color-silver-chalice;
    }

    &_orange {
      background: rgba($color-corn, .12);
      color: $color-corn,
    }

    &_main {
      background: rgba($color-dodger-blue, .08);
      color: $color-dodger-blue,
    }

    &_green {
      background: rgba($color-green, .12);
      color: $color-green,
    }

    &_red {
      background: rgba($color-cardinal, .12);
      color: $color-cardinal,
    }

    &_blue {
      background: rgba($color-dodger-blue, .12);
      color: $color-dodger-blue,
    }

  }

</style>
