<template>
  <div class="fields-settings">
    <div class="fields-settings__wrapper">
      <div class="admin-form-field">
        <div class="admin-form-field__label">
          {{ $t('elements.roleAvailability') }}
        </div>
        <multipleSelectTemplate
          :settings="rolesSelectSettings"
          :class="{'error': errors.has('roles')}"
          @changeSelect="changeSelectRoles($event)"
        />
        <transition name="fade-validation">
          <span
            v-if="errors.has('roles')"
            class="validation"
          >
            {{ errors.first('roles') }}
          </span>
        </transition>
      </div>
      <div class="admin-form-field">
        <div class="admin-form-field__label">
          {{ $t('elements.fieldType') }}
        </div>
        <selectWithKeyTemplate
          :settings="typeFieldSelectSettings"
          @changeSelect="changeSelectTypeField($event)"
        />
      </div>
      <div class="fields-settings__row">
        <div
          class="admin-form-field admin-form-field_height"
          :class="{'admin-form-field_half': showDynamicEl}"
        >
          <div class="admin-form-field__label">
            {{ $t('elements.fieldName') }}
          </div>
          <textInput
            :typeField="typeField"
            :typeInput="'text'"
            :autofocus="true"
            :textCapitalize="true"
            :disabled="newField.type === typeBirthday"
            :value.sync="newField.label"
            :class="{'error': $validator.errors.has('label')}"
            :errorStatus="$validator.errors.has('label')"
          >
          </textInput>
          <transition name="fade-content">
            <span
              v-show="$validator.errors.has('label')"
              class="validation"
            >
              {{ $validator.errors.first('label') }}
            </span>
          </transition>
        </div>
        <transition
          name="fade-content"
          appear
          mode="out-in"
        >
          <generateDynamicFields
            v-if="showDynamicEl"
            :dynamicListEl.sync="dynamicEl"
            :newFieldEl.sync="newField"
            :showErrorInput.sync="showErrorInput"
            :flag="'newEl'"
          />
        </transition>
      </div>
    </div>
    <div class="admin-modal-wrap-footer">
      <div class="admin-modal-wrap-footer__item">
        <button
          class="ub-btn ub-btn_cancel admin-modal-wrap-footer__cancel"
          @click="closeWindow"
        >
          {{ $t('global.cancel') }}
        </button>
      </div>
      <div class="admin-modal-wrap-footer__item">
        <button
          class="ub-btn ub-btn_primary"
          @click="addNewElement"
        >
          {{ $t('buttons.add') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';
import generateDynamicFields from '@/components/generateDynamicFields';
import multipleSelectTemplate from '@/components/fields/multipleSelectTemplate';
import typesList from '@/constants/typesList';
import ClickOutside from 'vue-click-outside';
import rolesList from '@/constants/rolesList';
import textInput from '@/components/fields/textInput';
import userCardApi from '@/api/user-card/userCardApi';
import {
  TYPE_TEXT,
  TYPE_LIST,
  TYPE_MULTILIST,
  TYPE_SWITCH,
  TYPE_BIRTHDAY,
} from '@/constants/typesAll';
import { FIELD_FUNCTION } from '@/preset/elements/listElementsToFunction';
import validationErrorMessage from '@/locales/validationErrorMessage';

export default {
  name: 'FieldsSettings',
  components: {
    textInput,
    selectWithKeyTemplate,
    generateDynamicFields,
    multipleSelectTemplate,
  },
  directives: {
    ClickOutside,
  },
  props: {
    filterQueryTab: {
      type: Object,
      default: () => {},
    },
    cardInfo: {
      type: Object,
      default: () => {},
    },
    emptyCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeField: '',
      validationError: validationErrorMessage,
      showErrorInput: false,
      toggleIconAction: -1,
      typeList: TYPE_LIST,
      typeMultiList: TYPE_MULTILIST,
      typeSwitch: TYPE_SWITCH,
      typeBirthday: TYPE_BIRTHDAY,
      typesList,
      rolesList,
      newField: {},
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    showDynamicEl() {
      return this.newField.type === this.typeList
          || this.newField.type === this.typeMultiList
          || this.newField.type === this.typeSwitch;
    },
    dynamicEl() {
      const {
        type,
        customSelectList,
        customMultiSelectList,
        customRadioButtonList,
      } = this.newField;
      switch (type) {
      case this.typeList:
        return customSelectList;
      case this.typeMultiList:
        return customMultiSelectList;
      case this.typeSwitch:
        return customRadioButtonList;
      default:
        break;
      }
      return undefined;
    },
    language() {
      return this.$i18n.locale;
    },
    filterQuery: {
      get() {
        return this.filterQueryTab;
      },
      set(data) {
        this.$emit('update:filterQueryTab', data);
      },
    },
    cardInfoInside: {
      get() {
        return this.cardInfo;
      },
      set(data) {
        this.$emit('update:cardInfo', data);
      },
    },
    typeFieldSelectSettings() {
      return {
        optionList: this.typesList,
        placeholderText: '',
        selectedKey: this.newField.type,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: '',
      };
    },
    rolesSelectSettings() {
      return {
        optionList: this.rolesList,
        placeholderText: '',
        selectedKey: this.newField.roles,
        disable: false,
        toTop: false,
        tabindex: 1,
        returnObj: true,
      };
    },
  },
  beforeMount() {
    const element = FIELD_FUNCTION[TYPE_TEXT]();
    this.newField = {
      ...element,
    };
    const dict = {
      en: {
        custom: {
          label: {
            required: validationErrorMessage.en.inputRequired,
          },
          roles: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        custom: {
          label: {
            required: validationErrorMessage.ru.inputRequired,
          },
          roles: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'label', rules: { required: true } });
    this.$validator.attach({ name: 'roles', rules: { required: true } });
  },
  methods: {
    addNewElement() {
      const {
        type,
        customSelectList,
        customMultiSelectList,
        customRadioButtonList,
      } = this.newField;
      let list = [];
      switch (type) {
      case this.typeList:
        list = customSelectList;
        break;
      case this.typeMultiList:
        list = customMultiSelectList;
        break;
      case this.typeSwitch:
        list = customRadioButtonList;
        break;
      default:
        break;
      }
      list.forEach((el) => {
        this.showErrorInput = el.text === '';
      });
      const { label, roles } = this.newField;
      this.$validator.validateAll({
        label,
        roles,
      }).then((result) => {
        if (result && !this.showErrorInput) {
          const { type: typeEl } = this.newField;
          const { tab } = this.filterQuery;
          const element = FIELD_FUNCTION[typeEl]();
          const {
            label, roles, type, customSelectList, customMultiSelectList, customRadioButtonList, value: valueEl,
          } = this.newField;
          const structureRoles = roles.map((role) => {
            const currentRole = this.rolesList.find((el) => el.key === role);
            return role.key ? currentRole[role.key] : currentRole[role];
          });
          const { _id: id } = this.userInfo;
          const newField = {
            ...element,
            label,
            groupId: +tab,
            type,
            roles: structureRoles,
            author: id,
            value: customSelectList || customMultiSelectList || customRadioButtonList || valueEl,
          };
          const { cardData: { fields } } = this.cardInfo;
          fields.push(newField);
          if (!this.emptyCard) {
            this.$emit('changeEmptyCard', true);
            userCardApi.createNewField({ ...this.cardInfoInside })
              .then((resp) => {
                const {
                  _id: cardId = '',
                } = resp.data;
                this.cardInfoInside.cardId = cardId;
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            const { cardId = '' } = this.cardInfoInside;
            const data = {
              ...this.cardInfoInside,
              user: undefined,
              cardId: undefined,
            };
            userCardApi.updateField(cardId, data)
              .then()
              .catch((err) => {
                console.error(err);
              });
          }
          this.closeWindow();
        }
      });
    },
    changeSelectTypeField(key) {
      this.typeField = key;
      const element = FIELD_FUNCTION[key]();
      const { customSelectList, customMultiSelectList, customRadioButtonList } = element;
      const list = customSelectList || customMultiSelectList || customRadioButtonList;
      if (list) {
        list.forEach((el) => {
          el.text = '';
        });
      }
      this.newField = {
        ...element,
      };
    },
    changeSelectRoles(key) {
      this.newField.roles = key;
    },
    closeWindow() {
      this.$emit('toggleFieldsSettings', false);
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../sass/variables";

  .fields-settings {
    background: rgba($color-dodger-blue, .08);
    border-radius: $borderRadius;
    padding: 10px 10px 16px 10px;
    max-width: 608px;
    margin: auto auto 30px auto;
    min-width: 608px;

    &-item {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 15px;

      .text-field {
        width: 285px;
      }

      &_active {
        z-index: $z-index-biggest;

        .text-field {
          position: relative;
          z-index: -1;
        }
      }
    }

    &__row {
      display: flex;
      justify-content: flex-start;
      margin-top: 9px;

      &_end {
        justify-content: flex-end;
      }
    }
  }

  .admin-form-field {
    width: 100%;

    & + .admin-form-field {
      margin-top: 10px;
    }

    &_height {
      height: 100%;
    }

    &_half {
      max-width: 264px;
      min-width: 264px;
    }

    &_list {
      margin-top: 20px !important;
      margin-left: 12px;
      min-width: 308px;
      max-width: 308px;
    }
  }

  .admin-modal-wrap {
    &-footer {
      padding: 37px 0 0 0;
    }
  }
</style>
