<template>
  <div
    class="ub-select-cell"
    :style="style"
  >
    <UBSelect
      :options="options"
      :placeholder="placeholder"
      :label="label"
      :value="value"
      @change="setValue"
    />
  </div>
</template>

<script>
import { get, isEqual } from 'lodash';
import UBSelect from '@/components/UBTable/UBSelect/UBSelect';

export default {
  name: 'UBSelectCell',
  components: { UBSelect },
  props: {
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
    index: {
      type: Number,
      default: () => -1,
    },
  },
  computed: {
    options() {
      return get(this.column, 'options', []);
    },
    label() {
      return get(this.column, 'listLabel', 'name');
    },
    value() {
      return get(this.row, this.column.prop, '');
    },
    placeholder() {
      return get(this.column, 'placeholder', '');
    },
    style() {
      const minWidth = get(this.column, 'selectWidth.min', 'auto');
      const maxWidth = get(this.column, 'selectWidth.max', 'auto');
      return {
        minWidth,
        maxWidth,
      };
    },
  },
  methods: {
    setValue(val) {
      const noChange = isEqual(this.value, val);
      if (noChange) return;
      this.$emit('update', { row: this.row, column: this.column, value: val });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-select-cell {
    font: $font-size-h3 $font-global;
    line-height: 16px;
    margin-bottom: -4px;
  }

</style>
