<template>
  <div
    class="ub-upload"
    @dragenter="uploadDrag(false)"
    @mouseenter="uploadDrag(true)"
    @mouseleave="uploadDrag(false)"
    @mousedown="uploadDrag(false)"
    @mouseup="uploadDrag(false)"
  >
    <div
      class="ub-upload-popup ub-upload-popup__icon"
      :class="{'shake': wrongFormat}"
    >
      <input
        class="ub-upload__input"
        type="file"
        :multiple="multiple"
        :accept="accept"
        title=""
        @change="previewFiles"
      >
    </div>
    <span v-if="fileCommercialOfferTitle">
      <i
        v-if="buttonTextContent"
        class="ub-icon-file-1"
      ></i>
      {{ fileCommercialOfferTitle }}
    </span>
    <template v-else>
      <span v-if="iconText">
        <span
          v-if="isIconUploadCommercialOffer"
          class="iconFile"
          v-html="uploadFile()"
        />
        <i
          v-else
          class="ub-icon-attachment"
        ></i>
        <span v-if="!buttonText">
          {{ buttonTextContent || $t('global.addFile') }} </span>
      </span>
    </template>
  </div>
</template>

<script>

import { uploadFile } from '@/constants/icons';

export default {
  name: 'Upload',
  props: {
    popupDown: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    upload: {
      type: Boolean,
    },
    iconText: {
      type: Boolean,
    },
    item: {
      type: Object,
      default: () => {},
    },
    isHover: {
      type: Boolean,
    },
    buttonText: {
      type: Boolean,
    },
    buttonTextContent: {
      type: String,
    },
    isIconUploadCommercialOffer: {
      type: Boolean,
    },
    fileCommercialOfferTitle: {
      type: String,
    },
    accept: {
      type: String,
      default: 'application/pdf, application/vnd.ms-excel, image/gif, image/jpeg, application/msword',
    },
  },
  data() {
    return {
      wrongFormat: false,
      uploadFile,
    };
  },
  methods: {
    uploadDrag(data) {
      this.$emit('update:isHover', data);
    },
    previewFiles(event) {
      this.$emit('upload', event.target.files, this.item);
      event.target.value = '';
    },
    deleteFile(fileId) {
      this.$emit('delete', fileId, this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../sass/variables";

  .iconFile {
    position: relative;
    top: 2px;
    left: -3px;
  }

  .ub-upload {
    position: absolute;
    cursor: pointer;
    user-select: none;

    &-popup {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    &__input {
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  @keyframes shake {
    from,
    to {
      transform: translate3d(0, 0, 0);
    }

    20%,
    60% {
      transform: translate3d(-3px, 0, 0);
    }

    40%,
    80% {
      transform: translate3d(3px, 0, 0);
    }
  }
</style>
