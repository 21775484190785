import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  createNewField(data) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.post('user-card', data);
  },
  getFieldById(userId) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.get(`user-card/users/${userId}`);
  },
  updateField(id, data) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.patch(`user-card/${id}`, data);
  },
};
