import { render, staticRenderFns } from "./UBDateCell.vue?vue&type=template&id=738e0f51&scoped=true&"
import script from "./UBDateCell.vue?vue&type=script&lang=js&"
export * from "./UBDateCell.vue?vue&type=script&lang=js&"
import style0 from "./UBDateCell.vue?vue&type=style&index=0&id=738e0f51&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "738e0f51",
  null
  
)

export default component.exports