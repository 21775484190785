import { uniqueId } from 'lodash';

export const customSelectList = [
  {
    id: +uniqueId(),
    text: '',
    key: `variant${uniqueId()}`,
  },
  {
    id: +uniqueId(),
    text: '',
    key: `variant${uniqueId()}`,
  },
];

export const customMultiSelectList = [
  {
    id: +uniqueId(),
    text: '',
    key: `variant${uniqueId()}`,
  },
  {
    id: +uniqueId(),
    text: '',
    key: `variant${uniqueId()}`,
  },
];

export const customRadioButtonList = [
  {
    id: +uniqueId(),
    text: '',
    key: `variant${uniqueId()}`,
  },
  {
    id: +uniqueId(),
    text: '',
    key: `variant${uniqueId()}`,
  },
];
export default { customSelectList, customMultiSelectList, customRadioButtonList };
