<template>
  <div class="admin-modal">
    <div class="admin-modal-wrap">
      <div class="admin-modal-wrap-header">
        <div class="admin-modal-wrap-header__title">
          <template v-if="isPrepayment">
            {{ $t('clientOrder.chequePrepayment') }}
          </template>
          <template v-else>
            {{ $t('clientOrder.chequeAdditionalPayment') }}
          </template>
        </div>
        <div
          class="admin-modal-wrap-header__close"
          @click="closeModal"
        >
          <i class="ub-icon-close-circle admin-modal-wrap-header__icon"></i>
        </div>
      </div>
      <div class="admin-modal-wrap-content">
        <div class="check__title">
          {{ $t('chequeModal.cheque') }} №{{ paymentInfo.orderId }}
        </div>
        <div class="check__date">
          {{ chequeCreatedDate }}
        </div>
        <div class="check-info">
          <div class="check-info__left">
            {{ $t('chequeModal.fio') }}
          </div>
          <div class="check-info__right">
            {{ fullName }}
          </div>
        </div>
        <div class="check-info">
          <div class="check-info__left">
            {{ $t('chequeModal.typePayment') }}
          </div>
          <div class="check-info__right">
            <template v-if="paymentForm === 'Paypal'">
              Paypal
            </template>
            <template v-else>
              {{ $t('chequeModal.invoice') }}
            </template>
          </div>
        </div>
        <div class="check-info">
          <div class="check-info__left">
            {{ $t('chequeModal.formPayment') }}
          </div>
          <div class="check-info__right">
            <template v-if="isPrepayment">
              {{ $t('chequeModal.prepayment') }}
            </template>
            <template v-else>
              {{ $t('chequeModal.postpayment') }}
            </template>
            – <template v-if="isPrepayment">
              {{ procentPayment }}
            </template>
            <template v-else>
              {{ 100 - procentPayment }}
            </template>%
          </div>
        </div>
        <div class="check-info">
          <div class="check-info__left">
            {{ $t('chequeModal.statusPayment') }}
          </div>
          <div class="check-info__right">
            {{ $t('chequeModal.status') }}
          </div>
        </div>
        <div class="check-info">
          <div class="check-info__left">
            {{ $t('chequeModal.currency') }}
          </div>
          <div class="check-info__right">
            {{ currency }}
          </div>
        </div>
        <div class="check-info check-info_amount">
          <div class="check-info__left check-info__left_font_color">
            {{ $t('chequeModal.amountPayment') }}
          </div>
          <div class="check-info__right check-info__right_font_color">
            {{ sum }} {{ currency }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-shortkey="['esc']"
      class="admin-modal__overlay"
      @click="closeModal"
      @shortkey="closeModal"
    ></div>
  </div>
</template>

<script>
import moment from 'moment';
import { get } from 'lodash';

export default {
  name: 'ChequeModal',
  props: {
    paymentInfo: {
      type: Object,
      default: () => {},
    },
    fullName: {
      type: String,
      default: '',
    },
    procentPayment: {
      type: Number,
    },
  },
  computed: {
    currency() {
      return get(this.paymentInfo, 'currency', '');
    },
    sum() {
      return get(this.paymentInfo, 'sum', '');
    },
    isPrepayment() {
      return get(this.paymentInfo, 'isPrepayment', false);
    },
    paymentForm() {
      return get(this.paymentInfo, 'paymentForm', '');
    },
    language() {
      return this.$i18n.locale;
    },
    chequeCreatedDate() {
      return moment(this.paymentInfo.createdAt).locale(this.language).format('MMMM DD, YYYY');
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";

.admin-modal-wrap {
  width: 400px;

  &-content {
    padding-top: 40px;
    padding-bottom: 46px;
    min-height: 508px;

    .check {

      &__title {
        font: $font-size-xlg $font-global-medium;
        text-align: center;
        margin-bottom: 10px;
      }

      &__date {
        font: $font-size-base $font-global;
        text-align: center;
        margin-bottom: 15px;
      }
      &-info {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        padding-top: 20px;
        border-bottom: 1px solid $color-gallery;
        min-height: 61px;

        &_amount {
          border-bottom: none;
          padding-bottom: 0;
        }

        &__left {
          width: 56%;
          font: $font-size-base $font-global-medium;
          color: $color-dodger-blue;

          &_font_color {
            font: $font-size-xlg $font-global-bold;
            color: $color-dodger-blue;
          }
        }

        &__right {
          width: 44%;
          font: $font-size-base $font-global;

          &_font_color {
            font: $font-size-xlg $font-global-bold;
            color: $color-dodger-blue;
          }
        }
      }
    }
  }
}

</style>
