import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  createFile(file) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      baseURL: domains.UPLOAD_API,
    });
    return instWithCred.post('', file);
  },
};
