<template>
  <div
    class="ub-table"
    :class="customClass"
  >
    <div
      v-if="columns && columns.length > 0 && showTable"
      class="ub-table-row ub-table-row_header"
      :class="{
        'ub-table-row_header_hide-border': hoverIndex === 0
      }"
      :style="columnsGrid"
    >
      <div
        v-for="column in columns"
        :key="column.id"
        class="ub-table-row-cell ub-table-row-cell_header"
        :class="{
          'ub-table-row-cell_pointer': column.pointer
        }"
        :style="{
          justifyContent: column.justifyContent || 'flex-start'
        }"
        @click="clickHeader(column)"
      >
        <span>
          {{ $t(column.title) }}
        </span>
        <i
          v-if="column.sortBy"
          class="ub-icon-arrow ub-table-row-cell__icon-sort"
          :class="{
            'ub-table-row-cell__icon-sort_active': sortBy === column.sortBy,
            'ub-table-row-cell__icon-sort_sorted': activeSort === column.sortBy
          }"
        />
      </div>
    </div>
    <VuePerfectScrollbar
      ref="ubScrollContainer"
      class="ub-table-body"
      :style="{maxHeight: maxHeight}"
      @ps-scroll-y="closeActiveModal"
    >
      <div
        v-for="(row, index) in rows"
        :key="row._id"
      >
        <div
          :ref="row._id"
          class="ub-table-row"
          :class="{
            'ub-table-row_hide-border': hoverIndex === index + 1,
            'ub-table-row_active': hoverRow === row._id,
            'ub-table-row_pointer': clickRow
          }"
          :style="columnsGrid"
          @mouseleave="setHover(false, false)"
          @mouseenter="setHover(row._id, index)"
          @click="clickByRow(row._id)"
        >
          <div
            v-for="column in columns"
            :key="column.id"
            class="ub-table-row-cell"
            :style="{
              justifyContent: column.justifyContent || 'flex-start'
            } "
          >
            <component
              :is="getComponentByType(column)"
              :row="row"
              :column="column"
              :hover="hoverRow"
              :countryFlag="countryFlag"
              :index="getIndexWithPage(index)"
              :activeItemModal.sync="activeItemModal"
              @getCountriesList="getCountriesList"
              @showCountriesModal="showCountriesModal"
              @showPersonModal="showPersonModal"
              @update="updateItem"
              @delete="deleteItem"
              @edit="editItem"
              @copy="copyItem"
              @toggleActiveModal="toggleActiveModal"
              @toggleSetActiveModal="toggleSetActiveModal"
            />
          </div>
        </div>
        <UBContent
          v-if="showInfo && activeRow === row._id"
          :row="row"
          :rows="rows"
          @activeRow="activeRow"
          @updateRow="updateRow"
          @updateCurrentRow="updateCurrentRow"
        />
      </div>
    </VuePerfectScrollbar>
    <transition name="fade-content">
      <div
        v-if="activeItemModal"
        ref="countryList"
        class="ub-table__modal"
        :style="{
          left: `${leftPosition}px`,
          top: `${topPosition}px`
        }"
      >
        <VuePerfectScrollbar class="ub-table__list">
          <div
            v-for="item in countriesList"
            :key="item._id"
            class="ub-table__modal-item"
          >
            <div class="ub-table__modal-img">
              <img
                :src="getOptionImage(item.image)"
                alt=""
              >
            </div>
            <div class="ub-table__modal-text">
              {{ item.name[language] }}
            </div>
          </div>
        </VuePerfectScrollbar>
      </div>
    </transition>
    <transition name="fade-content">
      <div
        v-if="activeItemSelectedModal"
        ref="personList"
        class="ub-table__modal"
        :style="{
          left: `${leftPosition}px`,
          top: `${topPosition}px`
        }"
      >
        <selectWithKeyTemplate
          :settings="personSelectSettings"
          @changeSelect="changeSelectUser($event)"
          @infiniteHandler="infiniteHandler"
        />
      </div>
    </transition>
    <transition name="fade-content">
      <div
        v-if="activeItemModal"
        ref="countryList"
        class="ub-table__modal"
        :style="{
          left: `${leftPosition}px`,
          top: `${topPosition}px`
        }"
      >
        <VuePerfectScrollbar class="ub-table__list">
          <div
            v-for="item in countriesList"
            :key="item._id"
            class="ub-table__modal-item"
          >
            <div class="ub-table__modal-img">
              <img
                :src="getOptionImage(item.image)"
                alt=""
              >
            </div>
            <div class="ub-table__modal-text">
              {{ item.name[language] }}
            </div>
          </div>
        </VuePerfectScrollbar>
      </div>
    </transition>
    <UBTableNoData
      v-if="noData.status && (!rows || rows.length === 0) && !loader"
      :noData="noData"
    />
  </div>
</template>

<script>
import {
  get, filter, sortBy, findIndex,
} from 'lodash';
import moment from 'moment';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import UBTableNoData from '@/components/UBTable/UBTableNoData/UBTableNoData';
import UBDateCell from '@/components/UBTable/UBDateCell/UBDateCell';
import UBStringCell from '@/components/UBTable/UBStringCell/UBStringCell';
import UBNumberCell from '@/components/UBTable/UBNumberCell/UBNumberCell';
import UBIndexCell from '@/components/UBTable/UBIndexCell/UBIndexCell';
import UBImageCell from '@/components/UBTable/UBImageCell/UBImageCell';
import UBToolbarTable from '@/components/UBTable/UBToolbarTable/UBToolbarTable';
import UBReviews from '@/components/UBTable/UBReviews/UBReviews';
import UBAccordionCell from '@/components/UBTable/UBAccordionCell/UBAccordionCell';
import UBSelectCell from '@/components/UBTable/UBSelectCell/UBSelectCell';
import UBAccordionContentCell from '@/components/UBTable/UBAccordionCell/UBAccordionContentCell';
import UBCompleteProject from '@/components/UBTable/UBCompleteProject/UBCompleteProject';
import UBToolbarMenu from '@/components/UBTable/UBToolbarMenu/UBToolbarMenu';
import UBFileCell from '@/components/UBTable/UBFileCell/UBFileCell';
import UBTagCell from '@/components/UBTable/UBTagCell/UBTagCell';
import UBContent from '@/components/UBTable/UBContent/UBContent';
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';
import {
  DEPARTMENT_HEAD, CEO, OWNER, MANAGER,
} from '@/constants/roleType';
import {
  UB_CONTACT, UB_CLIENT, UB_BUSINESS_DEVELOPMENT,
} from '@/constants/listStaffDepartments';
import userApi from '@/api/user/userApi';
import ordersApi from '@/api/orders/ordersApi';
import queryString from 'query-string';
import {
  orderStatus,
}
  from '@/constants/orderStatus/orderStatus';
import { paymentStatus, paymentStatuses } from '@/constants/paymentStatus';
import { statusColorList } from '@/constants/orderStatus';

export default {
  name: 'UBTable',
  components: {
    UBToolbarMenu,
    selectWithKeyTemplate,
    UBImageCell,
    UBIndexCell,
    UBNumberCell,
    UBStringCell,
    UBDateCell,
    UBTableNoData,
    UBAccordionCell,
    UBAccordionContentCell,
    UBSelectCell,
    UBCompleteProject,
    UBFileCell,
    UBTagCell,
    UBContent,
    VuePerfectScrollbar,
  },
  props: {
    activeRow: {
      type: [String, Number],
      default: -1,
    },
    customClass: {
      type: String,
    },
    countryFlag: {
      type: Boolean,
      default: false,
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: [Array, Object],
      default: () => [],
    },
    rows: {
      type: [Array, Object],
      default: () => [],
    },
    noData: {
      type: Object,
      default: () => ({
        status: false,
        image: 'list-app',
        title: this.$t('table.noData'),
      }),
    },
    noDataText: {
      type: String,
      default: 'Sorry, no reports found.',
    },
    maxHeight: {
      type: String,
      default: 'auto',
    },
    limit: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    clickRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoverIndex: false,
      setPartnersStatuses: [
        orderStatus.DOCUMENTS_REQUESTED,
        orderStatus.DOCUMENTS_RECEIVED,
        orderStatus.ALL_STEPS_COMPLETED,
        orderStatus.ORDER_CONFIRMED_AND_PAYED,
      ],
      hoverRow: false,
      sortBy: '',
      languageDef: 'ru',
      activeItemModal: false,
      activeItemSelectedModal: false,
      selectedPerson: '',
      countriesList: [],
      personsList: [],
      leftPosition: 0,
      topPosition: 0,
      activeSort: -1,
      activeOrder: {},
      updateUser: '',
      params: {
        page: 1,
        perPage: 20,
      },
      isVisibleSelectPartner: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userRole() {
      const { role = '' } = this.userInfo;
      return role;
    },
    userDepartment() {
      const { department = '' } = this.userInfo;
      return department;
    },
    selectPersonsList() {
      const newArr = [];
      const arrSortByName = sortBy(this.personsList, 'firstName');
      arrSortByName.forEach((item) => {
        newArr.push({
          text: item.contactPerson || item.partnerCompanyName ? `${item.contactPerson} ${item.partnerCompanyName}`
            : `${item.firstName} ${item.lastName}`,
          key: item._id,
          image: item.userAvatarUrl,
        });
      });
      return newArr;
    },
    placeholderText() {
      if (this.userRole === DEPARTMENT_HEAD && this.userDepartment === UB_CONTACT) {
        return this.$t('ordersPage.clientTable.placeholderManager');
      }
      if (this.userRole === DEPARTMENT_HEAD && this.userDepartment === UB_CLIENT) {
        return this.$t('ordersPage.clientTable.placeholderManager');
      }
      if (this.userRole === DEPARTMENT_HEAD && this.userDepartment === UB_BUSINESS_DEVELOPMENT) {
        return this.$t('ordersPage.clientTable.placeholderPartner');
      }
      return '';
    },
    personSelectSettings() {
      return {
        optionList: this.selectPersonsList,
        placeholderText: this.placeholderText,
        selectedKey: this.selectedPerson,
        disable: false,
        toTop: false,
        defaultIcon: true,
        tabindex: 1,
        selectClass: '',
        imagePosition: 'left',
        showImageInput: true,
        infinity: this.updateUser === 'partner',
      };
    },
    language: {
      get() {
        return this.languageDef;
      },
      set(data) {
        this.languageDef = data;
      },
    },
    showTable() {
      return ((this.totalPages > 0 && !this.noData.status) || this.rows.length) || false;
    },
    columnsGrid() {
      const columns = filter(this.columns || [], (col) => !col.hideHead);
      const repeat = columns.map((item) => item.width || 'minmax(70px, 1fr)').join(' ');
      return {
        gridTemplateColumns: `${repeat}`,
      };
    },
  },
  watch: {
    rows() {
      this.scrollReset();
    },
  },
  beforeMount() {
    this.language = this.$i18n.locale;
    const { location } = window;
    const parsed = queryString.parse(location.search);
    const { orderId: id } = parsed;
    if (id) {
      const currentOrder = this.rows.find((item) => item._id === id);
      const {
        partnerAssignmentConfirmation = '', partner = {}, _id: order,
      } = currentOrder;
      const { _id: partnerId } = partner;
      if (partnerAssignmentConfirmation === 'rejected') {
        currentOrder.partner = undefined;
        const currentRow = findIndex(this.rows, ((row) => row._id === id));
        const partnerAvatar = partnerId
        // eslint-disable-next-line global-require
          ? partner.userAvatarUrl || require('@/assets/images/placeholder/avatar.svg')
          : undefined;
        const partnerFullName = partnerId ? `${partner.partnerCompanyName || ''}` : '';
        this.rows.splice(currentRow, 1, {
          ...currentOrder,
          partner: {
            ...partner,
            fullName: partnerFullName,
            userAvatarUrl: partnerAvatar,
          },
        });
      }
      this.$emit('update:activeRow', id);
      if (partnerId && partnerAssignmentConfirmation === 'accepted') {
        ordersApi.getOrdersPartnerForPayoffs(partnerId, order)
          .then((resp) => {
            const { data, data: { maxPayoffSum } } = resp;
            if (Object.keys(data).length) {
              this.isVisibleSelectPartner = !!maxPayoffSum;
            }
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        this.isVisibleSelectPartner = true;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeCountriesModal);
    document.addEventListener('click', this.closePersonModal);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeCountriesModal);
    document.removeEventListener('click', this.closePersonModal);
  },
  methods: {
    updateCurrentRow(data) {
      this.$emit('updateRow', data);
    },
    updateRow(id, order) {
      const currentRow = findIndex(this.rows, ((row) => row._id === id));
      const { createdAt } = currentRow;
      const momentDate = moment(createdAt).locale(this.language);
      const { partnerAssignmentConfirmation = '' } = order;
      if (partnerAssignmentConfirmation) {
        order.partner = undefined;
        const currentRow = findIndex(this.rows, ((row) => row._id === id));
        this.rows.splice(currentRow, 1, {
          ...order,
          createdAt: momentDate.format('MMMM DD, YYYY'),
        });
        this.selectedPerson = -1;
      }
    },
    changeSelectUser(key) {
      this.selectedPerson = key;
      const { _id: id } = this.activeOrder;
      let order = {};
      if (this.updateUser === 'partner') {
        order = {
          partner: key,
          partnerAssignmentConfirmation: 'waitForResponse',
        };
      } else {
        order = {
          [`${this.updateUser}`]: key,
        };
      }
      ordersApi.updateOrderById({ id, order })
        .then((resp) => {
          const { name } = this.$route;
          this.loader = false;
          this.currentOrder = resp.data;
          const currentRow = findIndex(this.rows, ((row) => row._id === id));
          const {
            data: {
              client: { firstName = '', lastName = '' }, salesManager = {}, client, projectManager = {}, country,
              createdAt, partner = {}, isPrepaymentFulfilled, isFullPaymentFulfilled, status,
              partnerAssignmentConfirmation,
            },
          } = resp;
          const { _id: salesManagerId } = salesManager;
          const { _id: projectManagerId } = projectManager;
          const { _id: partnerId } = partner;
          const momentDate = moment(createdAt).locale(this.language);
          const { _id: clientAvatarId } = client;
          const notPayment = !isPrepaymentFulfilled && !isFullPaymentFulfilled;
          const payment = isFullPaymentFulfilled ? paymentStatus.FULL_PAYMENT : paymentStatus.PREPAYMENT_MADE;
          const statusPayment = notPayment ? paymentStatuses[paymentStatus.NOT_PAYMENT] : paymentStatuses[payment];
          const salesManagerAvatar = salesManagerId
          // eslint-disable-next-line global-require
            ? salesManager.userAvatarUrl || require('@/assets/images/placeholder/avatar.svg')
            : undefined;
          const partnerAvatar = partnerId
          // eslint-disable-next-line global-require
            ? partner.userAvatarUrl || require('@/assets/images/placeholder/avatar.svg')
            : undefined;
          const projectManagerAvatar = projectManagerId
          // eslint-disable-next-line global-require
            ? projectManager.userAvatarUrl || require('@/assets/images/placeholder/avatar.svg')
            : undefined;
          const clientAvatar = clientAvatarId
          // eslint-disable-next-line global-require
            ? client.userAvatarUrl || require('@/assets/images/placeholder/avatar.svg')
            : undefined;
          const partnerFullName = partnerId ? `${partner.partnerCompanyName || ''}` : '';
          const projectManagerFullName = projectManagerId ? `${projectManager.firstName || ''}
             ${projectManager.lastName || ''}` : '';
          const salesManagerFullName = salesManagerId ? `${salesManager.firstName || ''}
             ${salesManager.lastName || ''}` : '';
          this.rows.splice(currentRow, 1, {
            ...resp.data,
            client: {
              fullName: `${firstName} ${lastName}`,
              userAvatarUrl: clientAvatar,
            },
            partner: Object.keys(partner).length && partnerAssignmentConfirmation !== 'rejected' ? {
              ...partner,
              fullName: partnerFullName,
              userAvatarUrl: partnerAvatar,
            } : {},
            salesManager: Object.keys(salesManager).length ? {
              ...salesManager,
              fullName: salesManagerFullName,
              userAvatarUrl: salesManagerAvatar,
            } : {},
            projectManager: Object.keys(projectManager).length ? {
              ...projectManager,
              fullName: projectManagerFullName,
              userAvatarUrl: projectManagerAvatar,
            } : {},
            country: {
              ...country,
              image: this.getOptionImageCountry(country.code),
            },
            status: {
              ...status,
              color: statusColorList[status.code],
            },
            paymentStatus: statusPayment,
            createdAt: name === 'adminOrderList' ? momentDate : momentDate.format('MMMM DD, YYYY'),
          });
          this.rows.forEach((item) => {
            const { status: { code } } = item;
            if (code === orderStatus.ORDER_CONFIRMED_AND_PAYED
                && item.projectExecutor === 'projectManager') {
              item.status.manager[this.language] = this.$t('statuses.inProgressUB');
            }
          });
        })
        .catch((err) => {
          this.loader = false;
          console.error(err);
        });
      this.activeItemSelectedModal = false;
    },
    closeActiveModal() {
      if (this.activeItemModal) {
        this.activeItemModal = false;
      }
    },
    closeCountriesModal(e) {
      if (this.$refs.countryList) {
        if (!this.$refs.countryList.contains(e.target)) {
          this.toggleActiveModal(false);
        }
      }
    },
    closePersonModal(e) {
      if (this.$refs.personList) {
        if (!this.$refs.personList.contains(e.target)) {
          this.activeItemSelectedModal = false;
        }
      }
    },
    toggleActiveModal(data) {
      this.activeItemModal = data;
    },
    getDepartmentList(department) {
      userApi.getDepartmentList(department).then((resp) => {
        const { resource } = resp.data;
        this.personsList = resource;
      }).catch((e) => {
        console.error(e);
      });
    },
    toggleSetActiveModal(data, order, department) {
      this.activeOrder = order;
      const { status: { code } } = this.activeOrder;
      if (department === 'salesManager' && ((this.userRole === CEO || this.userRole === OWNER)
          || (this.userRole === DEPARTMENT_HEAD && this.userDepartment === UB_CONTACT))
          && orderStatus.ORDER_CANCELLED !== code) {
        const { salesManager: { _id: id = '' } } = this.activeOrder;
        if (id) {
          this.selectedPerson = id;
        }
        this.updateUser = department;
        this.getDepartmentList('ub-contact');
        this.activeItemSelectedModal = data;
      }
      if (department === 'projectManager' && ((this.userRole === CEO || this.userRole === OWNER)
          || (this.userRole === DEPARTMENT_HEAD && (
            this.userDepartment === UB_CONTACT || this.userDepartment === UB_CLIENT
          )
          )) && orderStatus.ORDER_CANCELLED !== code) {
        const { projectManager: { _id: id } = {} } = this.activeOrder;
        if (id) {
          this.selectedPerson = id;
        }
        this.updateUser = department;
        this.getDepartmentList('ub-client');
        this.activeItemSelectedModal = data;
      }
      if (department === 'partner' && this.isVisibleSelectPartner && ((this.userRole === CEO || this.userRole === OWNER)
          || (this.userRole === DEPARTMENT_HEAD && this.userDepartment === UB_BUSINESS_DEVELOPMENT)
          || ((this.userRole === DEPARTMENT_HEAD || this.userRole === MANAGER) && (
            this.userDepartment === UB_CLIENT || this.userDepartment === UB_CONTACT
          )))
      && (this.setPartnersStatuses.includes(code)) && orderStatus.ORDER_CANCELLED !== code) {
        const { partner: { _id: id = '' } = {} } = this.activeOrder;
        if (id) {
          this.selectedPerson = id;
        }
        this.updateUser = department;
        this.activeItemSelectedModal = data;
        this.personsList = [];
      }
      if ((department === 'partner' && !this.setPartnersStatuses.includes(code))
          || (department && orderStatus.ORDER_CANCELLED === code)) {
        this.activeItemSelectedModal = false;
      }
    },
    infiniteHandler($state) {
      const {
        page = this.params.page,
        perPage = this.params.perPage,
      } = this.params;
      const { country: { _id: id } } = this.activeOrder;
      const filterQuery = {
        country: [id],
      };
      userApi.getPartnersList({ page, perPage, filter: JSON.stringify(filterQuery) }).then((resp) => {
        const { resource } = resp.data;
        if (resource.length) {
          this.params.page += 1;
          this.personsList.push(...resource);
          $state.loaded();
        } else {
          $state.complete();
          this.params.page = 1;
        }
      }).catch((e) => {
        console.error(e);
      });
      this.activeItemSelectedModal = true;
    },
    showCountriesModal(e, list) {
      this.countriesList = list;
      const el = e.currentTarget;
      const { x, y, height } = el.getBoundingClientRect();
      const left = x - 75;
      const top = y + height + 250 > window.innerHeight
        ? y - 225
        : y + 25;
      this.leftPosition = left;
      this.topPosition = top;
      this.toggleActiveModal(!this.activeItemModal);
    },
    showPersonModal(e) {
      const elCoords = e.target.getBoundingClientRect();
      const { x, y, height } = elCoords;
      const left = x;
      const top = y + height + 40 > window.innerHeight
        ? y - 225
        : y + 40;
      this.leftPosition = left;
      this.topPosition = top;
    },
    getCountriesList(data) {
      this.countriesList = data;
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/${image}`);
        /* eslint-enable */
      } catch (e) {
        return false;
      }
    },
    clickHeader(column) {
      if (column.sortBy) {
        const revert = {};
        revert[column.sortBy] = -1;
        this.activeSort = column.sortBy;
        this.sortBy = this.sortBy === column.sortBy ? revert : column.sortBy;
        this.$emit('sort', this.sortBy);
      }
    },
    getOptionImageCountry(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/country/${image}.svg`);
        /* eslint-enable */
      } catch (e) {
        return false;
      }
    },
    getIndexWithPage(index) {
      return this.page === 1 ? index : index + ((this.page - 1) * this.limit);
    },
    deleteItem(currentRow) {
      this.$emit('delete', currentRow);
    },
    editItem(currentRow) {
      this.$emit('edit', currentRow);
    },
    copyItem(currentRow) {
      this.$emit('copy', currentRow);
    },
    updateItem({ row, column, value }) {
      this.$emit('update', { row, column, value });
    },
    setHover(rowId, index) {
      this.hoverRow = rowId;
      this.hoverIndex = index;
    },
    getComponentByType(column) {
      const type = get(column, 'type', '');
      switch (type) {
      case 'image':
        return UBImageCell;
      case 'number':
        return UBNumberCell;
      case 'date':
        return UBDateCell;
      case 'index':
        return UBIndexCell;
      case 'toolbar':
        return UBToolbarTable;
      case 'reviews':
        return UBReviews;
      case 'accordion':
        return UBAccordionCell;
      case 'accordionContent':
        return UBAccordionContentCell;
      case 'select':
        return UBSelectCell;
      case 'completeProject':
        return UBCompleteProject;
      case 'menu':
        return UBToolbarMenu;
      case 'file':
        return UBFileCell;
      case 'tag':
        return UBTagCell;
      default:
        return UBStringCell;
      }
    },
    scrollReset() {
      const container = get(this.$refs, 'ubScrollContainer.$el', '');
      if (container) {
        container.scrollTop = 0;
        container.scrollLeft = 0;
      }
    },
    clickByRow(id) {
      if (this.clickRow) {
        this.$emit('clickByRow', id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../sass/variables";

.ub-table {
  font-family: $font-global;
  display: inline-grid;
  max-width: 100%;
  width: 100%;
  min-height: 104px;
  margin: 0 auto 8px auto;

  &_sticky {
    position: sticky;
    top: 0;
  }

  &_margin {

    .ub-table {

      &-row {
        margin-top: 8px;

        &_header {
          margin-bottom: 0;
        }
      }
    }
  }

  &__list {
    max-height: 190px;
    padding-right: 15px;
  }

  &__modal {
    padding: 12px;
    color: $color-black;
    position: fixed;
    box-shadow: 0 0 10px rgba($color-black, .15);
    border-radius: $borderRadius;
    background: $color-white;
    z-index: 100;
    user-select: none;

    &-text {
      font-family: $font-global;
      margin-bottom: 3px;
    }

    &-item {
      display: flex;
      align-items: center;

      & + .ub-table__modal-item {
        margin-top: 10px;
      }
    }

    &-img {
      width: 20px;
      height: auto;
      margin-right: 5px;

      img {
        border-radius: 50%;
        min-width: 20px;
      }
    }
  }

  &-body {
    padding: 12px 4px 9px;
    margin: -12px -4px 0;
    box-sizing: border-box;
  }

  &-row {
    position: relative;
    display: grid;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 64px;
    border-radius: $borderRadius;
    grid-gap: 0 20px;
    font-size: $font-size-h3;
    padding: 8px 24px;
    transition: all .3s;
    background: $color-white;
    box-shadow: 0 0 8px rgba($color-black, .04);

    &_pointer {
      cursor: pointer;
    }

    & + .ub-table-row {
      margin-top: 8px;
    }

    &:hover:not(&_header) {
      &:before {
        opacity: 1;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 9px;
      left: 0;
      width: 4px;
      opacity: 0;
      height: 46px;
      background-color: $color-dodger-blue;
      border-radius: 0 $borderRadius $borderRadius 0;
      transition: .2s;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 35px;
      height: 1px;
      width: calc(100% - 70px);
      background-color: $color-alabaster;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }

    &_header {
      font: $font-size-h3 $font-global-medium;
      line-height: 21px;
      color: $color-dodger-blue;
      z-index: 3;
      background-color: $color-white;
      margin-bottom: 8px;
      min-height: 41px;
      max-height: 41px;
      cursor: default;

      &_hide-border {
        &:after {
          background-color: transparent;
        }
      }
    }

    &_active, &_hide-border {
      &:after {
        background-color: transparent;
      }
    }

    &_active {
      .ub-table-row-cell {
        position: relative;
        z-index: 5;
      }
    }

    &-cell {
      user-select: none;
      display: flex;
      position: relative;

      &_header {
        display: flex;
        align-items: center;
      }

      &_pointer {
        cursor: pointer;
        transition: 0.3s;
      }

      &__icon-sort {
        margin-left: 5px;
        font-size: $font-size-h6;
        transition: 0.2s linear;
        color: $color-gray;

        &_active {
          transform: rotate(180deg);
        }

        &_sorted {
          color: $color-dodger-blue;
        }
      }
    }
  }
}

</style>

<style lang="scss">
@import "../../sass/variables";

.ub-table {
  .ps__rail-x, .ps__rail-y {
    border-radius: $borderRadiusLarge;
    z-index: 10;
  }

  .ps__thumb-x, .ps__thumb-y {
    border-radius: $borderRadiusLarge;
  }

}
</style>
