<template>
  <div
    v-click-outside="close"
    class="ub-color-palette"
  >
    <div
      v-for="(color, index) in colorPalette"
      :key="color"
      class="ub-color-palette__item"
      :class="{
        'ub-color-palette__item_active': checkedColor(color),
        'ub-color-palette__item_first': index < 16
      }"
      :style="{backgroundColor: color}"
      :value="color"
      @click="setColor(color)"
    />
  </div>
</template>

<script>
import colorPalette from '@/constants/toolbarEditorOptions/colorPalette';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'ColorPalette',
  directives: {
    ClickOutside,
  },
  props: ['activeColor'],
  data() {
    return {
      colorPalette,
    };
  },
  methods: {
    checkedColor(color) {
      let hex;
      try {
        hex = this.rgbToHex(this.activeColor.match(/\d+/gmi));
      } catch (e) {
        hex = this.activeColor;
      }
      hex = hex || this.activeColor;
      return color === hex;
    },
    rgbToHex(rgb) {
      if (!rgb || !Array.isArray(rgb)) return false;
      const fullHex = [];
      rgb.forEach((color) => {
        let hex = Number(color).toString(16);
        if (hex.length < 2) {
          hex = `0${hex}`;
        }
        fullHex.push(hex);
      });
      return `#${fullHex.join('')}`;
    },
    setColor(color) {
      this.$emit('set', color);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../sass/variables";

  .ub-color-palette {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 1px;
    z-index: 99;

    &__item {
      width: 14px;
      height: 14px;
      border: 1px solid transparent;
      transition: .1s;
      border-radius: $borderRadiusSmall;
      margin: 1px;

      &_active {
        border: 1px solid $color-dodger-blue;
      }

      &:hover {
        border-color: $color-cornflower-blue;
      }
    }
  }

</style>
