var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ub-string-cell",on:{"click":_vm.clickCell}},[_c('span',{staticClass:"ub-string-cell-text",class:[
      {
        'ub-string-cell-text_with-hover': _vm.column.route || _vm.column.hover,
        'ub-string-cell-text_tag': _vm.column.styles === 'tag',
        'ub-string-cell-text_capitalize': _vm.countryFlag,
        'ub-string-cell-text_link': _vm.link
      }
    ]},[(_vm.link)?[_c('a',{attrs:{"href":_vm.linkHref}},[_vm._v(" "+_vm._s(_vm.value)+" ")])]:(_vm.icon)?[(_vm.hasFullyPayedPayoffs !== '')?[_c('i',{staticClass:"ub-string-cell-text__icon",class:[_vm.icon, _vm.hasFullyPayedPayoffs ? 'ub-string-cell-text__icon_full' : 'ub-string-cell-text__icon_half']})]:[(_vm.refunded)?_c('i',{staticClass:"ub-string-cell-text__icon",class:_vm.icon}):_vm._e()]]:[_vm._v(" "+_vm._s(_vm.value)+" ")],(_vm.procent)?[_vm._v(" % ")]:_vm._e()],2),(_vm.rewardRate)?_c('div',{staticClass:"ub-string-cell__reward",class:_vm.checkValue},[_vm._v(" "+_vm._s(_vm.rewardRate)+"% ")]):_vm._e(),(_vm.info.length > 0 && _vm.column.info)?_c('i',{staticClass:"ub-icon-info ub-string-cell-info",on:{"mouseenter":function($event){return _vm.setShowInfo($event, true)},"mouseleave":function($event){return _vm.setShowInfo($event, false)}}},[_c('transition',{attrs:{"name":"ub-bounce"}},[(_vm.showInfo)?_c('div',{staticClass:"ub-string-cell-info-popup",style:(_vm.positionInfo)},_vm._l((_vm.info),function(item){return _c('div',{key:item,staticClass:"ub-string-cell-info-popup-item"},[_c('span',{staticClass:"ub-string-cell-info-popup-item__circle"}),_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()])],1):_vm._e(),(_vm.markText && _vm.markDisplay)?_c('div',{staticClass:"ub-string-cell-mark"},[_vm._v(" "+_vm._s(_vm.markText)+" ")]):_vm._e(),(_vm.displayNotification && _vm.hasNotification)?_c('img',{staticClass:"ub-string-cell-notification",attrs:{"src":_vm.notificationImage,"alt":"table"}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }