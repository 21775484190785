<template>
  <div class="profile-client__wrapper">
    <breadСrumbs
      :items="breadCrumbs"
      :arrowIcon="true"
    />
    <VueDraggableResizable
      :w="customWidth"
      class="profile-client__resize"
      :draggable="false"
      :active="true"
      axis="x"
      :minWidth="300"
      :maxWidth="500"
      :handles="['mr']"
      @resizestop="resizeStop"
      @resizing="resizing"
    />
    <div
      class="profile-client-info"
      :style="{'width': `${withClientInfo}px`}"
    >
      <div class="profile-client-info__title profile-client-info__title_padding">
        {{ $t('userProfile.clientCard') }}
      </div>
      <div class="profile-client-info__item profile-client-info__item_client">
        <div class="profile-client-info__image">
          <img
            v-if="currentUser.userAvatarUrl"
            :src="currentUser.userAvatarUrl"
            alt="photo"
          >
          <div
            v-else
            class="profile-client-info__placeholder"
          >
            <i class="ub-icon-user"></i>
          </div>
        </div>
        <div class="profile-client-info__item-block">
          <div class="profile-client-info__name">
            {{ currentUser.firstName }} {{ currentUser.lastName }}
          </div>
          <div class="profile-client-info__transaction">
            {{ $t('userProfile.amountOfTransactions') }}
            <span v-if="currentUser.ordersSum">
              $ {{ currentUser.ordersSum }}
            </span>
            <span v-else>
              0$
            </span>
          </div>
        </div>
      </div>
      <div class="profile-client-info__item">
        <div class="profile-client-info__title">
          {{ $t('userProfile.personalInfo') }}
        </div>
        <div class="profile-client-info__item-row">
          <div class="profile-client-info__block">
            <div class="profile-client-info__label">
              {{ $t('userProfile.registrationData') }}
            </div>
            <div class="profile-client-info__value">
              {{ registrationDate }}
            </div>
          </div>
        </div>
        <div class="profile-client-info__item-row">
          <div class="profile-client-info__block">
            <div class="profile-client-info__label">
              {{ $t('userProfile.phone') }}
            </div>
            <div class="profile-client-info__value">
              {{ currentUser.phone }}
            </div>
          </div>
        </div>
        <div class="profile-client-info__item-row">
          <div class="profile-client-info__block">
            <div class="profile-client-info__label">
              {{ $t('userProfile.mail') }}
            </div>
            <div class="profile-client-info__value">
              {{ currentUser.email }}
            </div>
          </div>
        </div>
        <div class="profile-client-info__item-row">
          <div class="profile-client-info__block">
            <div class="profile-client-info__label">
              {{ $t('userProfile.country') }}
            </div>
            <div class="profile-client-info__value profile-client-info__value_country">
              <div class="profile-client-info__image-country">
                <img
                  v-if="clientCountry.image"
                  :src="clientCountry.image"
                  :alt="clientCountry.title"
                >
              </div>
              <div> {{ clientCountry.title || '' }} </div>
            </div>
          </div>
        </div>
        <div class="profile-client-info__item-row">
          <div class="profile-client-info__block">
            <div class="profile-client-info__label">
              {{ $t('userProfile.address') }}
            </div>
            <div
              class="profile-client-info__value profile-client-info__value_address"
              v-html="currentAddress"
            >
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="salesManagerInfo || isProjectManager"
        class="profile-client-info__item-row profile-client-info__item-row_border"
      >
        <div class="profile-client-info__title">
          {{ $t('userProfile.resposiblyManager') }}
        </div>
        <div v-if="checkRoleDepartment">
          <selectWithKeyTemplate
            :settings="selectSettings"
            @changeSelect="changeSelect($event)"
          />
        </div>
        <div
          v-else
          class="profile-client-info__sales"
        >
          <template v-if="salesManagerFullName.length > 1 || salesManagerAvatar">
            <img
              v-if="salesManagerAvatar"
              :src="salesManagerAvatar"
              alt=""
            >
            <div
              v-else
              class="profile-client-info__user"
            >
              <i
                class="ub-icon-user"
              ></i>
            </div>
            {{ salesManagerFullName }}
          </template>
        </div>
      </div>
      <div
        v-if="checkRoles"
        class="action-elements"
        :class="toggleIconAction ? 'ub-icon-cross-x' : 'ub-icon-more-vertical'"
        @click.stop="openWindowSettings()"
      >
        <transition
          name="fade-el"
          mode="out-in"
        >
          <div
            v-if="toggleIconAction"
            v-click-outside="closeChooseWindow"
            class="action-elements-choose"
            @click.stop
          >
            <div class="action-elements-choose-controls">
              <button
                class="ub-button action-elements-choose-controls__button"
                @click="showEditModal"
              >
                {{ $t('buttons.change') }}
              </button>
            </div>
          </div>
        </transition>
      </div>
      <elementsProfileClient
        :openModalElementsForClientsInside.sync="openModalElementsForClients"
        :defInfoListInside.sync="defInfoList"
        :cardInfo.sync="cardInfo"
        :filterQueryTab.sync="filterQueryTab"
        :emptyCard="emptyCard"
        @changeEmptyCard="changeEmptyCard"
        @openActiveTab="openActiveTab"
      />
      <div
        v-if="cardInfoList.length"
        class="profile-elements"
      >
        <div class="profile-elements__list">
          <template
            v-for="elem in cardInfoList"
          >
            <transition
              :key="elem.fieldId"
              name="fade-content"
              appear
              mode="out-in"
              tag="div"
            >
              <div
                v-if="filterQueryTab.tab === elem.groupId"
                :key="elem.fieldId"
                class="profile-elements__el"
                :class="{'profile-elements__el_active': elem.fieldId === activeEl}"
                @click="addZIndex(elem.fieldId)"
              >
                <showDefaultField
                  :element.sync="elem"
                />
              </div>
            </transition>
          </template>
        </div>
        <transition
          name="fade-content"
          mode="out-in"
          appear
        >
          <div
            v-if="isEqualValues && !openModalElementsForClients"
            class="profile-elements__footer"
          >
            <div class="profile-elements__item">
              <button
                class="ub-btn ub-btn_cancel"
                @click="closeSavePanel"
              >
                {{ $t('global.cancel') }}
              </button>
            </div>
            <div class="profile-elements__item">
              <button
                class="ub-btn ub-btn_primary"
                @click="saveElement"
              >
                {{ $t('buttons.save') }}
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {
  get, isEqual, cloneDeep, sortBy,
} from 'lodash';
import showDefaultField from '@/components/elements/showDefaultField';
import ClickOutside from 'vue-click-outside';
import elementsProfileClient from '@/components/client/elementsProfileClient';
import userCardApi from '@/api/user-card/userCardApi';
import queryString from 'query-string';
import VueDraggableResizable from 'vue-draggable-resizable';
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';
import {
  TYPE_NUMBER,
} from '@/constants/typesAll';
import {
  OWNER, CEO, MANAGER, DEPARTMENT_HEAD,
} from '@/constants/roleType';
import {
  UB_CLIENT, UB_CONTACT,
} from '@/constants/listStaffDepartments';
import userApi from '@/api/user/userApi';
import ordersApi from '@/api/orders/ordersApi';
import breadСrumbs from '@/components/landing/breadCrumbs';

export default {
  name: 'ClientProfile',
  components: {
    elementsProfileClient,
    showDefaultField,
    VueDraggableResizable,
    selectWithKeyTemplate,
    breadСrumbs,
  },
  directives: {
    ClickOutside,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {},
    },
    clientId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      openModalElementsForClients: false,
      prevRoute: '',
      withClientInfo: 375,
      filterQueryTabDef: {},
      activeEl: null,
      defInfoList: [],
      personsList: [],
      salesManager: '',
      toggleIconAction: false,
      cardInfoDef: {
        user: this.clientId,
        cardData: {
          fields: [],
          groups: [],
          deletedFields: [],
        },
      },
      emptyCard: false,
      queryParams: {
        page: 1,
        perPage: 20,
        tab: '',
        orderId: '',
      },
    };
  },
  computed: {
    salesManagerFullName() {
      const { salesManager: { firstName = '', lastName = '' } = {} } = this.currentUser;
      return `${firstName} ${lastName}`;
    },
    salesManagerAvatar() {
      const { salesManager: { userAvatarUrl } = {} } = this.currentUser;
      return userAvatarUrl;
    },
    breadCrumbs() {
      const { firstName, lastName = '' } = this.currentUser;
      return [
        {
          name: this.prevRoute === 'clients' ? this.$t('clientsPage.breadCrumbs') : this.$t('ordersPage.breadCrumbs'),
          route: this.prevRoute === 'clients' ? '/admin/clients' : '/admin/orders-list',
        },
        { name: `${firstName} ${lastName}` },
      ];
    },
    salesManagerInfo() {
      const { salesManager = {} } = this.currentUser;
      return salesManager;
    },
    isProjectManager() {
      return this.userRole === MANAGER && this.userDepartment === UB_CLIENT;
    },
    checkRoles() {
      return this.userRole === OWNER || this.userRole === CEO || this.userDepartment === UB_CONTACT;
    },
    selectPersonsList() {
      const newArr = [];
      const arrSortByName = sortBy(this.personsList, 'firstName');
      arrSortByName.forEach((item) => {
        newArr.push({
          text: `${item.firstName} ${item.lastName}`,
          key: item._id,
          image: item.userAvatarUrl,
        });
      });
      return newArr;
    },
    checkRoleDepartment() {
      return (this.userDepartment === UB_CONTACT && this.userRole === DEPARTMENT_HEAD)
          || (this.userRole === OWNER || this.userRole === CEO);
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userRole() {
      const { role = '' } = this.userInfo;
      return role;
    },
    userDepartment() {
      const { department = '' } = this.userInfo;
      return department;
    },
    orderText() {
      return this.$t('orderPage.order');
    },
    selectSettings() {
      return {
        optionList: this.selectPersonsList,
        placeholderText: this.$t('userProfile.selectPlaceholder'),
        selectedKey: this.salesManager,
        disable: false,
        toTop: false,
        defaultIcon: true,
        tabindex: 1,
        selectClass: '',
        imagePosition: 'left',
        showImageInput: true,
      };
    },
    filterQueryTab: {
      get() {
        return this.filterQueryTabDef;
      },
      set(data) {
        this.filterQueryTabDef = data;
      },
    },
    isEqualValues() {
      return !isEqual(this.defInfoList, this.cardInfoList);
    },
    cardInfoList: {
      get() {
        const { cardData: { fields } } = this.cardInfo;
        return fields;
      },
      set(data) {
        this.cardInfo.cardData.fields = data;
      },
    },
    cardInfoGroups: {
      get() {
        const { cardData: { groups } } = this.cardInfo;
        return groups;
      },
      set(data) {
        this.cardInfo.cardData.groups = data;
      },
    },
    cardInfo: {
      get() {
        return this.cardInfoDef;
      },
      set(data) {
        this.cardInfoDef = data;
      },
    },
    currentAddress() {
      const {
        postcode = '',
        district = '',
        city = '',
        street = '',
        house = '',
        room = '',
      } = this.currentUser;
      const a = postcode === '' && district === '';
      const b = street === '' && house === '';
      if (postcode === '' && district === '' && city === '' && street === '' && house === '' && room === '') {
        return '';
      }
      const firstTemp = a ? ''
        : `<span>${postcode ? `${postcode},` : ''} ${district ? `${district},` : ''}</span>`;
      const secondTemp = !city && b
        ? ''
        : `<span>${city ? `${city},` : ''} ${!b ? `${street}${
          house ? `${street ? ` ${house}` : house}` : ''
        },` : ''}</span>`;
      const thirdTemp = room ? `<span>${room}</span>` : '';
      return `
          ${firstTemp}
          ${secondTemp}
          ${thirdTemp}
        `;
    },
    clientCountry() {
      const { country } = this.currentUser;
      if (country) {
        return {
          image: this.getOptionImage(country.code),
          title: get(country, `name.${this.language}`, ''),
        };
      }
      return '';
    },
    language() {
      return this.$i18n.locale;
    },
    registrationDate() {
      if (this.currentUser.createdAt) {
        return moment(this.currentUser.createdAt).locale(this.language).format('MMMM DD, YYYY');
      }
      return '';
    },
    customWidth() {
      const width = localStorage.getItem('width');
      return width ? +width : this.withClientInfo;
    },
  },
  beforeMount() {
    const { params: { prevRoute = '' } } = this.$route;
    if (prevRoute) {
      this.prevRoute = prevRoute;
    }
    const { salesManager: { _id: id = '' } = {} } = this.currentUser;
    if (id) {
      this.salesManager = id;
    }
    userApi.getDepartmentList('ub-contact').then((resp) => {
      const { resource } = resp.data;
      this.personsList = resource;
    }).catch((e) => {
      console.error(e);
    });
    this.widthClientProfile();
    const userId = this.clientId;
    this.loader = true;
    userCardApi.getFieldById(userId)
      .then((resp) => {
        this.loader = false;
        if (Object.keys(resp.data).length) {
          this.emptyCard = true;
          const {
            cardData = {},
            user = '',
            _id: cardId = '',
          } = resp.data;
          const fields = cardData.fields.map((field) => {
            const { author: { _id: id } } = field;
            return {
              ...field,
              author: id,
            };
          });
          this.cardInfo = {
            cardData: {
              ...cardData,
              fields,
            },
            user,
            cardId,
          };
          this.cardInfo.cardData.deletedFields = [];
          this.defInfoList = cloneDeep(this.cardInfoList);
        } else {
          this.cardInfoGroups.push(
            {
              id: 1,
              position: 0,
              title: this.$t('global.main'),
            },
          );
          this.emptyCard = false;
        }
        const { location: { search } } = window;
        const parsed = queryString.parse(search);
        const {
          tab = this.cardInfoGroups[0].id, page, perPage, orderId,
        } = parsed;
        this.filterQueryTab = {
          ...this.filterQueryTab,
          ...parsed,
          tab,
        };
        this.queryParams = {
          page: +page < 1 ? 1 : +page,
          perPage: +perPage < 1 ? 1 : +perPage,
          orderId,
          tab,
        };
        this.openActiveTab(+tab);
      })
      .catch((err) => {
        this.loader = false;
        console.error(err);
      });
  },
  methods: {
    changeSelect(key) {
      const user = {
        ...this.currentUser,
        salesManager: key,
        country: [],
      };
      this.salesManager = key;
      const { location } = window;
      const parsed = queryString.parse(location.search);
      const { orderId } = parsed;
      const order = {
        salesManager: key,
      };
      userApi.updateUser(user).then().catch((e) => {
        console.error(e);
      });
      ordersApi.updateOrderById({ id: orderId, order }).then()
        .catch((err) => {
          console.error(err);
        });
    },
    widthClientProfile() {
      this.$nextTick(() => {
        const resiseWidth = document.querySelector('.profile-client__resize');
        if (resiseWidth) {
          const width = window.getComputedStyle(resiseWidth, null).getPropertyValue('width');
          this.withClientInfo = parseInt(width, 10);
        }
      });
    },
    changeEmptyCard(val) {
      this.emptyCard = val;
    },
    openActiveTab(tab) {
      this.$nextTick(() => {
        this.filterQueryTab = {
          ...this.filterQueryTab,
          tab,
        };
        this.addParameterInURL();
      });
    },
    addParameterInURL() {
      this.$nextTick(() => {
        const { query } = this.$route;
        this.$router.push({
          query: {
            ...query,
            ...this.filterQueryTab,
          },
        }).catch(() => {});
      });
    },
    closeSavePanel() {
      this.cardInfoList = cloneDeep(this.defInfoList);
    },
    saveElement() {
      const { cardId = '' } = this.cardInfo;
      const data = {
        ...this.cardInfo,
        user: undefined,
        cardId: undefined,
      };
      const { cardData } = data;
      const { fields } = cardData;
      fields.forEach((item) => {
        if (item.type === TYPE_NUMBER) {
          item.value = +item.value;
        }
      });
      this.loader = true;
      userCardApi.updateField(cardId, data)
        .then()
        .catch((err) => {
          console.error(err);
        });
      this.defInfoList = cloneDeep(this.cardInfoList);
    },
    addZIndex(id) {
      this.activeEl = id;
    },
    resizing(x, y, width) {
      this.withClientInfo = width < 542 ? width : 542;
    },
    resizeStop() {
      localStorage.setItem('width', this.withClientInfo);
    },
    showEditModal() {
      this.$emit('showEditModal', true);
    },
    updateClientProfile(data) {
      this.$emit('updateClientProfile', data);
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/country/${image}.svg`);
        /* eslint-enable */
      } catch (e) {
        return false;
      }
    },
    openWindowSettings() {
      this.toggleIconAction = !this.toggleIconAction;
    },
    closeChooseWindow() {
      this.toggleIconAction = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.profile-elements {
  &__el {
    margin-bottom: 17px;

    &_active {
      position: relative;
      z-index: 50;
    }
  }

  &__list {
    padding: 0 20px 10px 20px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    padding: 0 20px;
  }

  &__item {
    & + .profile-elements__item {
      margin-left: 5px;
    }
  }
}

.profile-client {

  &__wrapper {
    position: relative;
    margin-right: 15px;
  }

  &__resize {
    position: absolute;
    z-index: 10 !important;
    height: auto !important;
    min-height: 98% !important;
    border: none;
  }

  &-info {
    background: $color-white;
    padding: 22px 0;
    box-shadow: 0 0 8px rgba($color-black, .04);
    border-radius: $borderRadius;
    position: relative;
    z-index: 80;
    width: 100%;

    &__sales {
      display: flex;
      align-items: center;

      img {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 1px solid $color-dodger-blue;
        margin-right: 10px;
      }
    }

    &__user {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      color: $color-white;
      background: $color-dodger-blue;
      border-radius: 50%;
      margin-right: 10px;
    }

    &__value {
      width: 65%;
      min-width: 65%;
      max-width: 65%;

      &_country {
        display: flex;
        align-items: center;

        div {
          position: relative;
          top: 1px;
        }
      }
    }

    &__name {
      font: $font-size-icon-xd $font-global-medium;
      margin-bottom: 12px;
      text-align: center;
    }

    &__transaction {
      background: rgba($color-dodger-blue, .08);
      border-radius: $borderRadius;
      padding: 4px 14px;
      color: $color-dodger-blue;
      font-family: $font-global-medium;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      span {
        margin-left: 2px;
        margin-right: 2px;
      }
    }

    &__item {
      padding-left: 20px;
      padding-right: 20px;

      &_client {
        display: flex;
        padding-top: 11px;
        overflow: hidden;
      }

      &-row {
        display: flex;
        overflow: hidden;

        &_border {
          padding: 23px 20px 32px;
          border-top: 1px solid $color-gallery;
          border-bottom: 1px solid $color-gallery;
          overflow: visible;
          display: flex;
          flex-direction: column;
        }
      }
    }

    &__block {
      display: flex;
    }

    &__title {
      font: $font-size-h2 $font-global-medium;
      margin-bottom: 22px;

      &_padding {
        padding: 0 20px;
      }
    }

    &__label {
      font: $font-size-md $font-global-medium;
      color: $color-silver-chalice;
      margin-bottom: 6px;
      width: 35%;
      min-width: 35%;
      max-width: 35%;
      padding-right: 10px;
    }

    &__block {
      margin-bottom: 33px;
      width: 100%;
    }

    &__image {
      min-width: 80px;
      max-width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-bottom: 28px;
      margin-right: 20px;

      img {
        border-radius: 50%;
      }

      &-country {
        min-width: 20px;
        max-width: 20px;
        height: 20px;
        margin-right: 8px;

        img {
          border-radius: 50%;
        }
      }
    }

    &__placeholder {
      min-width: 80px;
      max-width: 80px;
      height: 80px;
      border-radius: 50%;
      background: $color-dodger-blue;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: $font-size-landing-h1;
        color: $color-white;
      }
    }
  }
}

.action-elements {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: $font-size-md;
  width: 24px;
  height: 24px;
  border: 1px solid rgba($color-dodger-blue, .08);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: $color-dodger-blue;
  cursor: pointer;
  transition: all .15s ease-in;
  z-index: auto;

  &-choose {
    user-select: none;
    position: absolute;
    top: 12px;
    right: 10px;
    left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba($color-black, .1);
    border-radius: $borderRadius;
    background-color: $color-white;
    color: $color-black;
    cursor: default;
    min-width: 143px;
    max-width: 143px;
    padding: 3px 0;
    z-index: -1;

    &-controls {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__button {
        line-height: 1.5;
        min-height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color .15s ease-in;
        padding: 0 12px;
        font: $font-size-md $font-global;

        &:hover {
          background: $color-alabaster;
        }
      }
    }
  }

  &:before {
    position: relative;
    border-color: transparent;
  }

  &:hover {
    background: $color-dodger-blue;
    color: $color-white;
    border-color: transparent;
  }
}

.ub-icon-cross-x {
  background: $color-dodger-blue;
  color: $color-white;

  &:before {
    font-size: $font-size-h6;
    left: -.5px;
  }
}

</style>

<style lang="scss">
@import "../../sass/variables";
@import '../../../node_modules/vue-draggable-resizable/dist/VueDraggableResizable.css';

.profile-client-info {

  &__value {
    &_address {
      span {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}

.handle.handle-mr {
  display: block !important;
  height: 100%;
  top: 5px;
  background: transparent;
  border: none;
}
</style>
