<template>
  <div
    v-if="fileId"
    class="ub-file-cell"
    @click="openFile"
  >
    <i class="ub-icon-eye ub-toolbar-icon ub-toolbar-icon_blue" />
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'UBFileCell',
  props: {
    column: {
      type: [Array, Object],
      default: () => [],
    },
    row: {
      type: [Array, Object],
      default: () => [],
    },
  },
  computed: {
    fileId() {
      return get(this.row, this.column.prop, '');
    },
  },
  methods: {
    openFile() {
      const path = get(this.fileId, 'path', '');
      const label = get(this.fileId, 'label', '');
      if (path) {
        window.open(path, label);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-file-cell {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

</style>
