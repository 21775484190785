import i18n from '@/i18n';
import {
  TYPE_TEXT,
  TYPE_NUMBER,
  TYPE_FLAG,
  TYPE_LIST,
  TYPE_MULTILIST,
  TYPE_DATE,
  TYPE_LINK,
  TYPE_TEXTAREA,
  TYPE_SWITCH,
  TYPE_BIRTHDAY,
  TYPE_BREAK,
} from '@/constants/typesAll';

const listTypes = [
  {
    id: 1,
    text: i18n.t('elements.text'),
    key: TYPE_TEXT,
  },
  {
    id: 2,
    text: i18n.t('elements.number'),
    key: TYPE_NUMBER,
  },
  {
    id: 3,
    text: i18n.t('elements.flag'),
    key: TYPE_FLAG,
  },
  {
    id: 4,
    text: i18n.t('elements.list'),
    key: TYPE_LIST,
  },
  {
    id: 5,
    text: i18n.t('elements.multiList'),
    key: TYPE_MULTILIST,
  },
  {
    id: 6,
    text: i18n.t('elements.date'),
    key: TYPE_DATE,
  },
  {
    id: 7,
    text: i18n.t('elements.link'),
    key: TYPE_LINK,
  },
  {
    id: 8,
    text: i18n.t('elements.textArea'),
    key: TYPE_TEXTAREA,
  },
  {
    id: 9,
    text: i18n.t('elements.switch'),
    key: TYPE_SWITCH,
  },
  {
    id: 10,
    text: i18n.t('elements.birthday'),
    key: TYPE_BIRTHDAY,
  },
  {
    id: 11,
    text: i18n.t('elements.break'),
    key: TYPE_BREAK,
  },
];
export default listTypes;
