<template>
  <div class="ub-country-flag">
    <div
      v-for="(country, index) in countriesList"
      :key="country._id"
      class="ub-country-flag__item"
    >
      <img
        v-if="index < 3"
        ref="ubCountryFlag"
        class="ub-country-flag__image"
        :class="{'ub-country-flag__image_pointer': countriesList.length === 1}"
        :src="getOptionImage(country.image)"
        @mouseleave="countriesList.length !== 1 ? displayCountryName = -1 : ''"
        @mouseenter="countriesList.length === 1 ? displayCountryName = -1 : displayCountryName = country._id"
      >
      <span
        v-if="countriesList.length === 1"
        class="ub-country-flag__text"
      >
        {{ country.name[language] }}
      </span>
      <div
        v-if="displayCountryName === country._id"
        class="ub-country-flag__tooltip"
      >
        {{ country.name[language] }}
      </div>
    </div>
    <div
      v-if="countriesList.length > 3"
      class="ub-country-flag__more"
      @click.stop="showCountriesModal($event)"
    >
      + {{ $t('global.more') }} {{ countriesList.length -3 }} <i class="ub-icon-arrow-2-down"></i>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CountryFlag',
  props: ['countries', 'activeItemModal', 'index'],
  data() {
    return {
      displayCountryName: -1,
      isVisibleCountiesModal: false,
    };
  },
  computed: {
    countriesList() {
      return this.countries;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    showCountriesModal(e) {
      this.$emit('showCountriesModal', e);
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/${image}`);
        /* eslint-enable */
      } catch (e) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-country-flag {
    font-family: $font-global-medium;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-width: 200px;
    align-items: center;

    &__text {
      font-size: $font-size-base;
      font-family: $font-global;
      margin-left: 4px;
    }

    &__more {
      cursor: pointer;
      transition: color .15s ease-in;
      color: $color-silver-chalice;
      position: relative;
      left: -7px;

      i {
        font-size: $font-size-icon;
        position: relative;
        top: -1px;
        right: 2px;
      }

      &:hover {
        color: $color-mine-shaft;
      }

      &_up {

        i:before {
          transform: rotate(180deg);
        }
      }
    }

    &__image {
      width: 25px;
      height: auto;
      cursor: pointer;
      border-radius: 50%;
      position: relative;
      border: 3px solid $color-white;
      text-align: center;

      &_pointer {
        cursor: default;
      }
    }

    &__tooltip {
      margin-top: 5px;
      position: absolute;
      padding: 3px 10px;
      display: flex;
      align-items: center;
      background: rgba($color-black, .8);
      border-radius: $borderRadius;
      min-height: 22px;
      color: $color-white;
      font-size: $font-size-md;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);

      &:before {
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        left: -50%;
      }

      &:before {
        border-bottom-color: rgba($color-black, .8);;
        border-width: 5px;
        transform: translate(-50%);
        top: -10px;
        left: 50%;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      position: relative;

      &:first-child {
        z-index: 3;
        position: relative;
      }

      &:nth-child(2) {
        z-index: 2;
        position: relative;
        left: -7px;
      }

      &:nth-child(3) {
        z-index: 1;
        position: relative;
        left: -14px;
      }
    }

    &__name {
      position: absolute;
      left: 0;
      top: 27px;
      padding: 6px 12px;
      background: rgba($color-black, 0.53);
      box-shadow: 0 0 12px rgba($color-black, 0.12);
      border-radius: 5px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: $color-white;
      white-space: nowrap;
      z-index: 3;
      pointer-events: none;

      &:after{
        content: '';
        position: absolute;
        left: 7px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 5px solid rgba($color-black, 0.53);
        box-shadow: 0 0 12px rgba($color-black, 0.12);
      }
    }
  }

</style>
